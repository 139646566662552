<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
    <div class="Popup">
        <div class="d-flex justify-content-center align-items-center bd-highlight">
            <div class="p-2 d-flex gap-3 flex-grow-1 bd-highlight">
                <img v-if="isMenuSidebar == false" class="header-close-sidebar" src="@/assets/svg/close-sidebar.svg"
                    data-toggle="tooltip" data-placement="bottom" title="Đóng sidebar" @click="toggleSidebarMenu"
                    alt="close-sidebar">
                <img v-if="isMenuSidebar == false" class="header-create-message" src="@/assets/svg/message.svg"
                    @click="viewConversation(0, { id: 0 })" alt="message">
                <img class="header-menu-mobile" src="@/assets/svg/menu-mobile.svg" @click="toggleSidebarMenu"
                    alt="menu-mobile">

                <div v-if="showPackageLeft == true && !PropsHeader" class="dropdown header-package">
                    <button class="btn dropdown-toggle fs-18 fw-600 text-neutral " type="button" data-toggle="dropdown"
                        aria-expanded="false">
                        
                        {{ this.versionGPT === '3.5' ? 'GPT - 3.5' : this.versionGPT === '4o' ? 'GPT - 4o' : this.versionGPT === '4' ? 'GPT - 4' : this.versionGPT === '4o-mini' ? 'GPT - 4o mini' : this.versionGPT === 'gemini' ? 'Gemini' :  'Chat' }}

                    </button>
                    <div class="dropdown-menu header-dropdown-custom " style="border-radius: 10px !important;">

                        <div class="d-flex align-items-center fs-14 color-7d7d7d p-8-16">
                            Mô hình
                        </div>
                        <a class="dropdown-item d-flex" href="#" @click.prevent="avtiveVersionGpt('4o-mini')"
                            >
                            <div class="d-flex align-items-center w-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M19.92.897a.447.447 0 0 0-.89-.001c-.12 1.051-.433 1.773-.922 2.262-.49.49-1.21.801-2.262.923a.447.447 0 0 0 0 .888c1.035.117 1.772.43 2.274.922.499.49.817 1.21.91 2.251a.447.447 0 0 0 .89 0c.09-1.024.407-1.76.91-2.263.502-.502 1.238-.82 2.261-.908a.447.447 0 0 0 .001-.891c-1.04-.093-1.76-.411-2.25-.91-.493-.502-.806-1.24-.923-2.273ZM11.993 3.82a1.15 1.15 0 0 0-2.285-.002c-.312 2.704-1.115 4.559-2.373 5.817-1.258 1.258-3.113 2.06-5.817 2.373a1.15 1.15 0 0 0 .003 2.285c2.658.3 4.555 1.104 5.845 2.37 1.283 1.26 2.1 3.112 2.338 5.789a1.15 1.15 0 0 0 2.292-.003c.227-2.631 1.045-4.525 2.336-5.817 1.292-1.291 3.186-2.109 5.817-2.336a1.15 1.15 0 0 0 .003-2.291c-2.677-.238-4.529-1.056-5.789-2.34-1.266-1.29-2.07-3.186-2.37-5.844Z">
                                    </path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">GPT-4o mini</div>
                                    <div class="fs-12 color-7d7d7d">Hiệu quả gấp đôi GPT-3.5</div>
                                </div>
                                <div class="ml-auto" v-if="versionGPT == '4o-mini'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"
                                        viewBox="0 0 24 24" class="icon-md">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex" href="#" @click.prevent="avtiveVersionGpt('4o')"
                           >
                            <div class="d-flex align-items-center w-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M19.92.897a.447.447 0 0 0-.89-.001c-.12 1.051-.433 1.773-.922 2.262-.49.49-1.21.801-2.262.923a.447.447 0 0 0 0 .888c1.035.117 1.772.43 2.274.922.499.49.817 1.21.91 2.251a.447.447 0 0 0 .89 0c.09-1.024.407-1.76.91-2.263.502-.502 1.238-.82 2.261-.908a.447.447 0 0 0 .001-.891c-1.04-.093-1.76-.411-2.25-.91-.493-.502-.806-1.24-.923-2.273ZM11.993 3.82a1.15 1.15 0 0 0-2.285-.002c-.312 2.704-1.115 4.559-2.373 5.817-1.258 1.258-3.113 2.06-5.817 2.373a1.15 1.15 0 0 0 .003 2.285c2.658.3 4.555 1.104 5.845 2.37 1.283 1.26 2.1 3.112 2.338 5.789a1.15 1.15 0 0 0 2.292-.003c.227-2.631 1.045-4.525 2.336-5.817 1.292-1.291 3.186-2.109 5.817-2.336a1.15 1.15 0 0 0 .003-2.291c-2.677-.238-4.529-1.056-5.789-2.34-1.266-1.29-2.07-3.186-2.37-5.844Z">
                                    </path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">GPT-4o</div>
                                    <div class="fs-12 color-7d7d7d">Hiệu quả nhất cho các công việc phức tạp</div>
                                </div>
                                <div class="ml-auto" v-if="versionGPT == '4o'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"
                                        viewBox="0 0 24 24" class="icon-md">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item d-flex" href="#" @click.prevent="avtiveVersionGpt('4')"
                            >
                            <div class="d-flex align-items-center w-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M12.001 1.75c.496 0 .913.373.969.866.306 2.705 1.126 4.66 2.44 6 1.31 1.333 3.223 2.17 5.95 2.412a.976.976 0 0 1-.002 1.945c-2.682.232-4.637 1.067-5.977 2.408-1.34 1.34-2.176 3.295-2.408 5.977a.976.976 0 0 1-1.945.002c-.243-2.727-1.08-4.64-2.412-5.95-1.34-1.314-3.295-2.134-6-2.44a.976.976 0 0 1-.002-1.94c2.75-.317 4.665-1.137 5.972-2.444 1.307-1.307 2.127-3.221 2.444-5.972a.976.976 0 0 1 .971-.864Z">
                                    </path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">GPT-4</div>
                                    <div class="fs-12 color-7d7d7d">Hiệu quả nhất gấp đôi GPT-3.5</div>
                                </div>
                                <div class="ml-auto" v-if="versionGPT == '4'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"
                                        viewBox="0 0 24 24" class="icon-md">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                        </a>

                        <a class="dropdown-item d-flex" href="#" @click.prevent="avtiveVersionGpt('3.5')">
                            <div class="d-flex align-items-center w-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M12.566 2.11c1.003-1.188 2.93-.252 2.615 1.271L14.227 8h5.697c1.276 0 1.97 1.492 1.146 2.467L11.434 21.89c-1.003 1.19-2.93.253-2.615-1.27L9.772 16H4.076c-1.276 0-1.97-1.492-1.147-2.467L12.565 2.11Z"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">GPT-3.5</div>
                                    <div class="fs-12">
                                        <span class="color-7d7d7d">Hiệu quả nhất cho các công việc đơn giản </span>
                                    </div>
                                </div>
                                <div class="ml-auto" v-if="versionGPT == '3.5'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"
                                        viewBox="0 0 24 24" class="icon-md">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                        </a>

                        <a class="dropdown-item d-flex" href="#" @click.prevent="avtiveVersionGpt('gemini')">
                            <div class="d-flex align-items-center w-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M12.001 1.75c.496 0 .913.373.969.866.306 2.705 1.126 4.66 2.44 6 1.31 1.333 3.223 2.17 5.95 2.412a.976.976 0 0 1-.002 1.945c-2.682.232-4.637 1.067-5.977 2.408-1.34 1.34-2.176 3.295-2.408 5.977a.976.976 0 0 1-1.945.002c-.243-2.727-1.08-4.64-2.412-5.95-1.34-1.314-3.295-2.134-6-2.44a.976.976 0 0 1-.002-1.94c2.75-.317 4.665-1.137 5.972-2.444 1.307-1.307 2.127-3.221 2.444-5.972a.976.976 0 0 1 .971-.864Z">
                                    </path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">GEMINI</div>
                                    <div class="fs-12 color-7d7d7d">AI đến từ nhà Google</div>
                                </div>
                                <div class="ml-auto" v-if="versionGPT == 'gemini'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"
                                        viewBox="0 0 24 24" class="icon-md">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m14.076-4.068a1 1 0 0 1 .242 1.393l-4.75 6.75a1 1 0 0 1-1.558.098l-2.5-2.75a1 1 0 0 1 1.48-1.346l1.66 1.827 4.032-5.73a1 1 0 0 1 1.394-.242"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </div>
                        </a>

                        <a v-if="this.package.package_id != this.BUSINESS_PACKAGE_ID" class="dropdown-item d-flex"
                            href="https://fchat.ai/price/#price_section">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"
                                    viewBox="0 0 24 24" class="h-4 w-4 text-token-text-primary">
                                    <path fill="currentColor"
                                        d="M15.11 14.285a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317">
                                    </path>
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M7.001 4a1 1 0 0 1 .993.887c.192 1.7.701 2.877 1.476 3.665.768.783 1.913 1.3 3.618 1.452a1 1 0 0 1-.002 1.992c-1.675.145-2.849.662-3.638 1.452-.79.79-1.307 1.963-1.452 3.638a1 1 0 0 1-1.992.003c-.152-1.706-.67-2.851-1.452-3.62-.788-.774-1.965-1.283-3.665-1.475a1 1 0 0 1-.002-1.987c1.73-.2 2.878-.709 3.646-1.476.767-.768 1.276-1.916 1.476-3.646A1 1 0 0 1 7 4Zm-2.472 6.998a6.1 6.1 0 0 1 2.468 2.412 6.2 6.2 0 0 1 1.037-1.376 6.2 6.2 0 0 1 1.376-1.036 6.1 6.1 0 0 1-2.412-2.469 6.2 6.2 0 0 1-1.053 1.416 6.2 6.2 0 0 1-1.416 1.053"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <div class="ml-8px">
                                    <div class="fs-14">Nâng cấp gói</div>
                                    <div class="fs-12 color-7d7d7d">Mô hình thông minh nhất của chúng tôi và hơn thế nữa
                                    </div>
                                </div>
                            </div>
                        </a>
                        <hr class="dropdown-divider">

                        <a class="dropdown-item d-flex" href="#">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"
                                    viewBox="0 0 24 24" class="h-5 w-5 shrink-0">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M10.974 3.252a1 1 0 0 1-.746 1.201 7.74 7.74 0 0 0-3.876 2.24 1 1 0 1 1-1.458-1.37 9.74 9.74 0 0 1 4.878-2.817 1 1 0 0 1 1.202.746m2.052 0a1 1 0 0 1 1.202-.746 9.74 9.74 0 0 1 4.878 2.818 1 1 0 1 1-1.458 1.37 7.74 7.74 0 0 0-3.876-2.24 1 1 0 0 1-.746-1.202M3.91 8.514a1 1 0 0 1 .67 1.246A7.8 7.8 0 0 0 4.25 12c0 .774.113 1.53.325 2.25a1 1 0 0 1-1.92.564A10 10 0 0 1 2.25 12c0-.978.144-1.924.413-2.817a1 1 0 0 1 1.246-.669m16.182 0a1 1 0 0 1 1.246.67c.269.892.413 1.838.413 2.816a10 10 0 0 1-.406 2.813 1 1 0 0 1-1.919-.564A8 8 0 0 0 19.75 12a7.8 7.8 0 0 0-.328-2.24 1 1 0 0 1 .669-1.246m-.982 8.768a1 1 0 0 1 .086 1.412c-1.293 1.462-3.006 2.551-4.955 3.033a1 1 0 1 1-.48-1.941c1.53-.379 2.895-1.24 3.938-2.418a1 1 0 0 1 1.411-.086m-12.937-.008a1 1 0 0 1 .293 1.384L5.593 20H10a1 1 0 1 1 0 2H3.75a1 1 0 0 1-.838-1.545l1.876-2.887a1 1 0 0 1 1.384-.294"
                                        clip-rule="evenodd"></path>
                                </svg>
                                <div class="ml-8px">Đoạn chat tạm thời</div>
                                <div class="form-check form-switch ">
                                    <input @click="toggleDisabled" class="form-check-input ml-auto" type="checkbox"
                                        role="switch" id="flexSwitchCheckChecked">
                                </div>
                            </div>

                        </a>

                    </div>
                    <!-- <span class="fs-20 fw-600 d-chat">Chat</span> -->

                </div>

                <div v-if="showPackageLeft == true && PropsHeader"
                    class="dropdown header-package d-flex align-items-center">

                    <button class="btn dropdown-toggle fs-20 fw-600 text-black header-text-limit" type="button"
                        data-toggle="dropdown" aria-expanded="false">
                        {{ litmitText(this.PropsHeader.name) }}
                    </button>

                    <span class="ml-16px">
                        <img v-if="isFavourite == false" src="@/assets/svg/no_favourite.svg" alt=""
                            @click="updateFavourite(PropsHeader.id)">
                        <img v-if="isFavourite == true" src="@/assets/svg/is_favourite.svg" alt=""
                            @click="removeFavourite(PropsHeader.id)">
                    </span>


                    <div class="dropdown-menu header-dropdown-custom1" style="border-radius: 10px !important;">

                        <a class="dropdown-item d-flex align-items-center" @click="redirectChatgpt()">
                            <span><img src="@/assets/svg/message.svg" alt=""></span>
                            <span class="ml-8px">Đoạn chat mới</span>
                        </a>

                        <a class="dropdown-item d-flex align-items-center" @click.prevent="toggleModal()" href="#">
                            <span><img src="@/assets/svg/icon-detail.svg" alt=""></span>
                            <span class="ml-8px">Chi tiết trợ lý</span>
                        </a>

                        <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="copyURL()">
                            <span><img src="@/assets/svg/copy_link.svg" alt=""></span>
                            <span class="ml-8px">{{ $t('copy_link.copy_link') }}</span>
                        </a>

                        <a class="dropdown-item d-flex align-items-center" href="#" v-if="keepSidebar == false"
                            @click="handleKeepSidebar(PropsHeader)">
                            <span><img src="@/assets/svg/keep_sidebar.svg" alt=""></span>
                            <span class="ml-8px">{{ $t('keep_in_sidebar.keep_in_sidebar') }}</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#" v-if="keepSidebar == true"
                            @click="handleHideSidebar(PropsHeader)">
                            <span><img src="@/assets/svg/hide_form_sidebar.svg" alt=""></span>
                            <span class="ml-8px">{{ $t('hide_form_sidebar.hide_form_sidebar') }}</span>

                        </a>

                    </div>
                </div>

            </div>
            <a href="/gpt/mine"
                v-if="this.package.package_id == this.BUSINESS_PACKAGE_ID || this.package.package_id == this.PRO_PACKAGE_ID">
                <div v-if="showPackageLeft == false" class="p-2 bd-highlight btn-assistant_create btn ">
                    <img src="@/assets/svg/plus.svg" alt="plus">
                    {{ $t('assistant_create.assistant_create') }}
                </div>
            </a>

            <img class="header-create-message-right p-2" width="" src="@/assets/svg/message.svg"
                @click="viewConversation(0, { id: 0 })" alt="create-message">

            <div @click="toggleModalShare" v-if="showIconShare == true && temporaryChat == false"
                class="header-share bd-highlight cursor-pointer mr-8px">
                <img src="@/assets/svg/share.svg" alt="" title="Chia sẻ">
            </div>
            <div class="p-2 bd-highlight header-profile">
                <div class="dropdown fix_drop cursor-ta d-flex">
                    <div class="dis-none">
                        <button
                            class="dropdown-left btn-shoponl fw-600 fs-14 dropdown-toggle d-flex justify-content-start"
                            data-toggle="dropdown" aria-expanded="false"> {{ aShop.name }}<br>
                        </button>

                        <div class="packge_display d-flex align-center">
                            <a v-if="this.package" href="/shopPackage" :title="$t('package_detail.package_detail')"
                                class="pack_name_head text-black">
                                {{ this.package.package_id == this.FREE_PACKAGE_ID ? "Free" :
                                    this.package.package_id == this.PRO_PACKAGE_ID ?
                                        'PRO' : this.package.package_id == this.BUSINESS_PACKAGE_ID ? 'BUSINESS'
                                            :
                                            'FREE' }}
                            </a>
                            <a class="mx-1">
                                |
                            </a>
                            <div class="text-black">
                                <small v-if="this.shop_role.role_id == 10" class="pakage_name">
                                    OWNER
                                </small>
                                <small v-else-if="this.shop_role.role_id == 11" class="pakage_name">
                                    ADMIN
                                </small>
                                <small v-else class="pakage_name">
                                    USER
                                </small>
                            </div>
                        </div>

                        <div class="user-menu dropdown-menu-right dropdown-menu dropdown-caret dropdown-close"
                            style="width:164px">
                            <ul style="">
                              
                                <li class="dropdown-item" v-for="(shop_role, index) in this.listShop" :key="index">
                                    <ul class="">
                                        <li class="">
                                            <a :href="'/change-shop/' + shop_role.shop_id" class="d-flex align-items-center text-black  fs-14">
                                                {{ shop_role.name }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li role="separator" class="divider"></li>

                                <li class="p-8px">
                                    <a href="/shop/add" class="d-flex align-items-center text-black  fs-14">
                                        ＋ {{ $t('create_new_shop.create_new_shop')
                                            }}
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>

                    <div>
                     
                        <img class="nav-user-photo cursor-pointer" style="border-radius:50% !important"
                            data-toggle="dropdown" :src="'https://'+ this.website.domain +'/avatar/' + aUser.id + '.png'">
                        <div class="user-menu dropdown-menu-right dropdown-menu dropdown-caret dropdown-close"
                            style="width:164px">
                            <ul style="">
                                <li class="dis-none1">
                                    <div class="dropdown fix_drop dropdown-submenu" style="display: grid; ">
                                        <button
                                            class="dropdown-left btn-shoponl fw-600 fs-14 dropdown-toggle d-flex justify-content-start"
                                            data-toggle="dropdown" aria-expanded="false"> {{ aShop.name }}<br>
                                        </button>
                                        <div class="packge_display d-flex justify-content-between">
                                            <a v-if="this.package" href="/shopPackage"
                                                :title="$t('package_detail.package_detail')"
                                                class="pack_name_head text-black">
                                                {{ this.package.package_id == this.FREE_PACKAGE_ID ? "Free" :
                                                    this.package.package_id == this.PRO_PACKAGE_ID ?
                                                        'Pro' : this.package.package_id == this.BUSINESS_PACKAGE_ID ? 'Business'
                                                            :
                                                            'Free' }}
                                            </a>
                                            <div class="text-black">
                                                <small v-if="this.shop_role.role_id == 10" class="pakage_name">
                                                    Owner
                                                </small>
                                                <small v-else-if="this.shop_role.role_id == 11" class="pakage_name">
                                                    Admin
                                                </small>
                                                <small v-else class="pakage_name">
                                                    User
                                                </small>
                                            </div>
                                        </div>
                                        <ul class="dropdown-menu">

                                            <li class="dropdown-item" v-for="(shop_role, index) in this.listShop"
                                                :key="index">
                                                <ul class="">
                                                    <li class="">
                                                        <a :href="'/change-shop/' + shop_role.shop_id" class="fs-14">
                                                            {{ shop_role.name }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li role="separator" class="divider"></li>

                                            <li class="p-8px-0px">
                                                <a href="/shop/add" class="fs-14">
                                                  ＋ {{ $t('create_new_shop.create_new_shop')
                                                        }}
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </li>

                                <li role="separator" class="divider dis-none1"></li>
                                <li
                                    v-if="this.package.package_id == this.BUSINESS_PACKAGE_ID || this.package.package_id == this.PRO_PACKAGE_ID">
                                    <a class="d-flex align-items-center  text-black bg-neutral-hover1" href="/gpt/mine"
                                        style="display: block; padding: 8px 8px !important; position: relative;">
                                        <img src="@/assets/svg/assistant.svg" alt="">
                                        <span class="ml-8px">{{ $t('assistant_manager.assistant_manager') }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a class="d-flex align-items-center text-black bg-neutral-hover1"
                                        href="/profile"
                                        style="display: block; padding: 8px 8px !important; position: relative;">
                                        <img src="@/assets/svg/profile-card-outline.svg" alt="">
                                        <span class="ml-8px">{{ $t('profile_user.profile_user') }}</span>
                                    </a>
                                </li>
                                
                                <li>
                                    <a class="d-flex align-items-center text-black bg-neutral-hover1"
                                        href="/setting/info"
                                        style="display: block; padding: 8px 8px !important; position: relative;">
                                        <img src="@/assets/svg/setting.svg" alt="">
                                        <span class="ml-8px">{{ $t('setting.setting') }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a class="d-flex align-items-center text-black bg-neutral-hover1"
                                        href="https://help.fchat.ai"
                                        style="display: block; padding: 8px 8px !important; position: relative;">
                                        <img src="@/assets/svg/help.svg" alt="">
                                        <span class="ml-8px">{{ $t('help.help') }}</span>
                                    </a>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li>
                                    <a class="d-flex align-items-center text-black bg-neutral-hover1"
                                        @click.prevent="logout"
                                        style="display: block;padding: 8px 8px !important;position: relative;">
                                        <img src="@/assets/svg/logout.svg" alt="">

                                        {{ $t('logout.logout') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import utils from '@/utils/index'
import { mapState, mapActions,mapMutations } from 'vuex';
import gptService from "@/services/gptService";
import config from "@/config";
export default {
  name: "Header",
  components: {

  },
  props: {
        base_url: String,
        aShop: Object,
        listShop: Array,
        shop_role: Object,
        aShopList: Array,
        aUser: Object,
        website: Object,
        package: [Object, Array],
        conv_not_view: [Number, String],
        livechat_mode: Boolean,
        showIconShare:Boolean,
        customKey:Number,
        version_gpt:String
  },
  data() {
    return {
        FREE_PACKAGE_ID : config.FREE_PACKAGE_ID,
        PRO_PACKAGE_ID : config.PRO_PACKAGE_ID,
        BUSINESS_PACKAGE_ID : config.BUSINESS_PACKAGE_ID,
        showDropdown: false,
        showPackageLeft: true,
        category:{},
        PropsHeader:null,
        isFavourite:false,
        keepSidebar:false,
        versionGPT:3.5,

    };
  },
  created() {
    this.$root.$refs.compname_component_header = this;
    this.getDataLocalStorage()
  },
  watch: {
    '$route.path'(newPath) {
      if (newPath === '/') {
        this.showPackageLeft = true;
        this.PropsHeader = JSON.parse(localStorage.getItem('PropsHeader')) || '';
      } else {
        this.showPackageLeft = false;
        this.$root.$refs.compname_component.closeIconShare()

      }
    },

  },
  created() {
    if(this.$route.path==='/'){
        this.showPackageLeft = true;
      } else {
        this.showPackageLeft = false;
        this.$root.$refs.compname_component.closeIconShare()
      }
      this.PropsHeader = JSON.parse(localStorage.getItem('PropsHeader')) || '';

      if(this.PropsHeader.is_recent){
      this.keepSidebar=true
    }
    // console.log(this.keepSidebar)
  },

  computed: {
    flagImage() {
       return this.$i18n.locale === 'vi'
        ? 'https://salekit.io/assets/images/flag/vi.svg'
        : 'https://salekit.io/assets/images/flag/en.svg';
    },
    ...mapState(['showModal', "isModalShare","welcome"]),
    ...mapState(['isMenuSidebar']), 
    ...mapState(['temporaryChat']), 


  },
  mounted() {
    this.PropsHeader = JSON.parse(localStorage.getItem('PropsHeader')) || '';
    // console.log('header', this.PropsHeader)
    if(this.PropsHeader){
        this.isFavourite=this.PropsHeader.is_favourite
    }
    this.getDataLocalStorage()
  },
  methods: {
    ...mapMutations({
      setTemporaryChat: 'setTemporaryChat',
      setWelcome: 'setWelcome',
      setQueryId:'setQueryId'
    }),
    ...mapActions(['updateShowModal','updateModalShare']),
    avtiveVersionGpt(version){
        this.versionGPT=version
        this.$root.$refs.compname_component_gpt.avtiveVersionGpt(version)
    },
    handleHideSidebar(data){
      let aUser=JSON.parse(localStorage.getItem("aUser"))
      this.$root.$refs.compname_component.deletePromptRecents({user_id:aUser.id, prompt_id:data.id})
      this.keepSidebar=false

      this.$toast.success("Ẩn khỏi sidebar thành công", {timeout: 1000});
    },
    handleKeepSidebar(data){
      let aUser=JSON.parse(localStorage.getItem("aUser"))
      this.$root.$refs.compname_component.insertPromptRecents({user_id:aUser.id, prompt_id:data.id})
      this.keepSidebar=true

      this.$toast.success("Giữ ở sidebar thành công!", {timeout: 1000});
    },
   async removeFavourite(prompt_id) {
    let propsHeader = JSON.parse(localStorage.getItem('PropsHeader'));
    let token=localStorage.getItem('token');
    if (propsHeader) {
       await gptService.deleteFavourite({ user_id: this.aUser.id, shop_id: this.aShop.id, prompt_id: prompt_id }, token)
       .then((res)=>{
                propsHeader.is_favourite = 0;
        })
        .catch((err)=>{
            console.log(err)
        })
      // Cập nhật lại dữ liệu trong localStorage
      localStorage.setItem('PropsHeader', JSON.stringify(propsHeader));

      this.PropsHeader = propsHeader;
      this.isFavourite = this.PropsHeader.is_favourite;
    } else {
      console.error('PropsHeader not found in localStorage');
    }
  },
  async updateFavourite(prompt_id) {
    let propsHeader = JSON.parse(localStorage.getItem('PropsHeader'));
    let token=localStorage.getItem('token');

    if (propsHeader) {
       await gptService.updateFavourite({ user_id: this.aUser.id, shop_id: this.aShop.id, prompt_id: prompt_id }, token)
       .then((res)=>{
  
                propsHeader.is_favourite = 1;
    
        })
        .catch((err)=>{
            console.log(err)
        })
      // Cập nhật lại dữ liệu trong localStorage
      localStorage.setItem('PropsHeader', JSON.stringify(propsHeader));

      this.PropsHeader = propsHeader;
      this.isFavourite = this.PropsHeader.is_favourite;
    } else {
      console.error('PropsHeader not found in localStorage');
    }
  },
    async copyURL() {
      if(this.PropsHeader){
        let link='https://fchat.ai/share/'+this.PropsHeader.id_hash
        await navigator.clipboard.writeText(link);
      }
      alert('Sao chép thành công!'); 
    },
    toggleDisabled() {
        this.$router.push({path:'/',query:{id:0}}).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    });
    this.setTemporaryChat(!this.temporaryChat);

    if(this.temporaryChat === false){
        $("#wapper-chat").html('');
        $('#list-mess').addClass('flex-content-items-center');
        $('#welcome').show()
    }else{
        $("#wapper-chat").show();
        $("#wapper-chat").html('');
    }
    
    },
    toggleModal() {
      this.updateShowModal(!this.showModal);
    },
    toggleModalShare() {
        this.setQueryId(this.$route.query.id)
        this.$root.$refs.compname_component.increaseCustomKey();
        this.updateModalShare(!this.isModalShare);

    },
    getDataLocalStorage() {
        this.PropsHeader = JSON.parse(localStorage.getItem('PropsHeader')) || '';
    },
    litmitText(text){
        if (window.matchMedia('(max-width: 767px)').matches) {
            return utils.limitText20(text);
        } else {
            return utils.limitText50(text);
        }
    },
    changeLanguage(locale) {
         window.$cookies.set('lang',locale);
        return console.log(window.$cookies.get('lang'));
    },
    toggleSidebarMenu() {
        event.stopPropagation();

        this.$store.commit('setIsMenuSidebar', !this.isMenuSidebar); // Gọi mutation để thay đổi giá trị isMenuSidebar

        // this.$root.$refs.compname_component.toggleSidebarMenu();
    },
    viewConversation(conv_index, conv) {
        this.setTemporaryChat(false);
      if(conv_index, conv){
        const targetRoute = { path: '/', query: { id: conv.id } };
        if (this.$route.path !== '/') {
            this.$router.push(targetRoute);
        }else{
          this.$root.$refs.compname_component_gpt.viewConversation(conv_index, conv);
        }
      }

    },
    redirectChatgpt(){
        window.location.href='/?id=0'
        utils.removeDataLocal()
    },
    logout(){
        localStorage.removeItem('PropsHeader');
        localStorage.removeItem('PropsBody');
        localStorage.removeItem('PropsCategory');
        localStorage.removeItem('aShop');
        localStorage.removeItem('aUser');
        localStorage.removeItem('package');
        localStorage.removeItem('shopName');
        localStorage.removeItem('token');
        window.location.href='/logout'
    }
  }
};
</script>

<style>
.dropdown-toggle {
    border: none !important;

}

.dropdown-menu {
    box-shadow: 0px 0px 4px 0px #2D579140;
    border: none;
    margin-top: 10px;

}

.dropdown-item {
    padding: 8px 1rem !important;
    color: #000000 !important;
    height: 40px !important;
    cursor: pointer;
}

.form-switch {
    padding-left: 8px !important;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: -100%;
    margin-top: -1px;
    width: 164px;
    padding: 8px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}
</style>
<style scoped src="../../assets/css/popupversion.css"></style>
