<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
  <div id="app">
    <div class="">
      <!-- Menu bar -->
      <div class="" style="" v-if="this.aUser">
        <div class="row" :key="customKey" v-if="this.token != ''">
          <div class="col-lg-2 col-md-4  conversation-responsive " ref="card" v-if="this.isMenuSidebar && !isSharePage">
          <div style="height: 100vh;">
         
            <!-- Bộ khung bên trái chia thành 3 phần với chiều cao cố định -->
            <Conversations :aUser="this.aUser" :conversations="this.conversations" :prompt_recent="this.prompt_recent"
              :aShop="this.aShop" :shop_role="this.shop_role" :listShop="this.listShop" :package="this.package"
              ref="conversationRef" />
            </div>
          </div>
          <div class="col scrollable-content" ref="scrollContainer" 
          :style="isRootRoute === true  ? {} : { height: 'calc(100vh)' }" 
          
          >
            <Header v-if="!isSharePage" :version_gpt="version_gpt" :customKey="this.customKey" :website="this.website"
              :showIconShare="this.showIconShare" :aUser="this.aUser" :aShop="this.aShop" :shop_role="this.shop_role"
              :listShop="this.listShop" :package="this.package" />
           
            <router-view></router-view>
              
            
          </div>
        </div>
      </div>
      <div class="form-group"  v-else>
        <LayoutNoAuth :customKey="this.customKey" />
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
Vue.use(Notifications);

import i18ns from '@/index.js';
import languageJson from '@/lang/language.json';
import Conversations from "@/components/conversation/Conversations.vue";
import ContentMain from "@/components/content/ContentMain.vue";
import config from "@/config";
import gptService from "@/services/gptService";
import Header from "@/components/layout/Header.vue";
import { mapState } from 'vuex';
import utils from '@/utils/index'
import LayoutNoAuth from '@/components/layout/LayoutNoAuth.vue'
export default {
  name: "Main",
  components: {
    Conversations,
    ContentMain,
    Header,
    LayoutNoAuth
  },
  data() {
    return {
      languageData: languageJson,
      version_gpt: "3.5",
      conversations: [],
      aPrompt: {},
      listShop: [],
      messages: [],
      aShop: {},
      aUser: {},
      package: {},
      shop_role: {},
      conv: {},
      conv_id: "",
      shop_id: "",
      token: "",
      showIconShare:false,
      customKey:0,
      prompt_recent:[],
      isRootRoute :'',
      website:{}
    };
  },
 async created() {
   let aShop= await localStorage.getItem("aShop");
    if(aShop){
      // localStorage.removeItem('PropsHeader');
        // localStorage.removeItem('PropsBody');
        // localStorage.removeItem('PropsCategory');
        localStorage.removeItem('aShop');
        localStorage.removeItem('aUser');
        localStorage.removeItem('package');
        localStorage.removeItem('shopName');
        localStorage.removeItem('token');
    }
     gptService.getLogin().then((res) => {
      if (!res.error) {
        const data = res.data.data;
        this.shop_id =data.shop ? data.shop.id : 0;
        this.token =data.shop ? data.shop.api_token : '';
        this.listShop = data.list_shop;
        this.shop_role = data.role_user;
        this.aShop = data.shop ? data.shop : []
        this.aUser = data.user;
        this.package = data.package || {};
        this.website=data.website || {}
        // Lấy danh sách conversation
        localStorage.setItem('aShop',JSON.stringify(this.aShop))
        localStorage.setItem('aUser',JSON.stringify(this.aUser))
        localStorage.setItem('package',JSON.stringify(this.package))
        localStorage.setItem('shopName',this.aShop.name)
        localStorage.setItem('token',this.token)
        localStorage.setItem('website',JSON.stringify(this.website))
        if(this.shop_id){
          this.getConversations({shop_id: this.shop_id});
          this.getPromptRecents({user_id:this.aUser.id, limit:3})

        }
        

      }
    });
    // Hàm gọi từ component cha
    this.$root.$refs.compname_component = this;
  },

  computed: {
    ...mapState(['isMenuSidebar']), // Kết nối isMenuSidebar từ Vuex vào computed property của component
    computedMenuSidebar() {
      return this.isMenuSidebar;
    },
    isSharePage() {
    //   return this.$route.name === 'SharePrompt' || this.$route.name === 'ShareChat' ;
    }
  },
  mounted() {
    this.checkRoute();
    // console.log(this.computedMenuSidebar)
    this.handleResize();
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('resize', this.handleResize);
    this.aUser = JSON.parse(localStorage.getItem('aUser')) || '';
    
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    '$route'() {
      this.checkRoute();
    }
  },
  methods: {
    checkRoute() {
      this.isRootRoute = this.$route.path === '/';
    },
    unshiftConversation(conversation){
      this.conversations.unshift({
                  id: conversation.id,
                  is_new: true,
                  name: conversation.name,
                  avatar: conversation.avatar,
                  last_message: conversation.last_message,
                  last_message_time: new Date(),
                  unread: 0,
                  version: conversation.version
      });
          
    },
    handleClickOutside(event) {
      // console.log(this.computedMenuSidebar)

      // Kiểm tra nếu conversation đang hiển thị và màn hình nhỏ hơn 767px
      if (this.computedMenuSidebar==true && window.innerWidth <= 767) {

        const conversationRef = this.$refs.conversationRef;
        const clickOutside = !(conversationRef && conversationRef.$el.contains(event.target));
        // Nếu click bên ngoài conversation, đóng nó lại
        if (clickOutside) {
          this.$store.commit('setIsMenuSidebar', false);
        }

      }
    },
    increaseCustomKey(){
      this.customKey ++;
    },
    handleResize() {
      const newValue = window.innerWidth > 767;
      this.$store.commit('setIsMenuSidebar', newValue); 
    },
    openIconShare(){
      this.showIconShare=true;
    },
    closeIconShare(){
      this.showIconShare=false;
    },
    toggleSidebarMenu() {
      this.$store.commit('setIsMenuSidebar', !this.isMenuSidebar); 
    },
    closeToggleSidebarMenu() {
      this.$store.commit('setIsMenuSidebar', false); 
    },
    getConversations(object_data) {
      if(!object_data) {
        object_data = {};
        object_data.shop_id = this.shop_id;
      }
      if(!object_data.shop_id) object_data.shop_id = this.shop_id;
      object_data.page = this.page_conv;

      gptService.getConversations(object_data, this.token)
        .then((res) => {
          if (!res.data.error) {
            let list_conversations = res.data.data;
            this.conversations.push(...list_conversations);
            this.page_conv++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPromptRecents(object_data) {
     let token=localStorage.getItem("token");
      gptService.getListPromptRecent(object_data, token)
        .then((res) => {
          if (!res.data.error) {
            this.prompt_recent=res.data.data
            // console.log(this.prompt_recent)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    insertPromptRecents(object_data) {
      // console.log(object_data)
     let token=localStorage.getItem("token");
     let aUser=JSON.parse(localStorage.getItem("aUser"))
     let aShop=JSON.parse(localStorage.getItem("aShop"))
      gptService.insertPromptRecent(object_data, token)
        .then((res) => {
          if (!res.data.error) {
          this.getPromptRecents({user_id:aUser.id, limit:3})

           return utils.refreshCache(aShop.id)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePromptRecents(object_data) {
     let token=localStorage.getItem("token");
     let aUser=JSON.parse(localStorage.getItem("aUser"))
     let aShop=JSON.parse(localStorage.getItem("aShop"))
      gptService.deletePromptRecent(object_data, token)
        .then((res) => {
          if (!res.data.error) {
            this.getPromptRecents({user_id:aUser.id, limit:3})
            return utils.refreshCache(aShop.id)

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
};
</script>

<style>
@media (max-width: 767px) {
  .conversation-responsive {
    position: absolute;
    width: 70%;
    z-index: 1000;
  }
  input, textarea {
        font-size: 16px;
    }
}
</style>
