<template>
    <div class="modal-backdrop-answer">

        <div class="modal-share">
            <div id="cancel-rewrite" class="icon-cancel-share" @click="close">
                <i class="fa-solid fa-xmark"></i>
            </div>

            <header class="modal-header">
                <span class="modal__title">{{ title }}</span>
            </header>
            <span class="ml-20px">Bất kì ai có liên kết đều có thể xem nội dung cuộc trò chuyện của bạn</span>

            <section class="odal-body-share">
                <slot name="body" />
                <div class="row d-flex align-items-center ml-10px">
                    <div class="col-lg-11 col-md-10 col-10">
                        <input type="text" name="" v-model="link" class="form-control" disabled id="">
                    </div>
                    <div class="col-lg-1 col-md-1 col-1 cursor-pointer" @click="copyURL(link)">
                        <img src="@/assets/svg/copy.svg" class=" ml-10px" alt="">
                    </div>
                </div>
            </section>
            <footer class="modal-footer">
                <slot name="footer" />
            </footer>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import Notifications from 'vue-notification'
Vue.use(Notifications);
export default {
    name: 'ModalShare',
    
    props: {
        title: {
            type: String,
            required: false
        },
        close: {
            type: Function,
            required: true
        },
        link: {
            type: String,
            required: false
        },
    },
    data(){
        return{
      
        }
    },
    mounted(){

    },
    methods: {
        async copyURL(mytext) {
            try {
            await navigator.clipboard.writeText(mytext);
                alert('Sao chép thành công!');
            } catch($e) {
                alert('Sao chép thất bại');
            }
        }
    }
};
</script>

<style lang="css">
.modal-backdrop-answer {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding: 300px; */
}

.modal-header {
    border-bottom: none;
    /* padding: 10px; */
}

.modal-footer {
    border-top: none;
    padding: 10px;

}

.odal-body-share {
    padding: 0;
    padding: 10px;

}

.modal-share {
    position: relative;
    width: 612px;
    z-index: 1000;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20% auto;
}

.btn-close {
    position: absolute;
    top: 17px;
    right: 17px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #2196F3;
    background: transparent;
}

.modal__title {
    color: #272727;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding: 10px 10px 0px 10px;
}

.icon-cancel-share {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #F4F7FA;
    box-shadow: 0px 0px 4px 0px #2D579140;
    width: 25px;
    height: 25px;
    right: 0;
    top: -10px;
    cursor: pointer;
}

@media (max-width: 576px) {
    .modal-share {
        width: 350px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .modal-share {
        width: 350px;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 992px) and (max-width: 1199px) {}
</style>
