module.exports = {
    SHOP_SYSTEM:1,
    FREE_PACKAGE_ID : 0,
    PRO_PACKAGE_ID : 1,
    BUSINESS_PACKAGE_ID : 7,
    PACKAGE:{
        FREE: 3000,
        PRO: 100000,
        BUSINESS: 200000
    },

    PAGEINATION: process.env.NODE_ENV === 'production' ? 20 : 20,
    TOKEN_GPT: process.env.NODE_ENV === 'production' ? '' : '',
    
    BASE_URL: process.env.NODE_ENV === 'production' ? 'https://fchat.ai/' : 'https://fchat.ai/',
   API_URL_CLIENT: process.env.NODE_ENV === 'production' ? 'https://fchat.ai/api/v1/' : 'http://fchat.ai.local/api/v2/',
    TOKEN_SHOP_SYSTEM: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoxLCJpYXQiOjE3MTYxOTM4NjF9.wjdPrmjKzga51K95QRmnT7gp-Mw1t1wQSCMQzWMahMc",
    //server
    API_URL: process.env.NODE_ENV === 'production' ? 'https://fchatai-api.salekit.com:3034/api/v1/' : 'https://fchatai-api.salekit.com:3034/api/v1/',
    //local
    // API_URL: process.env.NODE_ENV === 'production' ? 'http://localhost:3034/api/v1/' : 'http://localhost:3034/api/v1/',
    
     
}