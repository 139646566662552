<template>
    <div class="">
        <div class="gpts_content_tile fs-20 fw-600 text-black">
            Workflow
        </div>
        <div class="gpts-content_des mt-1 text-neutral">
            Danh sách tất cả trợ lý
        </div>
        <div class="row mx-md-n5 ">
            <div v-for="(workflow, index) in this.visibleWorkflows" :key="index"
                class="col-lg-6 col-md-6  col-12 pt-16 px-1 px-md-1">
                <div class="d-flex bg-neutral radius-16px bg-neutral-hover cursor-pointer" style="height:108px"
                    @click.prevent="viewCategoryDetail(workflow)">
                    <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-8px-0px">
                        <img v-if="workflow.icon" :src="workflow.icon" width="80" height="80" class="img-fluid rounded-circle"
                            alt="svg">
                        <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="80" height="80"
                            class="img-fluid rounded-circle" alt="svg">
                    </div>
                    <div class="flex-grow-1 p-8-12">
                        <div class="fs-14 fw-600 text-black limit-text-2-line">
                            {{ workflow.name }}
                        </div>
                        <div class="fw-12 fw-400 limit-text-2-line text-black pt-4px">
                            {{ workflow.description }}
                        </div>
                        <div class="fs-12 fw-400 text-blue mt-5px">{{ workflow.category_count }} trợ lý</div>

                    </div>
                </div>
            </div>

            <button class="btn btn-loadmore col-12" @click="visibleWorkflow += step"
                v-if="visibleWorkflow < listWorkflows.length">{{ $t('load_more.load_more') }}</button>
            <button class="btn btn-loadmore col-12" @click="collapseCollection"
                v-if="visibleWorkflow >= listWorkflows.length && listWorkflows.length > step">
                {{ $t('collapse.collapse') }}
            </button>
        </div>
    </div>
</template>

<script>
// import gptService from "@/services/gptService";
export default {
    name: 'Workflow',
    components: {},
    props: {
        listWorkflows: Array
    },
    data() {
        return {
            token: '',
            aUser: {},
            aPackage: {},
            aShop: {},
            visibleWorkflow: 6,
            step: 6,
            page: 2,
        }
    },
    created() {

    },
    beforeMount() { },

    beforeDestroy() { },
    mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');

    },

    computed: {
        visibleWorkflows() {
            return this.listWorkflows.slice(0, this.visibleWorkflow)
        },
    },
    watch: {

    },
    methods: {
        viewCategoryDetail(category) {
            localStorage.setItem('category', JSON.stringify(category));
            this.$router.push({ name: 'workflow_step', params: { id: category.id } });
        },
        collapseCollection() {
            this.visibleWorkflow = this.step;
        }
    }

}
</script>

<style scoped src="@/assets/css/gpts.css"></style>
