import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    totalTvCount: 10,
    isLarryHappy: true,
    listGPTS: true,
    showModal: false,
    isMenuSidebar: true,
    temporaryChat: false,
    welcome: true,
    isModalShare: false,
    queryId: 0,
    showDropdown: false,
  },
  getters: {
    listGPTS: (state) => state.listGPTS,
    showModal: (state) => state.showModal,
    showDropdown: (state) => state.showDropdown,
    isModalShare: (state) => state.isModalShare,
    queryId: (state) => state.queryId,
  },
  mutations: {
    removeTv(state, amount) {
      state.totalTvCount -= amount;
    },
    setListGPTS(state, value) {
      state.listGPTS = value;
    },
    SET_SHOW_MODAL(state, value) {
      state.showModal = value;
    },
    SET_showDropdown(state, value) {
      state.showDropdown = value;
    },
    setIsMenuSidebar(state, newValue) {
      state.isMenuSidebar = newValue;
    },
    setTemporaryChat(state, newValue) {
      state.temporaryChat = newValue;
    },
    setWelcome(state, newValue) {
      state.welcome = newValue;
    },
    setModalShare(state, newValue) {
      state.isModalShare = newValue;
    },
    setQueryId(state, newValue) {
      state.queryId = newValue;
    },
  },
  actions: {
    removeTv(context, amount) {
      if (context.state.totalTvCount >= amount) {
        context.commit("removeTv", amount);
      }
    },
    setListGPTS({ commit }, value) {
      commit("setListGPTS", value);
    },
    updateShowModal({ commit }, value) {
      commit("SET_SHOW_MODAL", value);
    },
    updateshowDropdown({ commit }, value) {
      commit("SET_showDropdown", value);
    },
    updateModalShare({ commit }, value) {
      commit("setModalShare", value);
    },
    updateQueryId({ commit }, value) {
      commit("setQueryId", value);
    },
  },
});
