<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
<div>
   
    <div class="modal" tabindex="-1" role="dialog" id="fullScreenModal" ref="fullScreenModal">
        <div class="modal-dialog" role="document">
        <div class="modal-content">
            
            <div class="nav-bar">
                <div class="modal-header">
                <h5 class="modal-title">{{ $t('assistant.assistant') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="container text-center">
                        <form method="get" class="form-assistant">
                            <input v-model="searchValue" id="search_assis_popup" :placeholder="$t('assistant.assistant_search')" type="text" name="assistant" value="" class="assistant form-control-custom search-query">
                            <select v-if="this.isFavouriteOnClicked" name="tag" v-model="refilter_tag" @change="filterByTag($event)" class="select_tag">
                                <option value="">{{ $t('all.all') }}</option>
                                <option v-for="(tag_chat, index_tag_chat) in allTagList" :key="index_tag_chat" :value="tag_chat.id">{{tag_chat.name}}</option>
                            </select>
                            <select v-else-if="!this.isMyAssistantOnClicked" name="tag" v-model="refilter_tag" @change="filterByTag($event)" class="select_tag">
                                <option value="">{{ $t('all.all') }}</option>
                                <option v-for="(tag_chat, index_tag_chat) in listTags.tags_system" :key="index_tag_chat" :value="tag_chat.id">{{tag_chat.name}}</option>
                            </select>
                            <select v-else-if="this.isMyAssistantOnClicked" name="tag" v-model="refilter_tag" @change="filterByTag($event)" class="select_tag">
                                <option value="">{{ $t('all.all') }}</option>
                                <option v-for="(tag_chat, index_tag_chat) in listTags.tags_shop" :key="index_tag_chat" :value="tag_chat.id">{{tag_chat.name}}</option>
                            </select>
                            <!-- Yêu thích -->
                            <input @click="listFavourite()" class="favorite form-control-custom search-query" :class="{ 'button-hover': isFavouriteOnClicked, 'clicked': isFavouriteOnClicked }" name="favourite" type="button" :value="$t('assistant.assistant_like')">
                            <!-- My Assistant -->
                            <input @click="myAssiatant()" class="mine form-control-custom search-query" :class="{ 'button-hover': isMyAssistantOnClicked, 'clicked': isMyAssistantOnClicked }" name="mine" type="button" :value="$t('assistant.assistant_me')">
                        </form>
                </div>
            </div>

            <div class="modal-body">
                <div class="main-content">
                    
                    <div class="container-fluid mt-35">
                    <div class="row">
                        <div v-for="prompt in this.visiblePrompts" :key="prompt.id" id="list_assis_popup" class="col-md-3 mb-15">
                            <div class="content-assis">
                                <div class="title-assis">
                                    <div v-if="prompt.avatar_url"><img :src="prompt.avatar_url" alt=""></div>
                                    <div v-else><img :src="'https://salekit.io/favicon.ico'" alt=""></div>
                                    <div class="text-assis">
                                        <div class="name_text">
                                            <a href="#" @click="appendPromptAndCloseModal(prompt)" >{{ prompt.name }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="des-assis" :title="prompt.description">
                                    <div class="desc_text"> 
                                        {{ prompt.description }}
                                    </div>
                                </div>
                                <div class="show-social">
                                    <div class="show-preview">
                                        <a href="#"> 
                                            <i class="fa fa-eye" aria-hidden="true" title="view"></i>
                                            {{ prompt.uses }}
                                        </a>
                                    </div>
                                    <div class="show-likes">
                                        <a href="#" @click="updatePromptLikes(prompt)">
                                            <i v-if="prompt.is_like == 0" class="fa fa-thumbs-up" aria-hidden="true" title="likes"></i>
                                            <i v-else class="fa fa-thumbs-up" aria-hidden="true" title="likes" style="color:rgb(221, 79, 221)"></i>
                                            {{prompt.likes }}
                                        </a>
                                    </div>
                                    <div class="show-favorite">
                                        <a href="#" @click="updateFavourite(prompt)">
                                            <i v-if="prompt.is_favourite==1" class="fa fa-heart" aria-hidden="true" title="favourite" style="color:rgb(9, 50, 255)"></i>
                                            <i v-else class="fa fa-heart" aria-hidden="true" title="favourite"></i>
                                            {{ prompt.favourites }}
                                        </a>
                                    </div>
                                    <div v-if="prompt.help_url" class="show-question">
                                        <a target="blank" :href="'https://help.fchat.ai/'+prompt.help_url"><i class="fa fa-question-circle" aria-hidden="true" title="question"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                    <div class="row text-center divloadmore">
                    <div class="col-sm-12">
                        <div @click="visiblePrompt += step" v-if="visiblePrompt < listPrompt.length" class="btn btn-primary more" data-toggle="modal" data-target="#assisFilter" id="loadmore" data-ofset="20">
                            <i class="fa fa-spinner fa-spin loading d-none"></i> {{ $t('load_more.load_more') }}
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

export default {
  name: "PopupAssistant",
  props: {
    aUser: Object,
    listPrompt: Array,
    listTags: [Object, Array],
    is_shop: Boolean,
    is_favourite: Boolean,
  },
  data() {
    return {
        isMyAssistantOnClicked: false,
        isFavouriteOnClicked: false,
        visiblePrompt: 15,
        step: 15,
        searchValue: "",
        refilter_tag: '',
        page: 2,
    };
  },
  computed:{
    visiblePrompts() {
        return this.listPrompt.slice(0, this.visiblePrompt)
    },
    allTagList() {
        return this.listTags.tags_system.concat(this.listTags.tags_shop);
    },
  },
  watch: {
    searchValue: function(newSearchValue, oldSearchValue) {
        this.updateVisiblePrompts();
    },
    visiblePrompt: function(newVisiblePrompt, oldVisiblePrompt) {
        if (newVisiblePrompt > this.listPrompt.length) {
            this.moreVisiblePrompt();
        }
    },
  },
  methods: {
    updateVisiblePrompts: async function() {
      const searchTerm = this.searchValue.toLowerCase().trim();
      let object_data = {
        keyword: searchTerm,
        is_shop: this.isMyAssistantOnClicked,
        limit: 500
      };
      await this.$root.$refs.compname_component.getListPrompt(object_data);
    },
    moreVisiblePrompt() {
        this.$root.$refs.compname_component.getPageListPrompt({page: this.page});
        this.page += 1;
    },
    listFavourite(){
        this.isFavouriteOnClicked = !this.isFavouriteOnClicked;
        let object_data = {
            user_id: this.aUser.id,
            is_favourite: this.isFavouriteOnClicked,
            isMyAssistantOnClicked: this.isMyAssistantOnClicked,
        }
        this.$root.$refs.compname_component.listFavourite(object_data);
    },
    myAssiatant(){
        this.isMyAssistantOnClicked = !this.isMyAssistantOnClicked;
        this.$root.$refs.compname_component.myAssiatant(this.isMyAssistantOnClicked);
    },
    appendPromptAndCloseModal(prompt){
        this.$root.$refs.compname_component.innerAssitantPrompt(prompt);
        this.$root.$refs.compname_component.updatePromptView(prompt);
        $(this.$refs.fullScreenModal).modal('hide');
    },
    filterByTag: function(tagevent) { 
         console.log('======= new tag value: ', tagevent.target.value);
         
        let object_data = {
            tag: tagevent.target.value,
            is_shop: this.isMyAssistantOnClicked
        }
        if(tagevent.target.value ===""){
            console.log('======= new tag value: null');
            let object_data = {
            tag: tagevent.target.value,
            is_shop: this.isMyAssistantOnClicked,
           
            }
            console.log('======= new tag value: null',object_data);
            
        }
        this.$root.$refs.compname_component.getListPrompt(object_data);
    },
    updateFavourite(newPrompt){
      this.$root.$refs.compname_component.updateFavourite(newPrompt);
    },
    updatePromptLikes(newPrompt){
      this.$root.$refs.compname_component.updatePromptLikes(newPrompt);
    },
  },
};
</script>

<style scoped src="../../assets/css/popupassiatant.css"></style>