import Vue from 'vue'
 import App from './App.vue'
import router from './router/router'
import store from './store/index'

// import Assistant from '@/components/assistant.vue';
import VueI18n from 'vue-i18n'
import enMessage from '../language/en.json'
import viMessage from '../language/vi.json'
import VueCookies from 'vue-cookies'

import './assets/css/style.css'
import './assets/css/admin.css'
import './assets/css/assistant.css'
// import './assets/bootstrap/bootstrap.min.css'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// var Vue = require('vue')
Vue.use(VueCookies)

Vue.use(VueI18n)
Vue.use(Toast)

let messages;
let lang;

window.$cookies.get('lang');
if (window.$cookies.get('lang') && window.$cookies.get('lang') != 'vi') {
	lang = 'en';
	messages = {
		en: enMessage,
	}
}
else {
	lang = 'vi';
	messages = {
		vi: viMessage,
	}
}


const i18n = new VueI18n({
  locale: lang, // set locale
  messages,
  fallbackLocale: lang,
})

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
  // render: h => h(Assistant),

}).$mount('#app');

