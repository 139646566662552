// import http from "../http-common";
import 'dotenv'
import axios from 'axios';
import config from "../config";
import Vue from 'vue';
// let local="http://localhost:3034/api/v1/";
var baseUrl = window.location.origin + '/api/v1/'
// var baseUrl ='http://fchat.ai.local/api/v2/'

class gptService {
  getLogin() {
    return axios.post(baseUrl + 'login', {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      //console.log(response);
      return response;
    })
      .catch(function (error) {
        console.log(error);
      });
  }
  // getPromptTemplate() {
  //   return axios.get('https://fchat-app.salekit.com:4039/api/v1/templates/prompt', {
  //     headers: {
  //       'X-Access-Token': 'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDI0LTA2LTI4VDE3OjEyOjQyKzA3MDAiLCJzaG9wX2lkIjoiNWI0ODcyZDEzODAzODk2ZGQ3NzEyNWFmIn0.TXqy9H2r5CAXr2d7_eeYGMrdqiCp5R0ZaBu7J1zml7A',
  //       // 'X-Access-Token':token,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(function (response) {
  //       return response;
  //     })
  // }
  getPromptTemplate(token) {
    return axios.get(config.API_URL + 'template/list', {
      headers: {
        'Token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        return response;
      })
  }

  getConversations(object, token) {
    let url = '';
    let where = '';
    // Lọc theo page
    if (object?.shop_id) {
      where += '&shop_id=' + object.shop_id;
    }
    if (object?.tag) {
      where += '&tag=' + object.tag;
    }
    url = config.API_URL + 'conversation/list?page=' + object?.page + '&limit=' + config.PAGEINATION + where;

    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  getListPrompt(object, token) {
    let url = '';
    let where = '';
    let is_shop = object.is_shop;
    let keyword = object.keyword;
    let package_id = object.package_id;
    let tag = object.tag;
    let page = object.page;
    let like = object.like;
    let user_id = object.user_id;
    let myassistant = object.myassistant;
    let type_action = object.type_action;
    let shop_id_owner = object.shop_id_owner;
    // let limit = object.limit;
    if (is_shop) {
      where += '&is_shop=' + is_shop;
    }
    if (shop_id_owner) {
      where += '&shop_id_owner=' + shop_id_owner;
    }
    if (tag) {
      where += '&tag=' + tag;
    }
    if (keyword) {
      where += '&keyword=' + keyword;
    }
    if (page) {
      where += '&page=' + page
    }
    if (like) {
      where += '&like=' + like
    }
    if (myassistant) {
      where += '&myassistant=' + myassistant
    }
    if (user_id) {
      where += '&user_id=' + user_id;
    }
    if (package_id) {
      where += '&package_id=' + package_id;
    }
    if (type_action) {
      where += '&type_action=' + type_action;
    }

    url = config.API_URL + 'prompt/list?limit=' + config.PAGEINATION + where;

    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  getListTag(token, object) {
    let url = '';
    url = config.API_URL + 'tag/list?shop_id_owner=' + object.shop_id_owner;
    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  getListFavourite(object, token) {
    let url = '';
    let where = '';
    if (object.user_id) {
      where += '&user_id=' + object.user_id;
    }
    if (object.shop_id) {
      where += '&shop_id=' + object.shop_id;
    }
    url = config.API_URL + 'prompt/list/favourite?page=' + object.page + '&limit=' + config.PAGEINATION + where;
    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  getListMyPrompt(token, user_id) {
    let url = "";
    url = config.API_URL + 'prompt/list-own?user_id' + user_id;
    try {
      const response = axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  getPackage(shop_id, token) {
    let url = config.API_URL + 'package?shop_id=' + shop_id;
    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  getMessages(conv_id, page, token) {
    return axios({
      url: config.API_URL + 'message/list?conv_id=' + conv_id + '&page=' + page + '&paging=15',
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(function (response) {
      console.log(response);
    })
  }

  sendMessage(object, version, token) {
    var url;
    url = config.API_URL + 'chatgpt/convert';
    let check = false
    if (object && object.is_image_openai == true) {
      check = true
    }
    return axios({
      method: 'post',
      url: url,
      data: object,
      headers: {
        "Content-type": "application/json",
        "token": token,
        "version": version,
        "is_image_openai": check
      }
    })
      .then(function (response) {
        if (response.status != 200) {
          Vue.notify({ group: 'notification_box', type: 'error', title: 'Lỗi', text: 'Gửi tin thất bại, vui lòng thử lại hoặc liên hệ Quản trị viên! Mã lỗi: ' + response.status })
        } else {
          return response;
        }

      })
      .catch(function (response) {
        //console.log("🚀 ~ file: LivechatService.js ~ line 219 ~ LivechatService ~ sendMessage ~ response", response)
        //handle error
        Vue.notify({ group: 'notification_box', type: 'error', title: 'Lỗi', text: 'Gửi tin thất bại, vui lòng thử lại hoặc liên hệ Quản trị viên! lỗi: ' + response })
      })
  }

  updatePromptBookmark(object, token) {
    let url = config.API_URL + 'prompt/update';
    return axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  updatePromptView(object, token) {
    let url = config.API_URL + 'prompt/update/view';
    return axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  updatePromptLikes(object, token) {
    let url = config.API_URL + 'prompt/action';
    return axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  updateFavourite(object, token) {
    let url = config.API_URL + 'prompt/update/favourite';
    return axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  deleteFavourite(object, token) {
    let url = config.API_URL + 'prompt/delete/favourite';
    return axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  async getListPromptRecent(object, token) {
    let url = '';

    url = config.API_URL + 'prompt/recent/list?user_id=' + object.user_id + '&limit=' + object.limit;
    return await axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }
  async insertPromptRecent(object, token) {
    let url = config.API_URL + 'prompt/recent/insert';
    return await axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }
  async deletePromptRecent(object, token) {
    let url = config.API_URL + 'prompt/recent/delete';
    return await axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }
}

export default new gptService();
