<template>
  <div class="modal-backdrop-answer">

    <div class="modal-assistant">

      <div class="modal-icon">
        <div class="dropleft">
          <button class=" " style="border: none; background:none" type="button" data-toggle="dropdown"
            aria-expanded="false">
            <img class="cursor-pointer" src="@/assets/svg/ellipsis.svg" alt="">
          </button>
          <div class="dropdown-menu" style="margin-top: 44px; margin-left:40px">
            <a class="dropdown-item d-flex align-items-center" href="#" v-if="keepSidebar == false"
              @click="handleKeepSidebar(PropsHeader)">
              <span><img src="@/assets/svg/keep_sidebar.svg" alt=""></span>
              <span class="ml-8px">{{ $t('keep_in_sidebar.keep_in_sidebar') }}</span>
            </a>
            <a class="dropdown-item d-flex align-items-center" href="#" v-if="keepSidebar == true"
              @click="handleHideSidebar(PropsHeader)">
              <span><img src="@/assets/svg/hide_form_sidebar.svg" alt=""></span>
              <span class="ml-8px">{{ $t('hide_form_sidebar.hide_form_sidebar') }}</span>

            </a>
            <a @click="copyURL" class="dropdown-item d-flex align-items-center" href="#">
              <span><img src="@/assets/svg/copy_link.svg" alt=""></span>
              <span class="ml-8px">{{ $t('copy_link.copy_link') }}</span>
            </a>
          </div>
        </div>
        <!-- <img class="cursor-pointer" src="@/assets/svg/ellipsis.svg" alt=""> -->
        <img class="cursor-pointer" src="@/assets/svg/close.svg" alt="" @click="close">
      </div>

      <header class="modal_header" v-if="PropsHeader">
        <div class=" d-flex justify-content-center">
          <img v-if="PropsHeader.avatar_url" :src="PropsHeader.avatar_url" class="rounded-circle" width="64" height="64"
            alt="">
          <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" class="rounded-circle" width="64"
            height="64" alt="">
        </div>
        <div class="d-flex justify-content-center text-center pt-12px text-black fw-600 fs-16 ">
          {{ PropsHeader.name }}
        </div>
        <div class="text-neutral fs-12 fw-400 text-center pt-12px">Bởi {{ PropsHeader.author }}</div>
        <div class="text-black fs-14 fw-400 text-center pt-12px">{{ PropsHeader.description }}</div>
        <div class="text-center pt-32px ">
          <div class="row g-2" v-if="PropsHeader">
            <div class="col-4">
              <div class="p-2 d-flex align-items-center justify-content-center">
                <img src="@/assets/svg/favourite.svg" alt="">
                <span class="ml-8px fs-20 fw-600 text-black">{{ PropsHeader.is_favourite }}</span>
              </div>
              <div class="fs-10 fw-400 text-neutral">{{ $t('favorites.favorites') }}</div>
            </div>
            <div class="col-4">
              <div v-if="PropsHeader.category_type == 1 && !category.prompts" class="p-2 fs-20 fw-600 text-black">{{
                $t('personal_assistant.personal_assistant')
              }}
              </div>
              <div v-else-if="category.type == 1 && category.prompts" class="p-2 fs-20 fw-600 text-black">{{
                $t('topic.topic')
              }}
              </div>
              <div v-else-if="PropsHeader.category_type == 2" class="p-2 fs-20 fw-600 text-black">Workflow
              </div>
              <div v-else class="p-2 fs-20 fw-600 text-black">
                {{ $t('all.all') }}
              </div>
              <div class="fs-10 fw-400 text-neutral">{{ $t('category.category') }}</div>
            </div>
            <div class="col-4">
              <div class="p-2 d-flex align-items-center justify-content-center">
                <img src="@/assets/svg/view.svg" alt="">
                <span class="ml-8px fs-20 fw-600 text-black">{{ PropsHeader.uses }}</span>

              </div>
              <div class="fs-10 fw-400 text-neutral">{{ $t('number_of_uses.number_of_uses') }}</div>
            </div>

          </div>
        </div>
        
      </header>
      <section class="modal_body">
        <slot name="body" />
        <div class="row " v-if="this.PropsHeader && this.PropsHeader.chat_initiation_tool">
        <div class="body_title fs-14 fw-600 text-black">Bắt đầu cuộc hội thoại</div>
          <div v-for="(item, index) in limitedList" :key="index"
            class="col-lg-6 col-md-6 col-sm-6 col-6 px-1 px-md-1 mt-3">
            <div class="" style="display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: 64px;
    border: 1px solid #DADFEB">
              <div class="px-2">
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="body_title fs-14 fw-600 text-black mt-3" v-if="PropsHeader">
          <span v-if="PropsHeader.category_type == 1 && !category.prompts">{{
            $t('personal_assistant.personal_assistant')
          }}:</span>
          <span v-if="category.type == 1 && category.prompts">
            {{ $t('topic.topic') }}:</span>
          <span v-if="PropsHeader.category_type == 2">Workflow:
          </span>

          {{ category.name }} <span v-if="PropsHeader.category_type == 2">({{ category.category_count }})</span>
        </div>
        <div class="row flex-row flex-nowrap  scrollable-row mx-md-n5 pb-10px">
          <div v-for="(item, index) in PropsBody" :key="index" class="col-md-7 px-1 px-md-1 mt-3 cursor-pointer">
            <div class="custom-item-topic">
              <div class="d-flex bg-neutral radius-16px " @click.prevent="appendPrompt(item)"
                :class="{ 'bg-focux': PropsHeader.id === item.id, 'bg-no-focus': PropsHeader.id !== item.id }">


                <div v-if="PropsHeader.category_type == 2"
                  class="justify-center align-content-center fs-20 fw-600 pl-12px">
                  {{ item.step }}
                </div>

                <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-12-24">
                  <img v-if="item.avatar_url" :src="item.avatar_url" width="48" height="48" class="img-fluid "
                    alt="svg">
                  <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48"
                    class="img-fluid " alt="svg">
                </div>
                <div class="flex-grow-1 p-8-12">
                  <div class="fs-14 fw-600 text-black limit-text-2-line">
                    {{ item.name }}
                  </div>
                  <div class="fw-12 fw-400 limit-text-2-line" style="color: #575B68;padding-top:4px;">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-7 px-1 px-md-1 mt-3 cursor-pointer">
              <div class="custom-item-topic">
                  <div class="d-flex bg-neutral radius-16px">
                      <div class="justify-center align-content-center fs-20 fw-600 p-0-12">1
                      </div>
                      <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-8px-0px">
                          <img src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48"
                              class="img-fluid " alt="svg">
                      </div>
                      <div class="flex-grow-1 p-8-12">
                          <div class="fs-14 fw-600 text-black limit-text-2-line">
                              Tạo outline, đề cương cho bài luận học thuật đỉnh cao cùng Fchat.ai
                          </div>
                          <div class="fw-12 fw-400 limit-text-2-line" style="color: #575B68;padding-top:4px;">
                              Chuyên gia biên soạn và tạo đề cương cho các bài luận thể loại học thuật.
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-7 px-1 px-md-1 mt-3 cursor-pointer">
              <div class="custom-item-topic">
                  <div class="d-flex bg-neutral radius-16px">
                      <div class="justify-center align-content-center fs-20 fw-600 p-0-12">1
                      </div>
                      <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-8px-0px">
                          <img src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48"
                              class="img-fluid " alt="svg">
                      </div>
                      <div class="flex-grow-1 p-8-12">
                          <div class="fs-14 fw-600 text-black limit-text-2-line">
                              Tạo outline, đề cương cho bài luận học thuật đỉnh cao cùng Fchat.ai
                          </div>
                          <div class="fw-12 fw-400 limit-text-2-line" style="color: #575B68;padding-top:4px;">
                              Chuyên gia biên soạn và tạo đề cương cho các bài luận thể loại học thuật.
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-7 px-1 px-md-1 mt-3 cursor-pointer">
              <div class="custom-item-topic">
                  <div class="d-flex bg-neutral radius-16px">
                      <div class="justify-center align-content-center fs-20 fw-600 p-0-12">1
                      </div>
                      <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-8px-0px">
                          <img src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48"
                              class="img-fluid " alt="svg">
                      </div>
                      <div class="flex-grow-1 p-8-12">
                          <div class="fs-14 fw-600 text-black limit-text-2-line">
                              Tạo outline, đề cương cho bài luận học thuật đỉnh cao cùng Fchat.ai
                          </div>
                          <div class="fw-12 fw-400 limit-text-2-line" style="color: #575B68;padding-top:4px;">
                              Chuyên gia biên soạn và tạo đề cương cho các bài luận thể loại học thuật.
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->
        </div>
      </section>
      <footer v-if="!isChatgptPage" class="modal_footer">
        <slot name="footer" />
        <button @click="sendChat()" class="btn-send-chat ">Bắt đầu đoạn chat</button>
      </footer>
    </div>

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: 'ModalAssistant',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    category: {
      type: Object,
      required: false,
      default: () => ({})
    },
    PropsBody: {
      type: Array,
      required: false,
      default: () => []
    },
    PropsHeader: {
      type: Object,
      required: false,
      default: () => ({})
    },
    close: {
      type: Function,
      required: false,
      default: () => { }
    },
    open: {
      type: Function,
      required: false,
      default: () => { }
    },
    updateDataProps: {
      type: Function,
      required: false,
      default: () => { }
    },
  },
  data() {
    return {
      keepSidebar: false,
      decodedList: []
    }
  },
  created() {
    if (this.PropsHeader.is_recent) {
      this.keepSidebar = true
    }

  },
  computed: {
    isChatgptPage() {
      return this.$route.path === '/';
    },
    ...mapState(["welcome"]),
    limitedList() {
      return this.decodedList.filter(item => item !== null).slice(0, 4);
    }
  },

  mounted() {
    if (this.PropsHeader && this.PropsHeader.chat_initiation_tool) {
      try {
        this.decodedList = JSON.parse(this.PropsHeader.chat_initiation_tool) || [];
        this.decodedList = this.decodedList.map(item => item ? decodeURIComponent(JSON.parse(`"${item}"`)) : item);
      } catch (error) {
        console.error('Lỗi khi phân tích cú pháp JSON:', error);
      }
    }
    // console.log(this.PropsHeader)
    // console.log(this.PropsBody)
    // console.log(this.category)
  },
  methods: {
    ...mapMutations({
      setWelcome: 'setWelcome',
    }),
    handleHideSidebar(data) {
      let aUser = JSON.parse(localStorage.getItem("aUser"))
      this.$root.$refs.compname_component.deletePromptRecents({ user_id: aUser.id, prompt_id: data.id })
      this.keepSidebar = false

      this.$toast.success("Ẩn khỏi sidebar thành công", { timeout: 1000 });
    },
    handleKeepSidebar(data) {
      let aUser = JSON.parse(localStorage.getItem("aUser"))
      this.$root.$refs.compname_component.insertPromptRecents({ user_id: aUser.id, prompt_id: data.id })
      this.keepSidebar = true

      this.$toast.success("Giữ ở sidebar thành công!", { timeout: 1000 });
    }
    ,
    appendPrompt(item) {
      localStorage.setItem('category', JSON.stringify(this.category))
      localStorage.setItem('PropsHeader', JSON.stringify(item))
      localStorage.setItem('PropsBody', JSON.stringify(this.PropsBody))
      if (this.$route.path === '/') {
        this.$root.$refs.compname_component_content_main.appendPrompt(item)
      } else {
        let category = JSON.parse(localStorage.getItem('category')) || []
        let PropsBody = JSON.parse(localStorage.getItem('PropsBody')) || []
        let PropsHeader = JSON.parse(localStorage.getItem("PropsHeader")) || '';
        this.updateDataProps({ category: category, PropsHeader: PropsHeader, PropsBody: PropsBody });
      }
    },
    sendChat() {
      localStorage.setItem('category', JSON.stringify(this.category))
      localStorage.setItem('PropsHeader', JSON.stringify(this.PropsHeader))
      localStorage.setItem('PropsBody', JSON.stringify(this.PropsBody))
      this.setWelcome(false)
      this.$router.push({ path: '/', query: { id: 0 } })
      // this.$router.push('/chatgpt')
    },
    async copyURL() {
      if (this.PropsHeader) {
        let link = 'https://fchat.ai/share/' + this.PropsHeader.id_hash
        await navigator.clipboard.writeText(link);
      }
      alert('Sao chép thành công!');
    }
  }
};
</script>

<style lang="css">
.modal-backdrop-answer {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_header {
  border-bottom: none;
  /* padding: 10px; */
}

.modal_footer {
  border-top: none;
  margin: 32px 16px 16px 16px;
}

.modal_body {
  padding: 32px 17.5px 12px 17.5px !important;
}

.scrollable-row {
  overflow-x: auto;
  /* white-space: nowrap; */
}

.modal-icon {
  display: flex;
  justify-content: end;

  /* img {
      padding: 12px 6px 0px 12px;
  } */
}

.modal-assistant {
  position: relative;
  width: 612px;
  z-index: 1000;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20% auto;
  padding: 16px;
}

.modal__title {
  color: #272727;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

}

.btn-send-chat {
  background: #2D5791;
  color: white;
  border-radius: 32px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

@media (max-width: 767px) {
  .modal-assistant {
    width: 350px;
    padding: 12px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 992px) and (max-width: 1199px) {}
</style>
