import { render, staticRenderFns } from "./WorkflowStep.vue?vue&type=template&id=0b511eea&scoped=true"
import script from "./WorkflowStep.vue?vue&type=script&lang=js"
export * from "./WorkflowStep.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/gpts.css?vue&type=style&index=0&id=0b511eea&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b511eea",
  null
  
)

export default component.exports