<template>
    <div class="modal-backdrop-answer">

        <div class="modal-answer">
            <div id="cancel-rewrite" class="icon-cancel-answer" @click="close">
                <i class="fa-solid fa-xmark"></i>
            </div>

            <header class="modal-header">
                <span class="modal__title">{{ title }}</span>
            </header>
            <section class="modal-body">
                <slot name="body" />
            </section>
            <footer class="modal-footer" style="padding: 10px 10px 20px 10px;">
                <slot name="footer" />
            </footer>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ModalRewrite',
    props: {
        title: {
            type: String,
            required: false
        },
        close: {
            type: Function,
            required: true
        }
    },
    methods: {

    }
};
</script>

<style lang="css">
.modal-backdrop-answer {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding: 300px; */
}

.modal-header {
    border-bottom: none;
    padding: 10px;
}

.modal-footer {
    border-top: none;


}

.modal-body {
    padding: 0;
    /* padding: 10px; */

}

.modal-answer {
    position: relative;
    width: 612px;
    z-index: 1000;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20% auto;
}

.btn-close {
    position: absolute;
    top: 17px;
    right: 17px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #2196F3;
    background: transparent;
}

.modal__title {
    color: #272727;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

}

.icon-cancel-answer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #F4F7FA;
    box-shadow: 0px 0px 4px 0px #2D579140;
    width: 25px;
    height: 25px;
    right: -8px;
    top: -10px;
    cursor: pointer;
}

@media (max-width: 576px) {
    .modal-answer {
        width: 350px;
        top: 30%;
    }


}

@media (min-width: 576px) and (max-width: 767px) {
    .modal-answer {
        width: 350px;
        top: 30%;

    }

}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 992px) and (max-width: 1199px) {}
</style>
