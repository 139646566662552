<template>
    <div class="container gpts">
        <div class="gpts_title">
            {{ $t('gpt_assistant.gpt_assistant') }}
        </div>
        <div class="gpts_des">
            {{ $t('gpts_des.gpts_des') }}
        </div>
        <div class="d-flex flex-column mb-3">

            <GPTS_HEADER />


            <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto sticky-nav"
                :style="{ 'z-index': showDropdown == true ? '0' : '100' }">
                <GPTS_NAV :activeNav="activeNav" :scrollToSection="scrollToSection" />
            </div>
            <div class="gpts_search col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                <div class="gpts_content row">

                    <!-- Tất cả  -->
                    <div id="all" class="all">
                        <AllPrompt :listPrompt="this.listPrompt" />
                    </div>
                    <!-- Chủ đề  -->
                    <div id="topic" class="topic pt-40">
                        <Topic :listTags="this.listTags" />
                    </div>

                    <!-- Bộ trợ lý  -->
                    <div id="collection" class="collection pt-40">
                        <Collection :listCollections="this.listCollections" />
                    </div>
                    <!-- Workflow  -->
                    <div id="workflow" class="collection pt-40">
                        <Workflow :listWorkflows="this.listWorkflows" />
                    </div>

                    <!-- Trợ lý yêu thích  -->
                    <div id="favorite_assistant" class="collection pt-40  ">
                        <Favourite :listFavourite="this.listFavourite" />
                    </div>
                    <!-- Trợ lý của tôi  -->
                    <div id="my_assistant" class="my_assistant pt-40  ">
                        <MyPrompt :listMyPrompt="this.listMyPrompt" />
                    </div>

                    <router-view></router-view>

                </div>
            </div>
        </div>
        <ModalAssistant v-if="isModalAssistant == true" :close="closeIsModalAssistant" :PropsHeader="PropsHeader" />
    </div>
</template>

<script>
// import config from "@/config";
import gptService from "@/services/gptService";
import categoryService from "@/services/categoryService";

import Topic from '@/pages/gpts/topic/Topic.vue'
import AllPrompt from '@/pages/gpts/prompt/AllPrompt.vue'
import MyPrompt from '@/pages/gpts/prompt/MyPrompt.vue'
import Favourite from '@/pages/gpts/favourite/Favourite.vue'
import Collection from '@/pages/gpts/collection/Collection.vue'
import Workflow from '@/pages/gpts/workflow/Workflow.vue'
import GPTS_NAV from '@/pages/gpts/GPTS_NAV.vue'
import GPTS_HEADER from '@/pages/gpts/GPTS_HEADER.vue'
import ModalAssistant from '@/components/popupmodal/ModalAssistant'
import { mapState } from 'vuex';

export default {
    name: 'GPTS',
    components: {
        Topic,
        AllPrompt,
        Collection,
        Workflow,
        GPTS_NAV,
        Favourite,
        MyPrompt,
        ModalAssistant, GPTS_HEADER
    },

    data() {
        return {
            activeNav: 'all',
            paddingTopValue: 40, // Giá trị padding-top bạn muốn thiết lập
            sectionIds: ['all', 'topic', 'collection', 'workflow', 'favorite_assistant', 'my_assistant'],
            listPrompt: [],
            listTags: [],
            listCollections: [],
            listWorkflows: [],
            token: '',
            aUser: {},
            aPackage: {},
            aShop: {},
            showDetail: false,
            listMyPrompt: [],
            listFavourite: [],
            isModalAssistant: false,
            website:{}
        }
    },

  async created() {
        this.$root.$refs.gpts = this;
        this.website = JSON.parse(localStorage.getItem('website')) ;

    },
    // beforeRouteLeave(to, from, next) {
    // this.$store.dispatch('setListGPTS', true);
    // next();
    // },
    beforeMount() {
        window.addEventListener('wheel', this.handleScroll)
    },

    beforeDestroy() {
        window.removeEventListener('wheel', this.handleScroll);

    },
   async mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');
        this.aUser = JSON.parse(localStorage.getItem('aUser'));
        this.website = JSON.parse(localStorage.getItem('website')) ;
     await this.getListPrompt({
            package_id: this.aPackage.package_id,
            shop_id_owner:this.website.shop_id

        })
        await this.getListTag({})
        await this.getListAssistantSet(this.token, {
            type: 1,
            package_id: this.aPackage.package_id,
            shop_id_owner: this.website.shop_id
        })
        await this.getListWorkflow(this.token, {
            type: 2,
            package_id: this.aPackage.package_id,
            shop_id_owner: this.website.shop_id
        })
        await this.getListFavourite({ page: 1, limit: 20, user_id: this.aUser.id })
        this.getListMyPrompt(this.token, {user_id:this.aUser.id, shop_id_owner: this.website.shop_id })

    },

    computed: {
        ...mapState(['showDropdown']),
        listGPTS() {
            return this.$store.getters.listGPTS;
        },
    },
    watch: {

    },
    methods: {
        showIsModalAssistant(item) {
            this.isModalAssistant = true
            this.PropsHeader = item
            this.category = item
        },
        closeIsModalAssistant() {
            this.isModalAssistant = false
        },

       async getListMyPrompt(token, object) {

          await  gptService.getListMyPrompt(token, object)
                .then((res) => {
                    if (!res.data.error) {
                        this.listMyPrompt = res.data.data;
                        // console.log(this.listMyPrompt)
                        localStorage.setItem('myPrompt', JSON.stringify(this.listPrompt))
                    }
                }).catch((error) => {
                    console.log(error);
                });
        },
        async getListFavourite(object_data) {
            let token = localStorage.getItem('token');
           await gptService.getListFavourite(object_data, token)
                .then((res) => {
                    if (!res.data.error) {
                        this.listFavourite = res.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
       async getListPrompt(object_data) {
            object_data.user_id = this.aUser.id;
            object_data.package_id = this.aPackage.package_id;
            // object_data.type_action = this.type_action;
           await gptService.getListPrompt(object_data, this.token)
                .then((res) => {
                    if (!res.data.error) {
                        this.listPrompt = res.data.data;
                        localStorage.setItem('listPrompt', JSON.stringify(this.listPrompt))
                    }
                }).catch((error) => {
                    console.log(error);
                });
        },
       async getListTag(object) {
            object.shop_id_owner=this.website.shop_id;
          await gptService.getListTag(this.token, object)
                .then((res) => {
                    if (!res.data.error) {
                        this.listTags = res.data.data.tags_system;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
       async getListAssistantSet(token, object) {
           await categoryService.getListCategory(token, object)
                .then((res) => {
                    this.listCollections = res.data.data.category_system
                    // console.log(this.listCollections);
                    localStorage.setItem('listCollection', JSON.stringify(this.listCollections))

                }).catch((error) => {
                    console.log(error);
                });
        },
      async getListWorkflow(token, object) {

           await categoryService.getListCategory(token, object)
                .then((res) => {
                    this.listWorkflows = res.data.data.category_system
                    localStorage.setItem('listWorkflows', JSON.stringify(this.listWorkflows))

                }).catch((error) => {
                    console.log(error);
                });
        },
        handleScroll() {

            this.sectionIds.forEach((sectionId) => {
                const element = document.getElementById(sectionId);

                if (element) {
                    if (window.scrollY > element.offsetTop) {
                        element.style.paddingTop = `${this.paddingTopValue}px`;
                    } else {
                        if (sectionId === "all") {
                            element.style.paddingTop = "8px";
                        } else {
                            element.style.paddingTop = "40px";
                        }
                    }
                }
            });

        },
        scrollToSection(sectionId) {
            // this.$store.dispatch('setListGPTS', true);

            const previousElement = document.getElementById(this.activeNav);
            if (previousElement) {
                previousElement.style.paddingTop = ""; // Xóa padding-top của element cũ
            }

            const element = document.getElementById(sectionId);

            if (element.id === "my_assistant") {
                element.style.paddingBottom = "60vh";
            }

            if (element) {
                // Thêm padding-top vào element mới
                element.style.paddingTop = "120px";

                element.scrollIntoView({
                    behavior: "smooth",
                });

                this.activeNav = sectionId;
            }
        },

    }

}
</script>

<style scoped src="../../assets/css/gpts.css"></style>
