<template>
<div class="gpts_nav_container col-lg-9 col-md-12">
    <ul class="gpts_nav">
        <li><a :class="{ 'active': activeNav === 'all' }" href="#" @click="scrollToSection('all')">{{
                                $t('all.all') }}</a></li>
        <li><a :class="{ 'active': activeNav === 'topic' }" href="#" @click="scrollToSection('topic')">{{ $t('topic.topic') }}</a></li>
        <li><a :class="{ 'active': activeNav === 'collection' }" href="#" @click="scrollToSection('collection')">{{ $t('personal_assistant.personal_assistant')
                                }}</a></li>
        <li><a :class="{ 'active': activeNav === 'workflow' }" href="#" @click="scrollToSection('workflow')">Workflow</a></li>
        <li><a :class="{ 'active': activeNav === 'favorite_assistant' }" href="#" @click="scrollToSection('favorite_assistant')">{{
                                    $t('favorite_assistant.favorite_assistant') }}</a></li>
        <li><a :class="{ 'active': activeNav === 'my_assistant' }" href="#" @click="scrollToSection('my_assistant')">{{ $t('my_assistant.my_assistant') }}</a></li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'GPTS_NAV',
    components: {},
    props: {
        scrollToSection: Function,
        activeNav: String
    },
    data() {
        return {

        }
    },
    created() {

    },
    beforeMount() {},

    beforeDestroy() {},
    mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');
    },

    computed: {

    },
    watch: {

    },
    methods: {

    }

}
</script>

<style scoped src="@/assets/css/gpts.css"></style>
