<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
   

    <nav class="main-header navbar navbar-expand navbar-white navbar-light " style=" border-bottom: 0px">
        <!-- Left navbar links -->
        <ul class="navbar-nav nav-menu">
            
            <li class="nav-item"  style="padding-top: 5px; padding-bottom: 5px;">
                <a href="/" class="brand-link">
                    <img src="https://fchat.ai/assets/images/EMO-fchat-03.png" style="width: 52px;" alt="">
                </a>
            </li>
            <li class="nav-item" style="background-color: rgb(11, 25, 111)" >
                <a href="/chatgpt" class="nav-link">
                    <i class="fa fa-comments nav-icon"></i>
                    <p>Chat GPT</p>
                </a>
            </li>
            <li class="nav-item ">
                <a href="/prompt" class="nav-link">
                    <i class="fas fa-font nav-icon"></i>
                    <p>Prompt</p>
                </a>
            </li>
            <li class="nav-item ">
                <a href="/tts" class="nav-link">
                    <i class="fas fa-font nav-icon"></i>
                    <p>Text to speech</p>
                </a>
            </li>
            <li class="nav-item  ">
                <a href="/stt" class="nav-link">
                    <i class="fas fa-microphone nav-icon"></i>
                    <p>Speech to text</p>
                </a>
            </li>
            <li class="nav-item ">
                <a href="/setting/info" class="nav-link">
                    <i class="fal fa-cog" aria-hidden="true"></i>
                    <p>{{ $t('config.config') }}</p>
                </a>
            </li>
           
        </ul>
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <li class="language_btn" style="margin-right: 18px; line-height: 45px;">
                <div class="dropdown">
                  <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                    <img style="vertical-align: middle;" width="25px" :src="flagImage">
                    <span class="caret d-none"></span>
                  </button>
                    <ul class="dropdown-menu">
                        <div  v-if="this.$i18n.locale === 'en'">
                            <li style="line-height: 20px;">
                                <a @click="changeLanguage('vi')" href="/change-language/vi"   >
                                    <img style="vertical-align: middle;" width="25px" src="https://salekit.io/assets/images/flag/vi.svg"> Tiếng Việt</a>
                            </li>
                        </div>
                        <div v-else>
                            <li style="line-height: 20px;" >
                                <a @click="changeLanguage('en')" href="/change-language/en"  >
                                    <img style="vertical-align: middle;" width="25px" src="https://salekit.io/assets/images/flag/en.svg"> English</a>
                            </li>
                        </div>
                    </ul>
                </div>
            </li>
    
            <li class="nav-item dropdown">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
                                        integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
                                        crossorigin="anonymous" referrerpolicy="no-referrer" />
                <div class="nav-item dropdown">
                    <!-- <img class="nav-user-photo" src="https://fchat.ai/assets/images/shop.png" title="Dat"> -->
                    <div class="dropdown fix_drop" style="display: grid; ">
                        <button class="btn-shoponl" data-toggle="dropdown" aria-expanded="false"> {{ aShop.name }}<br></button>
                        <div class="packge_display">
                            <a v-if="this.package" href="/setting/info" :title="$t('package_detail.package_detail')" class="pack_name_head ">
                                {{ this.package.package_id == 0 ? "Free" : this.package.package_id == 1 ? 'Pro' : this.package.package_id == 7 ? 'Bussiness': 'Free' }}
                            </a>
                            <div>
                                <small v-if="this.shop_role.role_id==10" class="pakage_name">
                                    Owner
                                </small>
                                <small v-else-if="this.shop_role.role_id==11" class="pakage_name">
                                    Admin
                                </small>
                                <small v-else class="pakage_name">
                                    User
                                </small>
                            </div>
                        </div>
    
                        <ul class="dropdown-menu-left dropdown-navbar dropdown-menu dropdown-caret dropdown-close drop-menu-shop">
                            <li class="dropdown-content" v-for="(shop_role, index) in this.listShop" :key="index">
                                <ul class="dropdown-menu dropdown-navbar dropdown-shop-list">
                                    <li class="active">
                                            <a :href="'/change-shop/'+ shop_role.shop_id">
                                                <!-- <img class="nav-user-photo" src="https://fchat.ai/assets/images/shop.png" alt=""> -->
                                                {{shop_role.name}}
                                            </a>
                                    </li>
                                </ul>
                            </li>
                            <hr>
                            <li class="create-shop">
                                <a href="/shop/add">
                                    <a href="/shop/add">＋ {{$t('create_new_shop.create_new_shop')}}</a>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="dropdown fix_drop cursor-ta">
                        <img class="nav-user-photo" data-toggle="dropdown" :src="'https://id.salemall.vn/avatar/'+aUser.id_salemall+'.png'">
                        <div class="user-menu dropdown-menu-right dropdown-menu dropdown-caret dropdown-close">
                            <ul>
                                <li>
                                    <a href="https://id.salemall.vn/profile" style="display: block; padding: 8px 10px !important; position: relative;">
                                        <i class="nav-icon fas fa-id-card"></i>
                                        {{ $t('profile.profile') }}
                                    </a>
                                </li>
                                <li>
                                    
                                    <div v-if="this.package.package_id === 7"> 
                            
                                        <a href="https://fchat.ai/assistant" style="display: block;
                                        padding: 8px 10px !important;
                                        position: relative;" previewlistener="true">
                                                   <i class="fa-solid fa-robot"></i>
                                                    {{ $i18n.locale === 'vi' ? 'Trợ lý doanh nghiệp' : 'Business assistant' }}
                                                 
                                        </a>
                                    </div>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li>
                                    <a href="/logout" style="display: block;padding: 8px 10px !important;position: relative;">
                                        <i class="nav-icon fas fa-sign-out-alt"></i>
                                        {{$t('logout.logout')}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            
        </ul>
    </nav>
</template>

<style scoped>
.dropdown-menu {
min-width: 12rem !important;
}
</style>
<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'

Vue.use(VTooltip)
export default {
  name: "Mainbartop",
  props: {
        base_url: String,
        aShop: Object,
        listShop: Array,
        shop_role: Object,
        aShopList: Array,
        aUser: Object,
        package: [Object, Array],
        conv_not_view: [Number, String],
        livechat_mode: Boolean
  },
  data() {
    return {
       
      
    };
  },
  computed: {
    flagImage() {
        
      return this.$i18n.locale === 'vi'
        ? 'https://salekit.io/assets/images/flag/vi.svg'
        : 'https://salekit.io/assets/images/flag/en.svg';
    },
  },
  methods: {
    changeLanguage(locale) {
        window.$cookies.set('lang',locale);
        return console.log(window.$cookies.get('lang'));
    //   this.$i18n.locale = locale;
    //   if(locale === 'vi') locale = 'vietnam';
    //   this.$cookies.set('lang', locale)
    },
  }
}
</script>

<style scoped src="../../assets/css/mainbartop.css">

</style>