<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
    <div class="row ">
        <div class="col scrollable-content" ref="scrollContainer">
            <div class="Popup ">
                <div class="d-flex justify-content-center align-items-center bd-highlight ">
                    <div class="d-flex flex-grow-1 bd-highlight nav-main-left">

                        <a href="/login">
                            <img class="header-create-message" src="@/assets/svg/message.svg" alt="message">
                        </a>

                        <div class="dropdown  ml-8px">
                            <button class="btn dropdown-toggle fs-18 fw-600 text-neutral " type="button"
                                data-toggle="dropdown" aria-expanded="false">
                                ChatGPT 3.5
                            </button>
                            <div class="dropdown-menu header-dropdown-custom-no-auth "
                                style="border-radius: 10px !important;max-width: 305px;">

                                <div class=" " style="height:100% !important;width: 305px; padding-left: 8px;">
                                    <div class="fs-14 fw-600 text-neutral">
                                        Đăng nhập để thử các tính năng nâng cao
                                    </div>
                                    <div class="">
                                        <span class="fs-12 fw-400 text-neutral">Hãy đăng nhập để nhận phản hồi thông
                                            minh <br>
                                            hơn, tải tệp lên, phân tích hình ảnh và nhiều tính <br>
                                            năng khác
                                        </span>
                                        <div class="d-flex pt-3">
                                            <div class=" btn-primary-login ">
                                                <a href="/login" class="text-white">{{ $t('login.login')
                                                    }}</a>
                                            </div>
                                            <div class="btn-register-header cursor-pointer ml-8px">
                                                <a class=""
                                                    href="">{{
                                                        $t('register.register') }}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <!-- <span class="fs-20 fw-600 d-chat">Chat</span> -->

                        </div>


                    </div>


                    <div class="d-flex align-items-center nav-main-right">

                        <div class=" btn-primary-login ml-12px">
                            <a href="/login" class="text-white">{{ $t('login.login') }}</a>
                        </div>

                    </div>

                </div>

            </div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
// import utils from '@/utils/index'

// import gptService from "@/services/gptService";
// import config from "@/config";
export default {
  name: "LayoutNoAuth",

  
};
</script>
<style>
.btn-primary-login {
    background: #0D99FF;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    border-radius: 32px;
    height: 42px;
    padding: 12px 12px;
}

.btn-register-header {

    height: Hug (40px)px;
    padding: 10px 12px 10px 12px;
    gap: 5px;
    border-radius: 32px;
    border: 1px solid #DADFEB;

}
</style>
<style scoped src="../../assets/css/popupversion.css"></style>
