import Vue from 'vue'

import enMessage from '../language/en.json'
import viMessage from '../language/vi.json'

import VueCookies from 'vue-cookies'
import VueI18n from 'vue-i18n'
Vue.use(require('vue-cookies'))
Vue.use(VueCookies)

Vue.use(VueI18n);
let messages;
let lang;
window.$cookies.get('lang');
if (window.$cookies.get('lang') && window.$cookies.get('lang') != 'vietnam'){
	lang = 'en';
	messages = {
		en: enMessage,
	}
} 
else{
	lang = 'vi';
	messages = {
		vi: viMessage,
	}
}

export default new VueI18n({
  locale: lang,
  messages,
});