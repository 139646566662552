<template>
<div class="container gpts">
    <div class="gpts_title">
        {{ $t('gpt_assistant.gpt_assistant') }}
    </div>
    <div class="gpts_des">
        {{ $t('gpts_des.gpts_des') }}
    </div>
    <div class="d-flex flex-column mb-3">
        <GPTS_HEADER />
        <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto sticky-nav">
            <GPTS_NAV :activeNav="activeNav" :scrollToSection="scrollToSection" />
        </div>
        <div class="gpts_search col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
            <div class="gpts_content row">

                <!-- Trợ lý yêu thích  -->
                <div id="topic" class="topic  ">
                    <div class="gpts_content_tile fs-16 fw-600 text-neutral d-flex align-items-center">
                        <img src="@/assets/svg/message1.svg" alt="">
                        <span class="ml-8px">{{ this.category.name }}</span>
                        <span class="ml-8px">({{ this.stepCount }})</span>

                    </div>

                    <div class="row mx-md-n5 ">
                        <div v-for="(topic, index) in visibleTags" :key="index" class="col-lg-6 col-md-6  col-12 pt-16 ">
                            <div class="d-flex" @click.prevent="openModal(topic)">
                                <div class="flex-shrink-0 d-flex align-items-center justify-content-center">
                                    <img v-if="topic.avatar_url" :src="topic.avatar_url" width="48" height="48" class="img-fluid rounded-circle" alt="svg">
                                    <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48" class="img-fluid rounded-circle" alt="svg">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <div class="fs-14 fw-600 text-black limit-text-2-line">
                                        {{ topic.name }}
                                    </div>
                                    <div class="fw-12 fw-400 limit-text-2-line" style="color: #575B68;">
                                        {{ topic.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-loadmore col-12" @click="loadMore" v-if="visibleTag < tagStep.length">
                            {{ $t('load_more.load_more') }}
                        </button>
                        <button class="btn btn-loadmore col-12" @click="collapseTags" v-if="visibleTag >= tagStep.length && tagStep.length > step ">
                            {{ $t('collapse.collapse') }}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ModalAssistant v-if="showModal == true" 
        :PropsHeader="tagHeader" 
        :PropsBody="tagStep" 
        :category="category"
        :open="openModal" 
        :close="closeModal"
                    :updateDataProps="updateDataProps"
        />
</div>
</template>

<script>
import GPTS_NAV from '@/pages/gpts/GPTS_NAV.vue'
import utils from '@/utils/index'
import gptService from "@/services/gptService";
import ModalAssistant from '@/components/popupmodal/ModalAssistant'
import GPTS_HEADER from '@/pages/gpts/GPTS_HEADER.vue'

export default {
    name: 'GPTS',
    components: {

        GPTS_NAV,
        ModalAssistant,
        GPTS_HEADER
    },
    data() {
        return {
            activeNav: 'all',
            paddingTopValue: 40,
            sectionIds: ['all', 'topic', 'collection', 'workflow', 'favorite_assistant', 'my_assistant'],
            token: '',
            aUser: {},
            aPackage: {},
            aShop: {},
            stepCount: 0,
            tagStep: [],
            category: {},
            visibleTag: 6,
            initialVisibleTag: 6,
            step: 6,
            page: 2,
            tagHeader:{},
            showModal:false,
            website:{}
        }
    },
    created() {

    },
    beforeMount() {},

    beforeDestroy() {},
    mounted() {
        this.website=JSON.parse(localStorage.getItem('website'))
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');
        this.aUser = JSON.parse(localStorage.getItem('aUser'));
        this.getListPrompt({})

    },

    computed: {
        visibleTags() {
            return this.tagStep.slice(0, this.visibleTag)
        },
    },
    watch: {},
    methods: {
      updateDataProps(object){
        this.category=object.category;
        this.tagHeader=object.PropsHeader;
        this.tagStep=object.PropsBody;
      },
        closeModal() {
            this.showModal = false;
        },
        openModal(tag) {
            this.tagHeader=tag
            this.showModal = true;
        },
        loadMore() {
            this.visibleTag += this.step;
        },
        collapseTags() {
      this.visibleTag = this.step;
    },
        getListPrompt(object_data) {
            object_data.user_id = this.aUser.id;
            object_data.package_id = this.aPackage.package_id;
            object_data.tag = this.$route.params.id;
            object_data.shop_id_owner =this.website.shop_id
            gptService.getListPrompt(object_data, this.token)
                .then((res) => {
                    if (!res.data.error) {
                        this.tagStep = res.data.data
                        this.category = JSON.parse(localStorage.getItem('category'))
                        if (Array.isArray(this.tagStep)) {
                            this.stepCount = this.tagStep.length;
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
        },

        scrollToSection(sectionId) {
            if (this.$route.path !== '/gpts') {
                this.$router.push('/gpts');
                if(sectionId){
                    utils.scrollToSection(sectionId)
                }
            }
        }
    }

}
</script>

<style scoped src="@/assets/css/gpts.css"></style>
