// import http from "../http-common";
import "dotenv";
import axios from "axios";
import config from "../config";
let API_URL=config.API_URL;


class categoryService {
  async getListCategory(token,object) {
    let url = '';  
    let where = '';
    // Lọc theo page
    if (object?.shop_id_owner) {
      where += '&shop_id_owner=' + object.shop_id_owner;
    }
    url = config.API_URL + 'category/list?type=' + object?.type + '&package_id=' + object?.package_id + where;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getDetailCategory(token,object) {
    let url = "";
    url = API_URL + 'category/list/'+object.category_id + '?shop_id_owner='+object.shop_id_owner;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getDetailPromptTag(token,tag_id) {
    let url = "";
    url = API_URL + 'prompt/prompt-tag/detail?tag='+tag_id;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getTopViewPrompt(token,object) {
    let url = "";
    let where = '';
    // Lọc theo page
    if (object?.favourite) {
      where += '?favourite=' + object.favourite;
    }
    if (object?.top) {
      where += '?top=' + object.top;
    }
    if (object?.website_id) {
      where += '?website_id=' + object.website_id;
    }
    url = config.API_URL + 'prompt/top' + where;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getFavouriteList(token,user_id) {
    let url = "";
    url = API_URL + 'prompt/list/favourite?user_id'+user_id;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getDetail(object, token,category_id) {
    // console.log(object);
    let url = '';
    let where = '';
    let is_shop = object.is_shop;
    let keyword = object.keyword;
    let package_id = object.package_id;
    let tag = object.tag;
    let page = object.page;
    let like = object.like;
    let user_id = object.user_id;
    let myassistant = object.myassistant;
    let type_action = object.type_action;
    let type_category = object.type_category;
    // let limit = object.limit;
    if (is_shop) {
      where += '&is_shop=' + is_shop;
    }
    if (tag) {
      where += '&tag=' + tag;
    }
    if (keyword) {
      where += '&keyword=' + keyword;
    }
    if (page) {
      where += '&page=' + page
    }
    if (like) {
      where += '&like=' + like
    }
    if (myassistant) {
      where += '&myassistant=' + myassistant
    }
    if (user_id) {
      where += '&user_id=' + user_id;
    }
    if (package_id) {
      where += '&package_id=' + package_id;
    }
    if (type_action) {
      where += '&type_action=' + type_action;
    }
    if (type_category) {
      where += '&type_category=' + type_category;
    }
    
      url = API_URL + 'category/list/'+category_id+'?limit=' + config.PAGEINATION + where;

    return axios({
      url: url,
      method: 'get',
      headers: {
        "Content-type": "application/json",
        "token": token
      }
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }

  async filterPrompt(token,object) {
    let url = '';  
    url = API_URL + 'prompt/filter?select=' + object?.select + '&package_id=' + object?.package_id;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  
  async getDetailPrompt(token,id_hash) {
    let url = "";
    url = API_URL + `share/prompt?id_hash=${id_hash}`;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        window.location.href = '/dashboard';
    }
    }
  }
}

export default new categoryService();
