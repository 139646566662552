<template>
    <div class="content-container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 col-12">
                <div class="fw-700 fs-24 text-black pt-24px">{{ this.titleChat }}</div>
                <div class="pt-24px fs-14 fw-400 text-neutral">{{ this.timeChat }}</div>
                <div class="border-line"></div>
                <div v-if="data && aShop">
                    <div v-for="(item, index) in data" :key="index"
                        :class="item.type === 1 ? 'message-right' : 'message-left'">
                        <div class="message-avatar">
                            <img v-if="item.type === 0" width="45" height="45" :src="avatar_bot" alt=""
                                class="avatar-img">
                        </div>
                        <div class="message-content" v-html="formatContent(item.content)">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-for="(item, index) in data" :key="index" class="message-no-user">
                        <div class="message-avatar">
                            <img :src="item.type === 0 ? avatar_bot : avatar_user" alt="Avatar" width="24" height="24"
                                class="avatar-img">
                        </div>
                        <div class="message-content">
                            <span class="fs-14 fw-600 text-black">{{ item.type === 0 ? 'ChatGPT' : 'Ẩn danh' }}</span>
                            <div class="" style="padding: 4px;" v-html="formatContent(item.content)">
                            </div>
                        </div>


                    </div>
                </div>
                <div class="footer-share">
                    <div @click.prevent="handleNameClick" class="btn btn-footer-share " v-if="data && aShop">
                        <span v-if="this.prompt">Bắt đầu với {{ this.prompt.name }}</span>
                        <span v-else>Bắt đầu với Fchat.ai</span>
                    </div>
                    <a href="/login" class="btn btn-footer-share" v-else>Đăng ký để trò chuyện</a>
                    <div class="ro fs-12 fw-400 text-neutral mb-16px" v-if="data && !aShop">Đăng ký hoặc đăng nhập để trò chuyện</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/vi';
import config from "@/config";
import utils from '@/utils/index'

export default {
    data() {
        return {
            avatar_bot: config.BASE_URL + 'assets/svg/icon-chat.png',
            avatar_user: config.BASE_URL + 'assets/svg/user-default.png',
            data: null,
            titleChat: '',
            timeChat: '',
            aShop: '',
            thuy: '',
            prompt:0
        };
    },
    mounted() {
        this.aShop = JSON.parse(localStorage.getItem('aShop')) || '';
        let conversation_id=this.$route.params.conversation_id;
        this.fetchData(conversation_id);
    },
    methods: {
        async fetchData(conversation_id) {
            try {
                const response = await fetch(`https://fchat.ai/api/v1/getMessage?conversation_id=${conversation_id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                this.data = result.data;
                if (result.conversation && result.conversation.prompt) {
                    this.prompt=result.conversation.prompt
                }
                this.titleChat = utils.limitText50(result.conversation.name) 
                const createdAt = result.conversation.created_at;
                moment.locale('vi');
                this.timeChat = moment(createdAt).format('D [tháng] M, YYYY');
                document.title = `${this.titleChat}`;

            } catch (error) {
                console.error('Lỗi:', error);
            }
        },
        async handleNameClick() {
            await localStorage.setItem('PropsHeader',JSON.stringify(this.prompt));
            this.$router.push({path:'/',query:{id:0}});
        },
        formatContent(content) {
            return content.replace(/\n/g, '<br>');
        }
    }
};
</script>

<style scoped>
.message-left:last-of-type .message-content,
.message-right:last-of-type .message-content {
    margin-bottom: 100px;
}

.content-container {
    max-height: 100vh;
    overflow-y: auto;
    padding: 1rem;
    /* background-color: #f9f9f9; */
}

.message-no-user {
    word-wrap: break-word;
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    display: flex;
    color: #000000;

    .message-content {
        padding-left: 8px;
    }
}

.message-left,
.message-right {
    word-wrap: break-word;
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    display: flex;
    color: #000000;

}

.message-left {
    text-align: left;
    justify-content: flex-start;
}

.message-left .message-content {

    max-width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.message-right {
    /* text-align: left; */
    justify-content: flex-end;
}

.message-right .message-content {
    background-color: #E2F8FF;
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
}

.footer-share {
    position: fixed;
    bottom: 0;
    right: 0;
    text-align: center;
    width: 100%;
    background: #FFFFFF;

}

.btn-footer-share {
    background: #0D99FF;
    color: white;
    border-radius: 32px;
    margin: 16px 0px 10px 0px;
    padding: 8px 20px;
    text-align: center;
}

.border-line {
    border-bottom: 1px solid #DADFEB;
    margin: 24px 0px;
}
</style>
