<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
  <div >
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />

    <div class="container-fluid">
      <!-- Menu bar -->
      <Mainbartop :aUser="this.aUser" :aShop="this.aShop" :shop_role="this.shop_role" :listShop="this.listShop" :package="this.package" />
      <div class="container-form-group">
        <div class="row">
          
          <div class="left-panel" style="position: fixed;height: 100%; width: 24.99%; left: 0;  ">
            <form class = "form-group-container" style = "overflow-y: auto;height: 100%; width: 95%; margin-left: 15px;">
              <div style="margin-top: 20px;">
                <!-- <h5>Tên trợ lý</h5> -->
                <div style="position: absolute;display: inline; right: 10px">
                 <a  @click="autoclick">
                      <!-- <h5 v-if="language_compoment === 'en'">Fix</h5>
                      <h5 v-else>Sửa</h5> -->
                      <!-- <h5 style="font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)"> sửa </h5> -->
                      <i class="fas fa-pen"></i> 
                  </a>
                </div>
                <div>
                 
                  <!-- <h5 style="display: inline;" v-if="language_compoment === 'vietnam'">Tên trợ lý: </h5>
                  <h5 style="display: inline;" v-else-if="language_compoment === 'en'">Name assistant: </h5>
                  <h5 style="display: inline;" v-else>Tên trợ lý </h5> -->
                  <i class="fas fa-robot"></i> 
                   <h5 style="margin-left: 3px; bottom: 0px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif);font-size: 16px">      {{ Nameassitant }} </h5>
                  <!-- <div style="margin-left: 20px; margin-right: 20px;"> <h5>{{ Nameassitant }} </h5></div> -->
                </div>
                
                
              </div>
             
              <!-- <input type="text" class="custom-input" style="height: 30px; width: 90%;" id="Nameassitant" v-model="Nameassitant" readonly  /> -->
               <div>
                  <div v-if="this.instructions.length < 60" >
                    <div>
                      <br>
                      <!-- <h5 style="padding-top: 18px;">Hướng dẫn:</h5> -->
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-if="language_compoment === 'vietnam'">Mô tả: </h5>
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else-if="language_compoment === 'en'">Description: </h5>
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else>Mô tả: </h5>
                      <p style="display: inline;font-weight: 400; font-size: 14px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" >{{ instructions}}</p>
                     
                    </div>
                    <br>
                    <!-- <div style="margin-left: 20px; margin-right: 20px;">
                      <p style="font-weight: 400; font-size: 14px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" >{{ instructions}}</p>
                    </div> -->
                </div>
                <div v-else>
                  <div>
                      <br>
                      
                      <!-- <h5 style="padding-top: 18px;">Hướng dẫn:</h5> -->
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-if="language_compoment === 'vietnam'">Mô tả: </h5>
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else-if="language_compoment === 'en'">Description: </h5>
                      <h5 style="display: inline;padding-top: 18px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else>Mô tả: </h5>
                    </div>
                    <div style="margin-left: 20px; margin-right: 20px;">
                      <p style="font-weight: 400; font-size: 14px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" >{{ instructions}}</p>
                    </div>
                </div>
                <!-- <textarea id="input-text" class="custom-textarea"  style="width: 90%; height: 100px; max-height: 100px; min-height: 100px; border: none;" v-model="instructions" readonly></textarea> -->
                
               <div v-if="language_compoment === 'en'">
                <h5 style="display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)">Model:</h5>
                <h5 style="font-weight: 400;display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)">{{ selectedModel }}</h5>
                <!-- <div style="margin-left: 20px; margin-right: 20px;">
                 
                </div> -->
               </div>
               <div v-else>
                <h5 style="display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)">Phiên bản:  </h5>
                <p style="font-weight: 400;font-size: 13;display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)">{{ selectedModel }}</p>
                <!-- <div style="margin-left: 20px; margin-right: 20px;"> 
                 
                </div> -->
               </div>
              </div>
              <div>
                <br>
                <!-- <h4>Danh sách file đã tải lên:</h4> -->
                <h5 style="font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-if="language_compoment === 'vietnam'">Danh sách file: </h5>
                <h5 style="font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else-if="language_compoment === 'en'">List file: </h5>
                <h5 style="font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-else>Danh sách file: </h5>
                <br>
              </div>
              <div>
                <div style="margin-left: 15px; margin-right: 20px;">
                  <div v-for="(file, index) in this.listfiledata" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                      <!-- <div style=" display: inline;margin-bottom: 5px; width: 5px; height: 5px; background-color: rgb(92, 155, 250); border-radius: 50%; margin-right: 5px;"></div> -->
                       <i style=" display: inline;bottom: 0px;" class="fas fa-file"></i> 
                      <h5 style="display: inline;margin-bottom: 0px;margin-left: 5px;font-weight: 400; font-size: 14px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)">{{ file }}</h5>
                  </div>
              </div>
              </div>
              
            </form>
          </div>
          <!-- <div class="separator" style="position: fixed;left: 25%; margin-top: 60px; margin-bottom: 0; "></div> -->
          <div class="right-panel" style="position: fixed; border-left: 1px solid #C8C8C8;height: 100%; width: 74.96%; right: 0;">
            <div class="container-form-group">
            <!-- Nội dung cột 2 -->
            <div  style="margin-right: 30px; margin-top: 10px ; margin-left: 30px; width: 95%;height: 100%;">
            <div style=" margin-top: 20px; left: 25%;" >
              <!-- <h5 style=" display: inline; ">ID Chủ đề: </h5> -->
              <h5 style="display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif)" v-if="language_compoment === 'en'">ID threads: </h5>
              <h5 style="display: inline;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif);font-size: 16px; " v-else>ID Chủ đề: </h5>
          
            <h5 style=" display: inline; color: rgb(102, 102, 96);">{{ id_threads }}</h5>
            <button class="custom-button " style="position: absolute ;display: inline; right: 0; padding-bottom: 10px; "  name="clearthreadconv" @click="clearthreadconv" >
                <span class="btn-node">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="currentColor" viewBox="0 0 24 24">
                        <path fill="#000" fill-rule="evenodd" d="M20.707 4.707a1 1 0 0 0-1.414-1.414L14.5 8.086l-.633-.634a3 3 0 0 0-3.786-.375l-7.636 5.09a1 1 0 0 0-.152 1.54l8 8a1 1 0 0 0 1.54-.152l5.09-7.636a3 3 0 0 0-.375-3.786l-.634-.633 4.793-4.793ZM11.19 8.742a1 1 0 0 1 1.262.124l2.681 2.682a1 1 0 0 1 .126 1.261l-.414.621-4.275-4.275.62-.413Zm-2.317 1.545L4.57 13.155 6 14.585l1.293-1.292a1 1 0 0 1 1.414 1.414L7.414 16l3.43 3.43 2.87-4.303-4.841-4.84Z" clip-rule="evenodd"></path>  
                    </svg>
                    </span>
                    <div v-if="language_compoment === 'en'" style="display: inline; font-weight: 500">Clear </div>
                    <div v-else style="display: inline;font-weight: 500">Xóa</div>
                    
                </button>
            </div>
            <div id="chat-wrapper"  style="  position: fixed; bottom: 30%; width: 67%; padding: 10px; top:120px; overflow-y: auto; ">
          
            
                <div id="chat-messages"  ref="scrollContainerwrapper"  >
                <div v-for="(message, index) in messages" :key="index" class="message">
                    <div v-if="message.INSTRUSTION" class="INSTRUSTION-message">
                    <div> <h2>INSTRUSTION</h2></div>
                    <h3>{{ message.text }}</h3>
                    </div>

                    <div v-if="message.fromAssistant" class="assistant-message">
                      <div data-v-6a9182bd="" style="display: inline; top: 0px; padding-left: 4px;" class="avatar-user-chat">
                        <img data-v-6a9182bd="" src="https://fchat.ai/assets/images/EMO-fchat-03.png" alt="" class="avatar">
                      </div>
                      <pre>  {{ message.text }}</pre>
                    </div>
                   
                    <div v-else class="user-message" style=" display: flex;margin-bottom: 0px;">
                          <div class="colum" >
                              <!--  avatar  -->
                             <h5 style=" margin-left: 10px;font-family: SÃ¶hne,helvetica,sans-serif;font-family:var(--sans-serif);font-size: 16px;"> User:</h5>
                          </div>
                          <div class="colum">
                            <pre style="top: 0px; padding-left: 27px; padding-top: 0;margin: 0;"> {{ message.text }}</pre>
                          </div>
                          
                      
                  </div>
                    
                </div>
                </div>
            </div>
            
            <div id="message-assistant12" style="position: fixed;  width: 57%; right: 10px;">
              <div class="container"  style="position: fixed;bottom: 30px; max-width: 70%;">
                <div class="from-input" style="border: none ;  width: 100%;">
                
                  <div class="send-mess" style=" background-color: white; width: 60%;  min-block-size: 100px;text-align: right ;border: none;">
                      <textarea
                        class="custom-textarea"
                        :placeholder=" $t('send_message.send_message')"
                        rows="1"
                        
                        style="background-color: white; width: 100%; max-block-size: 200px; min-block-size: 100px; "
                        v-model="MessAssistant"
                      
                      ></textarea>
                      <div  class="icon-inside-textarea" @click="handleIconClick">
                        <svg   viewBox="0 0 35 40" style=" background-color: white;width:50px;height:50px" >
                          <g fill="none" fill-rule="evenodd">
                            <g>
                              <path d="M31.1059281,19.4468693 L10.3449666,29.8224462 C8.94594087,30.5217547 7.49043432,29.0215929 8.17420251,27.6529892 C8.17420251,27.6529892 10.7473302,22.456697 11.4550902,21.0955966 C12.1628503,19.7344961 12.9730756,19.4988922 20.4970248,18.5264632 C20.7754304,18.4904474 21.0033531,18.2803547 21.0033531,17.9997309 C21.0033531,17.7196073 20.7754304,17.5095146 20.4970248,17.4734988 C12.9730756,16.5010698 12.1628503,16.2654659 11.4550902,14.9043654 C10.7473302,13.5437652 8.17420251,8.34697281 8.17420251,8.34697281 C7.49043432,6.9788693 8.94594087,5.47820732 10.3449666,6.1775158 L31.1059281,16.553593 C32.298024,17.1488555 32.298024,18.8511065 31.1059281,19.4468693" fill="#0099ff">
                              </path>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div></div>
                      
                    </div>
                

                </div>
              </div>  
            </div>
            </div>
            <div>
              <!-- ... -->
            </div>
          
          </div>
        </div>
        </div>
      </div>
   
    </div>
  </div>
</template>
<style scoped>
.column {
    flex: 1;
    /* Cấu hình độ co giãn để cột có thể mở rộng */
    padding: 10px;
    /* Thêm padding nếu cần */
}
pre {
            white-space: pre-wrap;
            font-size: 16px;
            font-family:'Roboto', sans-serif ;
            color: #333;
            padding: 10px;
            /* background-color: #f0f0f0;
            border: 1px solid #ddd; */
            border-radius: 5px;
            margin: 5px;
            font-weight: 500;
        }

.assistant-message {
  display: flex;
  align-items: center; /* Căn giữa theo chiều dọc */
}

.avatar-user-chat {
  display: inline;
  top: 0;
  margin-right: 10px; /* Khoảng cách giữa avatar và nội dung */
}

.avatar {
  width: 40px; /* Kích thước avatar */
  height: 40px;
  border-radius: 50%; /* Bo tròn avatar */
}

h5 {
  display: inline;
  bottom: 0;
  font-family:var(--sans-serif);
  font-family:SÃ¶hne,helvetica,sans-serif; 
  font-size: 14px; /* Kích thước font */
  font-weight: 500; 
  
  /* Độ đậm của font */
  /* opacity: 1.7; */
  color: #333; /* Màu chữ */
}
h4 {
  display: inline;
  bottom: 0;
  font-family: 'Roboto', sans-serif; /* Thay 'Your-Preferred-Font' bằng tên font bạn muốn sử dụng */
  font-size: 18px; /* Kích thước font */
  font-weight: bold; /* Độ đậm của font */
  color: #333; /* Màu chữ */
}
h3 {
  display: inline;
  bottom: 0;
  font-family: 'Roboto', sans-serif; /* Thay 'Your-Preferred-Font' bằng tên font bạn muốn sử dụng */
  font-size: 20px; /* Kích thước font */
  font-weight: bold; /* Độ đậm của font */
  color: #333; /* Màu chữ */
}
.send-mess {
  display: flex;
  align-items: center;
}
.send-mess:focus {
  display: flex;
  align-items: center;
  border: none;
}
.from-input:focus {
  display: flex;
  align-items: center;
  border: none;
  
}
textarea:focus {
  /* border: none; Hiển thị border khi textarea được focus */
  border: 1px solid rgb(165, 167, 167);

}

textarea {
  height: 100px;
  width: 100%;
  padding: 10px;
  margin-right: 10px;
  /* border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; */
  background-color: #f0f0f0;
  border: 1px solid rgb(165, 167, 167);
  border-radius: 10px;
}

.icon-inside-textarea {
  display: flex;
  align-items: center;
  margin-left: -65px;  /* Tùy chỉnh khoảng cách giữa textarea và biểu tượng */
}

svg {
  width: 20px;
  height: 20px;
  fill: #f3f5f3;
}
.custom-file-input {
    display: none; /* Ẩn input thực sự */
    color: #4caf50;
  }
  .custom-file-label {
    background-color:  #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .custom-input,
  .custom-select {
    font-size: 18px; 
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .center-button {
    text-align: center;
  }

  .custom-button {
    /* Thêm các quy tắc CSS cho nút Save và Revert Changes */
    background-color: rgb(92, 155, 250);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 18px; 
  
  }

  .custom-button:hover {
    background-color: #0b3fec;
  }
  .fa-save {
    margin-right: 5px;
  }
.loading {
  cursor: not-allowed;
  opacity: 0.7;
}
.custom-button,
  .btn-action-wrapper .btn-action-primary {
    /* Thêm các quy tắc CSS chung cho cả hai nút ở đây */
    padding: 10px;
    font-size: 16px;
    /* ... */
  }

  /* Quy tắc CSS cụ thể cho nút trên */
 

  /* Quy tắc CSS cụ thể cho nút dưới */
  .btn-action-wrapper .btn-action-primary {
    background-color: #007bff;
    color: white;
    /* ... */
  }
.INSTRUSTION-message,
  .assistant-message,
  .user-message {
    margin-bottom: 10px; /* Điều chỉnh giảm độ dãn dòng tại đây */
  }
.custom-button i {
  margin-right: 5px; /* Khoảng cách giữa biểu tượng và văn bản */
}
.container {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
}
.custom-button {
  /* Tùy chỉnh CSS cho button */
  margin: 0 10px; /* Khoảng cách giữa các button */
}
.button-container {
  position: fixed; /* Đặt vị trí cố định */
  bottom: 0; /* Đặt ở dưới cùng */
  width: 100%; /* Chiều rộng của button container bằng 100% */
  background-color: #817f7f; /* Màu nền của button container */
  display: flex; /* Hiển thị các button theo chiều ngang */
  justify-content: center; /* Căn giữa các button */
  padding: 10px 0; /* Padding cho button container */
}
.separator {
  width: 5px; /* Chiều rộng của đường line */
  background-color:var(--gray-200);
  margin: 20px 0; /* Khoảng cách giữa đường line và các form */
  /* height: 50%; */
  top: 0px;
  bottom: 0px;
}

.separator:hover {
  background-color: #000; /* Màu của đường line khi hover qua */
}
.custom-select {
  width: 200px; /* Đặt chiều rộng của combo box */
  padding: 8px; /* Đặt khoảng cách giữa nội dung và viền */
  border: 1px solid #ccc; /* Đặt viền */
  border-radius: 5px; /* Đặt góc cong của viền */
  background-color: #fff; /* Đặt màu nền */
  color: #333; /* Đặt màu chữ */
  font-size: 18px; 
}

.custom-select:focus {
  outline: none; /* Loại bỏ viền khi combo box được focus */
  border-color: #007bff; /* Đổi màu viền khi combo box được focus */
}
.custom-textarea {
  width: 100% ;
  /* border: 1px solid #ccc; */
  border: 1px solid rgb(165, 167, 167);
  border-radius: 5px;
  padding: 8px;
  font-size: 18px; 
 
}

.custom-textarea:focus {
  outline: none;
  border-color:none;
  
}

.split-screen {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.custom-input {
  border: 1px solid #ccc; /* Màu và kiểu viền */
  border-radius: 5px; /* Đường cong của viền */
  padding: 8px; /* Khoảng cách từ nội dung đến viền */
  /* width: 200px; Chiều rộng của input */
  width: 100%;
  font-size: 18px; 
}
.custom-input1 {
  /* border: 1px solid #ccc; Màu và kiểu viền */
  /* border-radius: 5px; /* Đường cong của viền */
  /* padding: 8px; Khoảng cách từ nội dung đến viền */
  /* width: 200px; Chiều rộng của input */
  background-color: #f0f0f0;
  width: 70%;
  height: 100px;
  font-size: 18px; 
}
.no-border {
  border: none;
  background-color: #f0f0f0;
  width: 70%;
  height: 100px; /* Loại bỏ viền của trường nhập liệu */
  /* Các thuộc tính CSS khác bạn muốn áp dụng cho trường nhập liệu */
}

/* Các kiểu khác cho input khi focus, hover, ... */
.custom-input:focus {
  outline: none; /* Loại bỏ viền khi input được focus */
  border-color: #007bff; /* Thay đổi màu viền khi input được focus */
}

/* Nếu bạn muốn tùy chỉnh placeholder */
.custom-input:placeholder1 {
  color: rgb(36, 36, 31); /* Màu của placeholder */
}
.form-container {
  height: 85vh;
  /* width: 100%; */
  /* border: 1px solid #ccc; Màu và kiểu viền */
  /* border-radius: 5px; Đường cong của viền */
  padding: 20px; /* Khoảng cách từ nội dung đến viền */
}
.left-panel {
  flex: 0.2;
}
.right-panel{
  flex: 0.8;
}

.left-panel {
  background-color: #f0f0f0;
}

.right-panel {
  background-color: #f0f0f0;
}
</style>
<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
Vue.use(Notifications);
import axios from 'axios';

import Cookies from 'js-cookie';
import VueCookies from 'vue-cookies';
import i18ns from '../index.js';
import languageJson from '../lang/language.json';
import Mainbartop from "@/components/mainbar/Mainbartop.vue";
import SidebarRight from "@/components/sidebar/SidebarRight.vue";
import Conversations from "@/components/conversation/Conversations.vue";
import ContentMain from "@/components/content/ContentMain.vue";
import PopupAssistant from "@/components/popupmodal/PopupAssistant.vue";
import PopupChooseVersion from '@/components/popupmodal/PopupChooseVersion.vue';
import PopupUpgradePackage from '@/components/popupmodal/UpgradePackage.vue';

import config from "@/config";
import gptService from "@/services/gptService";

export default {
  name: "Myassistant",
  props: {
    // assistant_token_props: String,
    // language_compoment_props: String,
    // token_props: String
    
  },
  components: {
    Mainbartop,
    
  },
  data() {
    return {
      messageuser:[],
      language_compoment:'',
      id_prompt_busines: '',
      instructions:'',
      Nameassitant:'',
      assistant_token:'',
      assistant: '',
      tokenAssistant: '',
      listfiledata: [],
      id_threads:'',
      instructionsAssistant: '',
      selectedModel: null,
      infoassistant:[],
      hasResponse: true,
      newMessage:'',
      message_assistant12:'',
      MessAssistant: '',
      responseMessage: '',
      inputText: '', 
      initialInputText: '', 
      base_url: config.BASE_URL,
      languageData: languageJson,
      avatar_bot: config.BASE_URL + 'assets/images/EMO-fchat-03.png',
      version_gpt: "3.5",
      conversations: [],
      listPrompt: [],
      listTags: [],
      aPrompt: {},
      prompt_options: [],
      listShop: [],
      messages: [],
      aNewMessageText: "",
      aShop: {},
      aUser: {},
      package: {},
      shop_role: {},
      conv: {},
      conv_id: "",
      shop_id: "",
      token: "",
      page_conv: 1,
     
    };
  },
 
  created() {
    
    // this.getinfoassistant();
    // this.getlistfiledata();
    gptService.getLogin().then((res) => {
      if (!res.error) {
        //console.log(res);
        const data = res.data.data;
        this.shop_id = data.shop.id;
        this.token = data.shop.api_token;
        this.listShop = data.listShop;
        this.shop_role = data.role_user;
        this.aShop = data.shop;
        this.aUser = data.user;
        this.package = data.package || {};
        // if(!data.package || (data.package && data.package.package_id !== '7')){
        //   console.log('data', this.package.package_id);
        //   setTimeout(() => {
        //     window.location.href = 'https://fchat.ai/chatgpt ';
        //   }, 1000);
        // }
        
        this.assistant_token = data.shop.assistant_token; 
        const langCookie = VueCookies.get('lang');
        this.language_compoment = langCookie;
         this.getidassistant();
         if((!this.id_threads ||this.id_threads ===''  || this.id_threads ==='null')  && localStorage.getItem('id_threads') && localStorage.getItem('id_threads') !== ''&& localStorage.getItem('id_threads') !== null ){
          //console.log('ok',localStorage.getItem('id_threads'));
           this.id_threads = localStorage.getItem('id_threads');
          console.log('ok',this.id_threads);
          this.getmessAssistant();
         }
      }
    });
    // Hàm gọi từ component cha
  //  console.log('this.$root.$refs.compname_component',this.$root.$refs.compname_component);
    // this.$root.$refs.compname_component = this;
  },
  // mouted:{
  //   getmessAssistant(){
  //     if(!this.id_threads ||this.id_threads ==='' || this.id_threads ==='null'){
  //         this.id_threads = localStorage.getItem('id_threads');
  //         console.log('ok mouted',this.id_threads);
  //        }
  //   }
     
  // },
 
  methods: {
            // Hàm để lấy tất cả các cookie cùng tên miền
     getmessAssistant(){
      let openai_key ='';
      if(this.assistant_token){
        openai_key = this.assistant_token;
      }
      else{
        openai_key = "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";
      }
      const assistant_token = "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";
      axios.get('https://api.openai.com/v1/threads/'+this.id_threads+'/messages',
      {
            headers: {
              "OpenAI-Beta": "assistants=v1",
              "Authorization": 'Bearer '+openai_key+  "",
              "Content-type": "application/json",
           }
          } 
      )
      .then(response =>{
        //dao nguoc chuoi
         const messofthread = response.data.data.reverse();
        //push tin nhan lan luot vao mang
         for (let i = 0; i < messofthread.length; i++) {
          //neu la in nhan cua user
          if(messofthread[i].assistant_id === '' || messofthread[i].assistant_id === null || messofthread[i].assistant_id ==='null' ){
            //console.log("mess user",messofthread[i].content[0].text.value);
            //xu ly push vao mang message
            this.messages.push({
                text: messofthread[i].content[0].text.value,
                fromAssistant: false // Giả sử tin nhắn từ người dùng
              });
          }else{
            //neu la tin nhan tu assistant
           // console.log("mess user",messofthread[i].content[0].text.value);
            this.messages.push({
                text: messofthread[i].content[0].text.value,
                fromAssistant: true // Giả sử tin nhắn từ người dùng
              });
          }
          
         }
      })


    },
    autoclick(){
      window.location.href = 'https://fchat.ai/assistant ';
    },
    getidprompt_business_edit(){
      this.listfiledata = '';
      const token_shop_api = this.token;
      const id = this.assistant;
      axios.get(config.API_URL + 'prompt/business',
          {
            headers: {
            "Content-type": "application/json",
            // "token": token_shop_api
             "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoyOTEyLCJpYXQiOjE3MDQ0MzY5ODB9.vv48hn1h2aZRy6y9CzNWYw_8U8PwY69OXzlMG83fZLM"
          }
          }
          )
          .then(response =>{
            
            const prompt_busines = response.data.data;
           // console.log(prompt_busines);
            for (let i = 0; i < prompt_busines.length; i++) {
              if(prompt_busines[i].assistant_id === this.assistant){
                this.id_prompt_busines = prompt_busines[i];
                //console.log(this.id_prompt_busines);
              }
            }
            if(this.id_prompt_busines.file){
              const data1 = this.id_prompt_busines.file;
            let file_name_1 =[];
            const adata =  JSON.parse(data1);
            for (let index = 0; index < adata.length; index++) {
            file_name_1[index] = adata[index].file_name;
            }
            this.listfiledata = file_name_1;
            }
            
          })
          .catch(error => { console.log(error)});
    },
    getinfoassistant(){
      //var openai_key = req.body.openai_key;
      var openai_key;
      if(this.assistant_token){
        openai_key = this.assistant_token;
      }
      else{
        openai_key = "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";
      }
       
      //console.log('openai_key',openai_key);
      axios.get('https://api.openai.com/v1/assistants/'+ this.assistant,{
              headers: {
                  "Content-type": "application/json",
                  "Authorization": "Bearer "+ openai_key,
                  'OpenAI-Beta': 'assistants=v1',
                }
      })
      .then(response => {
        //console.log(response.data);
        const infoassistant = response.data;
        this.instructions = infoassistant.instructions;
        this.Nameassitant = infoassistant.name;
        this.selectedModel = infoassistant.model;
        //this.listfiledata = infoassistant.file_ids;
       // this.id_prompt_busines = infoassistant
      })
      .catch(error => {
        console.log(error);
      })
    },
    handleIconClick() {
            if (this.hasResponse && this.hasResponse == true) {
              
                this.sendMessAssistant();
            }
    },
    
    clearthreadconv(){
      this.messages = [];
      this.id_threads = '';
      localStorage.removeItem('id_threads');
    
    },
    getidassistant(){
      const currentPath = window.location.pathname;
      const pathArray = currentPath.split('/');
    const lastSegment = pathArray[pathArray.length - 1];
    this.assistant = lastSegment;
    this.getinfoassistant();
    this.getidprompt_business_edit();
    },

    sendMessAssistant() {
      this.responseMessage = '';
      this.hasResponse = false;
    
      var messageassistant = this.MessAssistant;
      
     
      let assistant_token = this.assistant_token;
      let assistant_id = this.assistant;
      this.MessAssistant ='';
      let thread_id ='';
      if(this.id_threads){
        thread_id = this.id_threads;
      }
      
      const token_shop_api = this.token ;
     
     
      if(assistant_id && assistant_token){
       
        this.messages.push({
            text: messageassistant,
            fromAssistant: false // Giả sử tin nhắn từ người dùng
          });
        localStorage.setItem('messageuser',messageassistant);
        
        axios.post(config.API_URL + 'prompt/use-question-assistant',{messageassistant,assistant_id,assistant_token,thread_id },
          {
            headers: {
            "Content-type": "application/json",
            "token": token_shop_api
            // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoyOTEyLCJpYXQiOjE3MDQ0MzY5ODB9.vv48hn1h2aZRy6y9CzNWYw_8U8PwY69OXzlMG83fZLM"
          
          }
          }
          )
          .then(response => {
            if(response.data.error ){
            console.log( response.data.error);
            if(!this.id_threads  ||this.id_threads ==='' ){
              localStorage.setItem('id_threads',response.data.id_threads);
            }
            this.id_threads = response.data.id_threads;
            this.responseMessage = response.data.error;
            this.messages.push({
               text: this.responseMessage,
               fromAssistant: true 
             });
             this.hasResponse = true;
            }
          // if(response.data && response.data.message)
            else{
              this.responseMessage = response.data.messages[0][0].text.value;
              this.id_threads = response.data.id_threads;
              localStorage.setItem('savedMessage', this.message);
              this.messages.push({
                  text: this.responseMessage,
                  fromAssistant: true 
                });
                this.hasResponse = true;
            }
          })
          .catch(error => {
            console.log("error :",error);
            this.hasResponse = true;
          })
      }
      // test local
      else
      {
         assistant_token = "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";
         assistant_id = "asst_8294fKZREDTPRflVZYv8BhZu";
         this.messages.push({
          text: messageassistant,
          fromAssistant: false // Giả sử tin nhắn từ người dùng
        });
        let thread_id ='';
        if(this.id_threads){
          thread_id = this.id_threads;
        }
        axios.post(config.API_URL + 'prompt/use-question-assistant',{messageassistant,assistant_id,assistant_token,thread_id },
          {
            headers: {
            "Content-type": "application/json",
            "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoyOTEyLCJpYXQiOjE3MDQ0MzY5ODB9.vv48hn1h2aZRy6y9CzNWYw_8U8PwY69OXzlMG83fZLM"
          }
          }
        )
        .then(response => {
          if(response.data.error ){
            console.log( response.data.error);
            this.responseMessage = response.data.error;
            if(!thread_id || (thread_id === ''&& thread_id === 'null')){
              localStorage.setItem('id_threads',response.data.id_threads);
              console.log('luu dong827', localStorage.getItem('id_threads'));
            }
           this.messages.push({
               text: this.responseMessage,
               fromAssistant: true 
             });
             this.hasResponse = true;
          }
          // if(response.data && response.data.message)
          else{
             this.responseMessage = response.data.messages[0][0].text.value;
            this.id_threads = response.data.id_threads;
            if(!thread_id || (thread_id === ''&& thread_id === 'null')){
              localStorage.setItem('id_threads',response.data.id_threads);
              console.log('luu dong842', localStorage.getItem('id_threads'));
            }
            this.messages.push({
                text: this.responseMessage,
                fromAssistant: true 
              });
              this.hasResponse = true;
              //   setTimeout(() => {
              //   // window.scrollTo(0, scrollHeight);
              //   const scrollContainer = this.$refs.scrollContainerwrapper;
              //   if (scrollContainer) {
              //   scrollContainer.scrollTop = scrollContainer.scrollHeight;
              //    // console.log('xong 1s');
              //   }
              //   else{
              // //    console.log('rong'); }
              //   }, 3000);
          }
        
        })
        .catch(error => {
          console.log("error :",error);
          console.log('khong co id tro ly');
        this.hasResponse = true;
      })
      }
      return false;
    },
   
   
    
    scrollToEnd(i = ""){
      let container_message;
      if (i != "") {
        container_message = this.$el.querySelector("#list-mess" + i);
      } else {
        container_message = this.$el.querySelector("#list-mess");
      }
      if (container_message) {
        container_message.scrollTop = container_message.scrollHeight;
      }
    },
    handleScrollMessage(event) {
      if (event.target.scrollTop <= 0 && !this.view_conv) {
        this.loadMessages(this.conv.id, this.page_mess +1);
      }
    },
    handleScrollConv(event) {
      const target = event.target;
      const scrollBottom = target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollBottom <= 0.6) {
          this.getConversations({page_conv: this.page_conv + 1});
      }
    },
    loadMorelMessage() {
      this.loadMessages(this.conv.id, this.page_mess);
    },
  },
};
</script>
