<template>
  <div id="modal-upgrade-package" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p id="popup-noti-package">{{ $t('update_to_package.non_update') }}</p>
          <a href="/#price_section" class="btn btn-primary">
            <i class="ion-ios-cloud-upload-outline"></i>{{ $t('upgrate_now.upgrate_now') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "UpgradePackage",
    data() {
        return { 
           
        };
  },
}
</script>

<style scoped>

</style>