import Vue from "vue";
import Router from "vue-router";

import PopupAssistant from "@/components/popupmodal/PopupAssistant.vue";
import AssistantTest from "@/components/assistant.vue";
import ChatGPT from "@/pages/chatgpt/chatgpt.vue";
import GPTS from "@/pages/gpts/gpts.vue";
import TopicStep from "@/pages/gpts/topic/TopicStep.vue";
import CollectionStep from "@/pages/gpts/collection/CollectionStep.vue";
import WorkflowStep from "@/pages/gpts/workflow/WorkflowStep.vue";
import i18ns from "@/index.js";
import store from "@/store/index.js";
import SharePrompt from "@/pages/share/SharePrompt.vue";
import ShareChat from "@/pages/share/ShareChat.vue";
import Main from "@/components/layout/Main.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/share/chat/:conversation_id",
      name: "ShareChat",
      component: ShareChat,
    },
    {
      path: "/share/:id_hash",
      name: "SharePrompt",
      component: SharePrompt,
    },
    {
      path: "/assistant",
      name: "Assistant",
      component: PopupAssistant,
    },
    {
      path: "/assistant",
      name: "Myassistant",
      component: AssistantTest,
    },
    {
      path: '/',
      component: Main,
      children: [
        {
          path: "/",
          name: "ChatGPT",
          component: ChatGPT,
          meta: {
            title: "ChatGPT",
          },
        },
        {
          path: "gpts",
          name: "GPTS",
          component: GPTS,
          meta: {
            title: i18ns.t("explore_gpts.explore_gpts"),
          },
        },
        {
          path: "/gpts/topic/:id",
          name: "topic_step",
          component: TopicStep,
          meta: {
            title: i18ns.t("topic.topic"),
          },
        },
        {
          path: "/gpts/collection/:id",
          name: "collection_step",
          component: CollectionStep,
          meta: {
            title: i18ns.t("personal_assistant.personal_assistant"),
          },
        },
        {
          path: "/gpts/workflow/:id",
          name: "workflow_step",
          component: WorkflowStep,
          meta: {
            title: "Workflow",
          },
        },
      ],
    },
    
    
    
  ],
});
router.beforeEach((to, from, next) => {
  const title = to.meta.title ?? "Fchat.ai";
  if (title) {
    document.title = title;
  }
  if (store.state.isMenuSidebar && window.innerWidth <= 767) {
    store.commit("setIsMenuSidebar", false);
  }
  next();
});
export default router;
