<script>
  import jQuery from 'jquery';
  let $ = jQuery;
  console.log($); 
</script>
<template>
    <div id="modal-gpt-image" class="modal-fade-gpt border-template-img  modal-chat-ai " >
        <div class="modal-dialog modal-send-block" >
            <div class="modal-content border-template-img"  >
                <div class="modal-header " style="border-radius: 8px;width: 100%;">
                    <div  class="flex">
                        <div class=" btn-modalgpt  " style="width: 5px;" @click="srcoll($event,0)">
                                    <svg style="margin-left: -8px;margin-top: 5px;" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="M8.055 12.004q0-.203.078-.375t.234-.328l6.086-5.953a.86.86 0 0 1 .64-.266q.25 0 .462.125.21.118.336.328a.925.925 0 0 1-.164 1.117l-5.485 5.352 5.485 5.352a.92.92 0 0 1 .28.656.9.9 0 0 1-.116.46.9.9 0 0 1-.336.321.9.9 0 0 1-.461.125.87.87 0 0 1-.64-.258l-6.087-5.953a1.1 1.1 0 0 1-.234-.328.9.9 0 0 1-.078-.375"></path></svg>  
                        </div>
                        <div class="flex scroll-width" style="width: 100%;">
                            <div class=" horizontal-scroll " ref="scrollContainer" style="padding: 2px; ">
                            <div  style="padding: 2px;">
                                <div :class="{'selected-background': select_type == -1}" class="btn-modalgpt" @click="select(null,-1)">
                                    <span>Tất cả</span>
                                </div>
                            </div>
                            <div  v-for="(content, index) in type_img" :key="index" style="padding: 2px;">
                                <div :class="{'selected-background': select_type == index}" class="btn-modalgpt" @click="select(content,index)">
                                    <span>{{content.name}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="btn-modalgpt  " style="width: 10px;" @click="srcoll($event,1)">
                            <svg style="margin-left: -7px;margin-top: 6px;" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M17.008 11.996q0 .203-.078.375t-.235.328l-6.086 5.953a.86.86 0 0 1-.64.266.9.9 0 0 1-.461-.125.9.9 0 0 1-.336-.328.925.925 0 0 1 .164-1.117l5.484-5.352-5.484-5.351a.92.92 0 0 1-.281-.657q0-.258.117-.46a.9.9 0 0 1 .336-.321.9.9 0 0 1 .46-.125.87.87 0 0 1 .641.258l6.086 5.953q.156.157.235.328.078.173.078.375">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="modal-body  -webkit-scrollbar" >
                    <table id="answer_table_popup" class=" table-striped  horizontal-scroll-y  -webkit-scrollbar">
                        <tbody class=" grid-4-images img " >
                            <tr v-for="(content, index) in data_array_select" :key="index" class="" style="padding: 2px;">
                                <div  >
                                    <div class="fchat_ai_image_item">
                                        <img :src=content.thumb_url>
                                            <div class="fchat_image-item-cover">
                                                <div class="image-item-cover-text">{{ content.content }}
                                                </div>
                                                <button @click="sendObject(content)" class="semi-button semi-button-primary semi-button-light image-item-cover-btn-wtZGo6 semi-button-with-icon" type="button"  >
                                                    <span class="semi-button-content"><span role="img" class="semi-icon semi-icon-default tetx-20">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M18.889 10.965q.12.108.28.108a.4.4 0 0 0 .284-.108.45.45 0 0 0 .146-.276q.097-.645.205-1.087a2.6 2.6 0 0 1 .28-.724q.172-.285.448-.451.28-.168.707-.264a9 9 0 0 1 1.045-.171.429.429 0 0 0 .401-.435.4.4 0 0 0-.112-.285.47.47 0 0 0-.285-.15q-.619-.088-1.041-.188a2.4 2.4 0 0 1-.69-.268 1.35 1.35 0 0 1-.443-.443 2.7 2.7 0 0 1-.289-.711q-.112-.44-.226-1.083a.45.45 0 0 0-.15-.276.4.4 0 0 0-.28-.109.4.4 0 0 0-.285.109.44.44 0 0 0-.142.276q-.096.645-.205 1.087a2.6 2.6 0 0 1-.284.724 1.36 1.36 0 0 1-.448.451q-.275.168-.702.264t-1.05.167a.44.44 0 0 0-.284.146.4.4 0 0 0-.113.289q0 .166.109.288.113.121.284.147.624.091 1.041.192.422.1.695.268.27.167.439.443.171.275.288.71.117.44.23 1.084.03.166.147.276"></path><path fill="currentColor" d="M19.91 16.675v-4.158a2.08 2.08 0 0 1-1.508-.012v3.016l-3.223-3.02q-.225-.21-.482-.313a1.43 1.43 0 0 0-1.06 0 1.7 1.7 0 0 0-.49.3l-3.066 2.739-1.25-1.129a1.7 1.7 0 0 0-.456-.278 1.1 1.1 0 0 0-.463-.102q-.245 0-.455.095-.21.088-.421.279l-1.768 1.574V8.075q0-.47.231-.7.239-.231.687-.231h7.93a2.01 2.01 0 0 1 1.441-1.51H6.063q-1.128 0-1.72.585-.585.578-.584 1.7v8.756q0 1.109.585 1.692.59.585 1.72.585h11.55q1.127 0 1.712-.585.585-.584.585-1.692"></path><path fill="currentColor" d="M9.177 12.712a1.6 1.6 0 0 1-.83-.225 1.7 1.7 0 0 1-.598-.598 1.6 1.6 0 0 1-.224-.836q0-.449.224-.823a1.7 1.7 0 0 1 .599-.605q.373-.225.829-.224.455 0 .822.224.375.225.599.605.224.375.224.823 0 .456-.224.836-.225.375-.599.598-.367.225-.822.225">

                                                            </path>
                                                        </svg>
                                                    </span>
                                                    <span class="semi-button-content-right" x-semi-prop="children">Sử dụng mẫu</span>
                                                </span>
                                                </button>
                                            </div>
                                    </div>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                    
               
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

// import ReplymessageComponent from '../message/Replymessage.vue'
// import LivechatService from "../../services/LivechatService";
import gptService from "@/services/gptService";
    export default {
        name: "SuggetimageComponent", 
        props: {
            // opengpt_img: Boolean,
            // isopengptimg:Boolean,
            
        },
        components: {
            // ReplymessageComponent
        },
        data() {
            return { 
                prompt_template:{},
                search_quick:'', 
                array_img_0:[{key: 0,link:"https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/image-example/art/classicart-eg17.png",text:'Cô gái đeo hoa tai ngọc trai, Johannes Vermeer, phong cách tranh biếm họa, lấy cảm hứng từ thời kỳ hoàng kim của hội họa Hà Lan, phông nền là đường phố Nhật Bản'}],               
                data_array:[],
                data_array_select:[],
                type_img:[  ],
                link_img:'',
                select_type:-1,

            };
        },
        async created(){
            await this.getTemplate()
            this.push_array() 
            // this.sendDataToParent()
        },
        watch:{
            
        },
        methods: {
            async getTemplate(){
                let shop = localStorage.getItem('aShop');
                shop = JSON.parse(shop)
                let check =  ''
                // let check =  localStorage.getItem('prompt_template');
                let data
                if(check){
                    data = JSON.parse(check)
                    this.prompt_template = data
                }else
                {
                    data =   await  gptService.getPromptTemplate(shop.api_token)
                    this.prompt_template = data.data.data
                    this.type_img = this.prompt_template.category
                    this.data_array =  this.prompt_template.content.filter(item => item.type === 1)
                    this.data_array_select =   this.prompt_template.content.filter(item => item.type === 1)
                    localStorage.setItem('prompt_template',JSON.stringify( this.prompt_template ));
                }
            },
            // sendDataToParent(data) {
            //     this.$emit('isopengptimg',data);
            // },
            sendObject(data){
                // console.log("emit lai data")
                 this.$emit('from_img',data);
            },
            // cancelmodal(){
            //     // console.log(this.isopengptimg)
            //     // this.isopengptimg = false
            //    this.sendDataToParent(false)
            // },
            srcoll(event,num){
                const container = this.$refs.scrollContainer;
                const scrollAmount = 100;
                if(num == 0){
                    //cuon trai
                    container.scrollLeft -= scrollAmount;
                }else if(num == 1){
                    // cuon phai
                    container.scrollLeft += scrollAmount;
                }
            },
            select(content,index){
                this.select_type = index
                if(index == -1){
                    this.data_array_select = this.data_array
                }else if(content.key == -1){
                        this.data_array_select = this.data_array
                }else{
                        // const data = this.data_array.filter(item => item.category_id === content._id);
                        //khi nao chuyen sang sql fchat.ai thi dung cai duoi 
                        const data = this.data_array.filter(item => item.category_id === content.id);
                        this.data_array_select = data
                }
            },
            push_array(){
                this.type_img = this.prompt_template.category
                this.data_array =  this.prompt_template.content.filter(item => item.type === 1)
                this.data_array_select =   this.prompt_template.content.filter(item => item.type === 1)
            }
        },
        computed: {
    }
    }
</script>
<style scoped src="@/assets/css/styles.css"></style>