<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
  <div class="Popup">
    <div class="chooseversion">
      <div class="px-2 w-full flex flex-col py-2 md:py-6 sticky top-0">
        <div class="relative flex flex-col items-stretch justify-center gap-2 sm:items-center">
          <div class="relative flex rounded-xl bg-gray-100 p-1 text-gray-900 dark:bg-gray-900">
            <ul class="flex w-full list-none gap-1 sm:w-auto">
              <li class="group/toggle w-full" data-testid="text-davinci-002-render-sha">
                <button type="button"
                  id="radix-:rl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"
                  class="w-full cursor-pointer"
                  @click="avtiveVersionGpt('3.5')">

                  <div :class="{'active-version': version_gpt === '3.5'}"
                    class="group/button relative flex w-full items-center justify-center gap-1 rounded-lg border py-3 outline-none transition-opacity duration-100 sm:w-auto sm:min-w-[148px] md:gap-2 md:py-2.5">
                    <span class="max-[370px]:hidden relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        fill="none" class="icon-sm transition-colors text-brand-green" width="16" height="16">
                        <path
                          d="M9.586 1.526A.6.6 0 0 0 8.553 1l-6.8 7.6a.6.6 0 0 0 .447 1h5.258l-1.044 4.874A.6.6 0 0 0 7.447 15l6.8-7.6a.6.6 0 0 0-.447-1H8.542l1.044-4.874Z"
                          fill="currentColor"></path>
                      </svg></span><span class="truncate text-sm font-medium md:pr-1.5 pr-1.5">GPT-3.5</span>
                  </div>
                </button>
              </li>
              <li class="group/toggle w-full" data-testid="gpt-4-upsell">

                <button v-if="this.package.package_id == 0" type="button" id="radix-:rn:"
                  aria-haspopup="menu" aria-expanded="false" data-state="closed" 
                  class="w-full cursor-pointer btn-plugin"
                  data-toggle="modal" data-target="#modal-upgrade-package"
                  @click="openUpgradePackageModal"
                  >
                  <div :class="{'active-version': version_gpt === '4'}"
                    class="group/button relative flex w-full items-center justify-center gap-1 rounded-lg border py-3 outline-none transition-opacity duration-100 sm:w-auto sm:min-w-[148px] md:gap-2 md:py-2.5 border-transparent text-gray-500 hover:text-gray-800 hover:dark:text-gray-100">
                    <span class="max-[370px]:hidden relative">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        fill="none" class="icon-sm transition-colors group-hover/button:text-brand-purple" width="16"
                        height="16">
                        <path
                          d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z"
                          fill="currentColor"></path>
                      </svg></span><span class="truncate text-sm font-medium md:pr-1.5 pr-1.5">GPT-4</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                        class="icon-sm ml-0.5 transition-colors sm:ml-0 group-hover/options:text-gray-500 !text-gray-500 -ml-2 group-hover/button:text-brand-purple">
                        <path fill-rule="evenodd"
                          d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                          clip-rule="evenodd"></path>
                      </svg>
                  </div>
                  
                </button>

                <button v-else type="button" id="radix-:rn:"
                  aria-haspopup="menu" aria-expanded="false" data-state="closed" 
                  class="w-full cursor-pointer"
                  @click="avtiveVersionGpt('4')"
                  >
                  <div :class="{'active-version': version_gpt === '4'}"
                    class="group/button relative flex w-full items-center justify-center gap-1 rounded-lg border py-3 outline-none transition-opacity duration-100 sm:w-auto sm:min-w-[148px] md:gap-2 md:py-2.5 border-transparent text-gray-500 hover:text-gray-800 hover:dark:text-gray-100">
                    <span class="max-[370px]:hidden relative"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        fill="none" class="icon-sm transition-colors group-hover/button:text-brand-purple" width="16"
                        height="16">
                        <path
                          d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z"
                          fill="currentColor"></path>
                      </svg></span><span class="truncate text-sm font-medium md:pr-1.5 pr-1.5">GPT-4</span>
                      <svg v-if="this.package.package_id == 0"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                        class="icon-sm ml-0.5 transition-colors sm:ml-0 group-hover/options:text-gray-500 !text-gray-500 -ml-2 group-hover/button:text-brand-purple">
                        <path fill-rule="evenodd"
                          d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                          clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PopupChooseVersion",
  components: {
    
  },
  props: ['package','version_gpt'],
  data() {
    return {
    };
  },
  methods:{
    avtiveVersionGpt(version) {
      this.$root.$refs.compname_component.avtiveVersionGpt(version)
    },
    openUpgradePackageModal() {
      $('#modal-upgrade-package').modal('show');
    },
  }
};
</script>

<style scoped src="../../assets/css/popupversion.css"></style>