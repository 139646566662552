<script>
  import jQuery from 'jquery';
  let $ = jQuery;
  console.log($); 
</script>
<template>
    <div  class="modal-fade-gpt  " style="margin-top: -15px;height: 230px;">
        <div class="modal-dialog modal-send-block" >
            <div class="modal-content "  style="height: 230px;">
                <div class="modal-header" style="width: 100%;" >
                  
                                <!-- <button type="button" data-dismiss="modal" class="close" fdprocessedid="rob7ub" style="margin-top: -15px;font-size: 25px;" @click="cancelmodal()">×</button> -->
                                
                                <div  style="display: flex;width: 100%;">
                                    <div class=" btn-modalgpt-scroll  " @click="srcoll($event,0)" >
                                                <svg style="margin-left: -8px;margin-top: 8px;" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M8.055 12.004q0-.203.078-.375t.234-.328l6.086-5.953a.86.86 0 0 1 .64-.266q.25 0 .462.125.21.118.336.328a.925.925 0 0 1-.164 1.117l-5.485 5.352 5.485 5.352a.92.92 0 0 1 .28.656.9.9 0 0 1-.116.46.9.9 0 0 1-.336.321.9.9 0 0 1-.461.125.87.87 0 0 1-.64-.258l-6.087-5.953a1.1 1.1 0 0 1-.234-.328.9.9 0 0 1-.078-.375"></path></svg>  
                                    </div>
                                    <div class="horizontal-scroll " ref="scrollContainer" style=" margin-left: 10px;width: 100%;" >
                                        <div  v-for="(content, index) in data_array" :key="index">
                                            <div @click="sendObject(content)" class="btn-image-gpt ">
                                                <div  style="width: 108px;">
                                                    <div >
                                                        <div  style="width: 100%; height: 0px; padding-bottom: min(130%, 130px); max-width: 100px;">
                                                            <span style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
                                                                <span style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
                                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27100%27%20height=%27130%27/%3e" style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;">
                                                                </span>
                                                                <picture>
                                                                    <!-- <source type="image/avif" srcset="https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/writing-templates/template_tiktokScript.png" src="https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/writing-templates/template_tiktokScript.png">
                                                                    <source type="image/webp" srcset="https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/writing-templates/template_tiktokScript.png" src="https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/writing-templates/template_tiktokScript.png"> -->
                                                                    <img  alt="image"  width="100" height="130" :src="content.thumb_url" imagex-type="react" imagex-version="0.3.7" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; ">
                                                                </picture>
                                                        </span>
                                                    </div>
                                                    <div class="fchat_item-title-long" title="Kịch bản TikTok">{{content.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="btn-modalgpt-scroll  " @click="srcoll($event,1)">
                                        <svg style="margin-left: -8px;margin-top: 8px;" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M17.008 11.996q0 .203-.078.375t-.235.328l-6.086 5.953a.86.86 0 0 1-.64.266.9.9 0 0 1-.461-.125.9.9 0 0 1-.336-.328.925.925 0 0 1 .164-1.117l5.484-5.352-5.484-5.351a.92.92 0 0 1-.281-.657q0-.258.117-.46a.9.9 0 0 1 .336-.321.9.9 0 0 1 .46-.125.87.87 0 0 1 .641.258l6.086 5.953q.156.157.235.328.078.173.078.375">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
               
            </div>
        </div>
    </div>
</template>



<script>
import Vue from 'vue';

// import ReplymessageComponent from '../message/Replymessage.vue'
// import LivechatService from "../../services/LivechatService";
import gptService from "@/services/gptService";
export default {
        name: "SuggetChatComponent", 
        props: {
            // opengpt: Boolean,
            // isopengpt: Boolean,
        },
        components: {
            // ReplymessageComponent
        },
        data() {
            return { 
                prompt_template :{},
                search_quick:'', 
                array_img_0:[{key: 0,link:"https://sf-flow-web-cdn.ciciai.com/obj/ocean-flow-web-sg/samantha/image-example/art/classicart-eg17.png",text:'Tiktok 1'}],               
                data_array:[],
                data_array_select:[],
                link_img:'',
                select_type:0,

            };
        },
       async  created(){
            await this.getTemplate()
            this.push_array() 
            
           
        },
        watch:{
        //     opengpt: function (newVal,oldVal) {
        //     console.log("thay doi =========",this.opengpt)
        //     this.opengpt_cpm = this.opengpt
        // },
        },
        methods: {
            async getTemplate(){
                let shop = localStorage.getItem('aShop');
                shop = JSON.parse(shop)
                let check = ''
                // let check =  localStorage.getItem('prompt_template');
                let data
                if(check){
                    data = JSON.parse(check)
                    this.prompt_template = data
                }else
                {
                    data =   await  gptService.getPromptTemplate(shop.api_token || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzaG9wX2lkIjoxLCJpYXQiOjE3MTYxOTM4NjF9.wjdPrmjKzga51K95QRmnT7gp-Mw1t1wQSCMQzWMahMc')
                    this.prompt_template = data.data.data
                    localStorage.setItem('prompt_template',JSON.stringify( this.prompt_template ));
                }
            },
            // sendDataToParent(data) {
            //     // console.log("this.opengpt_cpm",this.opengpt_cpm)
            //     this.$emit('isopengpt', data);
            // },
            sendObject(data){
                // console.log("emit lai data")
                 this.$emit('from_chat',data);
            },
            // cancelmodal(){
            //     // console.log(this.opengpt_cpm)
            //    this.sendDataToParent(false)
            // },
            srcoll(event,num){
                const container = this.$refs.scrollContainer;
                const scrollAmount = 250;
                if(num == 0){
                    //cuon trai
                    container.scrollLeft -= scrollAmount;
                }else if(num == 1){
                    // cuon phai
                    container.scrollLeft += scrollAmount;
                }
            },
            select(content,index){
                this.select_type = index
               if(content.key == 1){
                    this.data_array_select = this.data_array
               }else{
                    const data = this.data_array.filter(item => item.key === content.key);
                     this.data_array_select = data
               }
               
            },
            push_array(){
                const data = this.prompt_template.content.filter(item => item.type == 0)
                this.data_array  = data
                this.data_array_select = data
            }
        },
        computed: {
    }
    }
</script>
<style scoped src="@/assets/css/styles.css"></style>