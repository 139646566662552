<template>
    <div class="gpts_search col-lg-8 col-md-12 col-sm-12 col-12 mx-auto sticky-search">
        <img src="@/assets/svg/icon-search.svg" class="gpts_icon_search ">
        <input class="form-control gpts_search_input w-100" v-model="searchQuery"
            :placeholder="$t('find_prompt.find_prompt')">
        <select class="select-option-search" v-model="selectQuery">
            <option value="1">{{ $t('assistant.assistant') }}</option>
            <option value="2">{{ $t('personal_assistant.personal_assistant') }}</option>
            <option value="3">Workflow</option>
            <option value="4">{{ $t('my_assistant.my_assistant') }}</option>
        </select>
        <div class="dropdown-list" v-if="this.showDropdown" ref="dropdown">
            <ul>
                <li v-for="item in paginatedList" :key="item.id" @click="showIsModalAssistant(item)">
                    <span class="d-flex align-items-center">
                        <img v-if="item.avatar_url" :src="item.avatar_url" width="25" height="25" alt="icon">
                        <img v-else-if="item.icon" :src="item.icon" width="25" alt="icon">
                        <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="25" alt="icon">
                        <span style="margin-left:10px" class="fs-14 fw-400">{{ item.name }}</span>
                    </span>
                </li>
            </ul>
            <div style="margin:0px 16px 16px">
                <button v-if="showMoreButton" @click="loadMore" class="btn btn-loadmore col-12">Xem thêm</button>
            </div>
        </div>
        <ModalAssistant v-if="isModalAssistant" :close="closeIsModalAssistant" :PropsHeader="PropsHeader"
            :PropsBody="PropsBody" :category="category" />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState, mapActions } from 'vuex';
import ModalAssistant from '@/components/popupmodal/ModalAssistant'
import categoryService from "@/services/categoryService";

export default {
    name: 'GPTS_HEADER',
    components: { ModalAssistant },
    data() {
        return {
            searchQuery: '',
            selectQuery: 1,
            filteredList: [],
            currentPage: 1,
            itemsPerPage: 10,
            isModalAssistant: false,
            PropsHeader: {},
            PropsBody: [],
            category: {},
            filterPromptDebounced: null
        }
    },
    created() {
        this.filterPromptDebounced = debounce(this.filterPrompt, 500);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
    },
    mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');
        document.addEventListener('click', this.closeDropdown);
    },
    computed: {
        ...mapState(['showDropdown']),
        paginatedList() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredList.slice(start, end);
        },
        showMoreButton() {
            return this.filteredList.length > this.currentPage * this.itemsPerPage;
        }
    },
    watch: {
        searchQuery() {
            this.filterPromptDebounced();
        },
        selectQuery() {
            this.filterPrompt();
        }
    },
    methods: {
        ...mapActions(['updateshowDropdown']),
        async showIsModalAssistant(item) {
            this.updateshowDropdown(!this.showDropdown);
            this.isModalAssistant = true;
            this.PropsHeader = item;
            let token = localStorage.getItem('token');
            if (this.selectQuery == 2 || this.selectQuery == 3) {
                this.isModalAssistant = false;
                localStorage.setItem('category', JSON.stringify(item));
                await this.getDetail({ package_id: this.aPackage.package_id, categoryId: item.id }, token);
                if (this.selectQuery == 2) {
                    this.$router.push({ name: 'collection_step', params: { id: item.id } });
                } else {
                    this.$router.push({ name: 'workflow_step', params: { id: item.id } });
                }
            }
            this.category = item;
        },
        async getDetail(object_data, token) {
            let categoryId = object_data.categoryId;
            await categoryService.getDetail(object_data, token, categoryId)
                .then((res) => {
                    this.PropsBody = res.data.data;
                    this.category = JSON.parse(localStorage.getItem('category'));
                }).catch((error) => {
                    console.log(error);
                });
        },
        closeIsModalAssistant() {
            this.isModalAssistant = false;
            localStorage.removeItem("PropsBody");
            localStorage.removeItem("PropsHeader");
            localStorage.removeItem("category");
        },
        filterPrompt() {
            let listToFilter = [];
            if (this.selectQuery == '1') {
                listToFilter = JSON.parse(localStorage.getItem('listPrompt'));
            } else if (this.selectQuery == '2') {
                listToFilter = JSON.parse(localStorage.getItem('listCollection'));
            } else if (this.selectQuery == '3') {
                listToFilter = JSON.parse(localStorage.getItem('listWorkflows'));
            } else if (this.selectQuery == '4') {
                listToFilter = JSON.parse(localStorage.getItem('myPrompt'));
            } else {
                listToFilter = JSON.parse(localStorage.getItem('listPrompt'));
            }
            // Log dữ liệu để kiểm tra
            // console.log('List to Filter:', listToFilter);
            //     console.log('Search Query:', this.searchQuery);
            const normalizeString = (str) => {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            };

            this.filteredList = listToFilter.filter(item => {
                const itemNameNormalized = normalizeString(item.name);
                const searchQueryNormalized = normalizeString(this.searchQuery);
                return itemNameNormalized.includes(searchQueryNormalized);
            });
            // Log kết quả lọc để kiểm tra
            // console.log('Filtered List:', this.filteredList);

            this.updateshowDropdown(true);
            this.currentPage = 1;
        },
        loadMore() {
            this.currentPage += 1;
        },
        closeDropdown(event) {
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.updateshowDropdown(!this.showDropdown);
                localStorage.removeItem("PropsBody");
                localStorage.removeItem("PropsHeader");
                localStorage.removeItem("category");
            }
        }
    }
}
</script>

<style scoped src="../../assets/css/gpts.css"></style>
