<template>
<div class="">
    <div class="gpts_content_tile fs-20 fw-600 text-black">
        {{ $t('my_assistant.my_assistant') }}
    </div>
    <div class="gpts-content_des mt-1 text-neutral">
        Danh sách trợ lý bạn tạo
    </div>
    <div class="row mx-md-n5 ">
        <div v-for="(prompt, index) in this.visiblePrompts" :key="index" class="col-lg-6 col-md-6  col-12 pt-16 px-1 px-md-1 ">
            <div class="d-flex radius-16px bg-neutral-hover1 cursor-pointer" @click.prevent="openIsAssistant(prompt)">
                <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-12-24">
                    <img v-if="prompt.avatar_url" :src="prompt.avatar_url" width="48" height="48" class="img-fluid rounded-circle" alt="prompt.avatar_url">
                    <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48" height="48" class="img-fluid rounded-circle" alt="svg">
                </div>
                <div class="flex-grow-1 p-8px-0px">
                    <div class="fs-14 fw-600 text-black limit-text-2-line">
                        {{ prompt.name }}
                    </div>
                    <div class="fw-12 fw-400 limit-text-2-line text-black pt-4px">
                        {{ prompt.description }}
                    </div>
                </div>
            </div>
        </div>

        <button class="btn btn-loadmore col-12" @click="visiblePrompt += step" v-if="visiblePrompt < listMyPrompt.length">{{ $t('load_more.load_more') }}</button>
        <button class="btn btn-loadmore col-12" @click="collapseListMyPrompt" v-if="visiblePrompt >= listMyPrompt.length && listMyPrompt.length > step">
          {{ $t('collapse.collapse') }}
        </button>
    </div>
    <ModalAssistant v-if="isAssistant==true" :PropsHeader="this.PropsHeader" :close="closeIsAssistant" />
</div>
</template>

    
<script>
// import gptService from "@/services/gptService";
import ModalAssistant from '@/components/popupmodal/ModalAssistant'

export default {
    name: 'MyPrompt',
    components: {
        ModalAssistant
    },
    props: {
        listMyPrompt: Array
    },
    data() {
        return {
            token: '',
            aUser: {},
            aPackage: {},
            aShop: {},
            visiblePrompt: 6,
            step: 6,
            page: 2,
            isAssistant: false,
            PropsHeader: {}
        }
    },
    created() {

    },
    beforeMount() {},

    beforeDestroy() {},
    mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');

    },

    computed: {
        visiblePrompts() {
            return this.listMyPrompt.slice(0, this.visiblePrompt)
        },
    },
    watch: {

    },
    methods: {
        openIsAssistant(item) {
            this.PropsHeader = item
            this.isAssistant = true
        },
        closeIsAssistant() {
            this.isAssistant = false
        },
        collapseListMyPrompt() {
            this.visiblePrompt = this.step;
        }
    }

}
</script>

    
<style scoped src="@/assets/css/gpts.css"></style>
