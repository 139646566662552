// import http from "../http-common";
import "dotenv";
import axios from "axios";
import config from "../config";
let API_URL=config.API_URL;
// let local="http://localhost:3034/api/v1/";

class convService {
  async deleteConv(token,id) {
    let url = "";
    url = API_URL + 'conversation/deleteConv/'+id;

    try {
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-type": "application/json",
          token: token,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async updateConv(token, object,id) {
    let url = API_URL + 'conversation/updateConv/'+id;
    return await axios({
      url: url,
      method: 'post',
      headers: {
        "Content-type": "application/json",
        "token": token
      },
      data: object
    }).then(function (response) {
      return response;
    }).catch(error => {
      console.log(error)
    });
  }
}

export default new convService();
