<template>
    <div class="container gpts">
        <div class="gpts_title">
            {{ $t('gpt_assistant.gpt_assistant') }}
        </div>
        <div class="gpts_des">
            {{ $t('gpts_des.gpts_des') }}
        </div>
        <div class="d-flex flex-column mb-3">
            <GPTS_HEADER />
            <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto sticky-nav">
                <GPTS_NAV :activeNav="activeNav" :scrollToSection="scrollToSection" />
            </div>
            <div class="gpts_search col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                <div class="gpts_content row">

                    <!-- Trợ lý yêu thích  -->
                    <div id="topic" class="topic  ">
                        <div class="gpts_content_tile fs-16 fw-600 text-neutral d-flex align-items-center">
                            <img src="@/assets/svg/message1.svg" alt="">
                            <span class="ml-8px">{{ this.category.name }}</span>
                            <span class="ml-8px">({{ this.stepCount }})</span>

                        </div>

                        <div class="row mx-md-n5 ">
                            <div v-for="(collection, index) in visibleCollections" :key="index"
                                class="col-lg-6 col-md-6  col-12 pt-16 ">
                                <div class="d-flex radius-16px bg-neutral-hover1 cursor-pointer" @click.prevent="openModal(collection)">
                                    <div class="flex-shrink-0 d-flex align-items-center justify-content-center p-12-24">
                                        <img v-if="collection.avatar_url" :src="collection.avatar_url" width="48"
                                            height="48" class="img-fluid rounded-circle" alt="svg">
                                        <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="48"
                                            height="48" class="img-fluid rounded-circle" alt="svg">
                                    </div>
                                    <div class="flex-grow-1 p-8px-0px">
                                        <div class="fs-14 fw-600 text-black limit-text-2-line">
                                            {{ collection.name }}
                                        </div>
                                        <div class="fw-12 pt-4px text-black fw-400 limit-text-2-line" >
                                            {{ collection.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-loadmore col-12" @click="loadMore"
                                v-if="visibleCollection < collectionStep.length">
                                {{ $t('load_more.load_more') }}
                            </button>
                            <button class="btn btn-loadmore col-12" @click="collapseCollection"
                                v-if="visibleCollection >= collectionStep.length && collectionStep.length > step">
                                {{ $t('collapse.collapse') }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <ModalAssistant v-if="showModal == true" 
        :PropsHeader="collectionHeader" 
        :PropsBody="collectionStep" 
        :category="category"
        :open="openModal" 
        :close="closeModal"
                        :updateDataProps="updateDataProps"
        />
    </div>
</template>

<script>
import GPTS_NAV from '@/pages/gpts/GPTS_NAV.vue'
import utils from '@/utils/index'
import categoryService from "@/services/categoryService";
import ModalAssistant from '@/components/popupmodal/ModalAssistant'
import GPTS_HEADER from '@/pages/gpts/GPTS_HEADER.vue'

export default {
    name: 'GPTS',
    components: {
        GPTS_NAV, ModalAssistant,GPTS_HEADER
    },
    data() {
        return {
            activeNav: 'all',
            paddingTopValue: 40,
            sectionIds: ['all', 'topic', 'collection', 'workflow', 'favorite_assistant', 'my_assistant'],
            token: '',
            aUser: {},
            aPackage: {},
            aShop: {},
            stepCount: 0,
            collectionStep: [],
            category: {},
            visibleCollection: 6,
            initialVisibleCollection: 6,
            step: 6,
            page: 2,
            showModal: false,
            collectionHeader:{}

        }
    },
    created() {

    },
    beforeMount() { },

    beforeDestroy() { },
    mounted() {
        this.aPackage = JSON.parse(localStorage.getItem('package'));
        this.aShop = JSON.parse(localStorage.getItem('aShop'));
        this.token = localStorage.getItem('token');
        this.aUser = JSON.parse(localStorage.getItem('aUser'));
        this.getDetailCollection({ package_id: this.aPackage.package_id, user_id:this.aUser.id }, this.token);

    },

    computed: {
        visibleCollections() {
            return this.collectionStep.slice(0, this.visibleCollection)
        },
    },
    watch: {},
    methods: {
        updateDataProps(object){
          this.category=object.category;
          this.collectionHeader=object.PropsHeader;
          this.collectionStep=object.PropsBody;
        },
        closeModal() {
            this.showModal = false;
        },
        openModal(collection) {
            this.collectionHeader=collection
            this.showModal = true;
        },
        loadMore() {
            this.visibleCollection += this.step;
        },
        collapseCollection() {
        this.visibleCollection = this.step;
        },
        getDetailCollection(object_data, token) {
            let categoryId = this.$route.params.id;
            categoryService.getDetail(object_data, token, categoryId)
                .then((res) => {
                    // console.log(res);
                    this.collectionStep = res.data.data;

                    //   localStorage.setItem('flow',JSON.stringify(this.collectionStep));
                    this.category = JSON.parse(localStorage.getItem('category'))
                    if (Array.isArray(this.collectionStep)) {
                        this.stepCount = this.collectionStep.length;
                    }
                    // console.log(res.data.data);

                }).catch((error) => {
                    console.log(error);
                });

        },

       async scrollToSection(sectionId) {
            if (this.$route.path !== '/gpts') {
               await this.$router.push('/gpts');
                if(sectionId){
                  utils.scrollToSection(sectionId)
                }
            }
        }
    }

}
</script>

<style scoped src="@/assets/css/gpts.css"></style>
