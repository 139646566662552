import { render, staticRenderFns } from "./AllPrompt.vue?vue&type=template&id=97293bb0&scoped=true"
import script from "./AllPrompt.vue?vue&type=script&lang=js"
export * from "./AllPrompt.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/gpts.css?vue&type=style&index=0&id=97293bb0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97293bb0",
  null
  
)

export default component.exports