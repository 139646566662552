
module.exports = {
  removeDataLocal() {
    localStorage.removeItem("category");
    localStorage.removeItem("flow");
    localStorage.removeItem("flowStep");
    localStorage.removeItem("PropsBody");
    localStorage.removeItem("PropsHeader");

  },
  limitText(text, option) {
    if (!text) return "";
    if (option === "name") {
      if (text.length > 56) {
        return text.substring(0, 56) + "...";
      } else {
        return text;
      }
    }

    if (option === "description") {
      if (text.length > 70) {
        return text.substring(0, 70) + "...";
      } else {
        return text;
      }
    }
  },
  handleScroll() {
    if(this.sectionIds){


      this.sectionIds.forEach((sectionId) => {
        const element = document.getElementById(sectionId);
  
        if (element) {
          if (window.scrollY > element.offsetTop) {
            element.style.paddingTop = `${this.paddingTopValue}px`;
          } else {
            if (sectionId === "all") {
              element.style.paddingTop = "8px";
            } else {
              element.style.paddingTop = "40px";
            }
          }
        }
      });
    }
    
  },
  scrollToSection(sectionId) {
    // console.log(sectionId)
    if(sectionId){
      const previousElement = document.getElementById(this.activeNav);
    if (previousElement) {
      previousElement.style.paddingTop = ""; // Xóa padding-top của element cũ
    }

    const element = document.getElementById(sectionId);

    if (element.id === "my_assistant") {
      element.style.paddingBottom = "60vh";
    }
    // console.log(element)
    if (element) {
      // Thêm padding-top vào element mới
      element.style.paddingTop = "120px";

      element.scrollIntoView({
        behavior: "smooth",
      });

      this.activeNav = sectionId;
    }
    }
  },
  limitText50(text){
    if (!text) return "";
      if (text.length > 50) {
        return text.substring(0, 50) + "...";
      } else {
        return text;
      }
  },
  limitText20(text){
    if (!text) return "";
      if (text.length > 20) {
        return text.substring(0, 20) + "...";
      } else {
        return text;
      }
  },
  async refreshCache(shop_id){
   await fetch(`https://fchat.ai/clear/cache?module=prompt&shop_id=${shop_id}`, {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
     }
    })
    .then(response => response.json())
    .then(data => {
      console.log('Cache cleared:', data);
    })
    .catch(error => {
      console.error('Error clearing cache:', error);
    });
  },
  formatNumber(value) {
    const result = parseFloat(value);

    if (isNaN(result)) {
        return "Invalid value";
    }
    return result.toLocaleString('vi-VN', {
        maximumFractionDigits: 0
  });
  },
  
};
