<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {

  created(){
   
   
  },
  mounted() {
    if(!this.$cookies.get('request')){
      this.$cookies.set('request', 0, '1d');
    }

    window.addEventListener('beforeunload', this.clearLocalStorage);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.clearLocalStorage);
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem('PropsHeader'); 
      localStorage.removeItem('PropsBody'); 
      localStorage.removeItem('PropsCategory'); 
    }
  }
}
</script>