<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
<div class="">
    <!-- Bộ khung ở giữa (chiếm toàn bộ chiều rộng của màn hình) -->
    <ContentMain :version_gpt="this.version_gpt" :show_popup_version="this.show_popup_version" :avatar_bot="this.avatar_bot" :languageData="this.languageData" :isSending="this.isSending" :token="this.token" :aUser="this.aUser" :messages="this.messages" :listPrompt="this.listPrompt" :package="this.package" :is_package="this.is_package" :limit_request="this.limit_request" :prompt_options="this.prompt_options" :aPrompt="this.aPrompt" :show_version_gpt="this.show_version_gpt" :show_load_more="this.show_load_more" :aNewMessageText="this.aNewMessageText" :isTyping="this.isTyping" :replyTyperWriter="this.replyTyperWriter" :is_album="this.is_album" :is_voice="this.is_voice" :is_radio="this.is_radio" :is_checkbox="this.is_checkbox" :photo_length="this.photo_length" :photo_size="this.photo_size" :is_select="this.is_select" />
</div>
</template>

<script>
import Vue from 'vue';
import Notifications from 'vue-notification';
Vue.use(Notifications);
import i18ns from '@/index.js';
import languageJson from '@/lang/language.json';

import SidebarRight from "@/components/sidebar/SidebarRight.vue";
import Conversations from "@/components/conversation/Conversations.vue";
import ContentMain from "@/components/content/ContentMain.vue";
import PopupAssistant from "@/components/popupmodal/PopupAssistant.vue";
import PopupChooseVersion from '@/components/popupmodal/PopupChooseVersion.vue';
import PopupUpgradePackage from '@/components/popupmodal/UpgradePackage.vue';
import config from "@/config";
import gptService from "@/services/gptService";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ChatGPT",
  components: {

   SidebarRight,
    Conversations,
    ContentMain,
    PopupAssistant,
    PopupChooseVersion,
    PopupUpgradePackage,

  },
  props: {

  },
  data() {
    return {
      base_url: config.BASE_URL,
      TOKEN_SHOP_SYSTEM: config.TOKEN_SHOP_SYSTEM,
      languageData: languageJson,
      avatar_bot: config.BASE_URL + 'assets/svg/icon-chat.png',
      version_gpt: "3.5",
      conversations: [],
      listPrompt: [],
      listTags: [],
      aPrompt: {},
      prompt_options: [],
      listShop: [],
      messages: [],
      aNewMessageText: "",
      aShop: {},
      aUser: {},
      package: {},
      shop_role: {},
      conv: {},
      conv_id: "",
      shop_id:0,
      token: "",
      page_conv: 1,
      page_mess: 1,
      page_prompt: 1,
      count_mess: 1,
      type_action: 0,
      show_version_gpt: false,
      show_popup_version: true,
      show_load_more: false,
      is_shop: false,
      isLoading: true,
      isSending: false,
      isSidebarRight: false,
      is_livechat: false,
      isMenuSidebar: false,
      isMenuContentMain:false,
      isTyping: false,
      isNavbarClicked: false,
      currentTypeWriter: 0,
      replyTyperWriter: '',
      indexTypeWriter: '',
      speedWriter: 0, 
      selectedConversation: null,
      limit_request: false,
      is_package: false,
      is_image_openai: true,
      is_album: false,
      is_voice: false,
      is_radio: false,
      is_checkbox: false,
      is_select: false,
      photo_length: '',
      photo_size: '',
      color1:'',
      isHovered: false,
      isIConSVG:false,
      isShowModal: false,
      selectedLanguage: this.$i18n.locale,
      website:{},
      PropsHeader:null
    };
  },
  created() {

    gptService.getLogin().then((res) => {
      if (!res.error) {
        const data = res.data.data;
        this.shop_id =data.shop ? data.shop.id : 0;
        this.token =data.shop ? data.shop.api_token : '';
        this.listShop = data.list_shop;
        this.shop_role = data.role_user;
        this.aShop = data.shop ? data.shop : []
        this.aUser = data.user;
        this.package = data.package || {};
        localStorage.setItem('website', JSON.stringify(data.website))
        if(this.shop_id){
            // Lấy danh sách conversation
          this.getConversations({shop_id: this.shop_id});
          // Lấy danh sách prompt
          this.getListPrompt({package_id: this.package.package_id})
          //console.log("this.package.package_id",this.package.package_id);
          // Lấy danh sách tag
          
          if (this.$route.query.id) {
            this.loadConversation(this.$route.query.id);
          }
          if (localStorage.getItem('flow')) {
            const flowData = JSON.parse(localStorage.getItem('flow'));
            if (Array.isArray(flowData) && flowData.length > 0) {
              this.isSidebarRight = true;
            }
          }
        }

      }
    });
    // Hàm gọi từ component cha
    this.$root.$refs.compname_component_gpt = this;

  },
  watch: {
    '$route.query.id': function(newId) {
      if (newId) {
        this.loadConversation(newId);
      }
    },

  },
  computed: {
    ...mapState(['welcome']),
  },
  mounted() {
    this.PropsHeader=JSON.parse(localStorage.getItem("PropsHeader"));
    this.website=JSON.parse(localStorage.getItem("website"))
    if(this.$route.query.id != 0 && this.$route.query.id){
        $('#list-mess').removeClass('flex-content-items-center')
        $('#welcome').hide()
      }else if(!this.PropsHeader && !this.$route.query.id ){
        $('#welcome').show()
      }
    this.token=localStorage.getItem('token');
    this.checkWindowSize();
    window.addEventListener('resize', this.checkWindowSize);
    if(this.shop_id){
      this.loadConversation(this.$route.query.id);
    }
    if (localStorage.getItem('flow')) {
      const flowData = JSON.parse(localStorage.getItem('flow'));
      if (Array.isArray(flowData) && flowData.length > 0) {
        this.isSidebarRight = true;
      }
    }
    this.getListTag({shop_id_owner:this.website.shop_id_owner})

    let prompt=JSON.parse(localStorage.getItem('flowStep'))
    if (prompt && prompt.one_step && prompt.one_step === 1) {
      this.appendPrompt(prompt);
    }
    setTimeout(function() {
    localStorage.removeItem('newMessageText');
    }, 2000);

  },
  beforeRouteLeave(to, from, next) {
    if (from.path === '/') {
      localStorage.removeItem('category');
      localStorage.removeItem('flow');
      localStorage.removeItem('flowStep');
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    ...mapMutations({
      setWelcome: 'setWelcome',
      setQueryId:'setQueryId'
    }),
    closeModal() {
      this.isShowModal = false
    },
    callModal() {
      this.isShowModal = true
    },
    handleNavbarClick() {
      this.isNavbarClicked = !this.isNavbarClicked;
    },
    checkWindowSize() {
      this.isMenuSidebar = window.innerWidth <= 768;
    },
    toggleSidebarRight() {
      this.isSidebarRight = false;
    },
    toggleIconLive() {
      this.isSidebarRight = true;
    },
    toggleCloseLive() {
      this.is_livechat = !this.is_livechat; // Toggle the value
      localStorage.setItem('is_livechat', this.is_livechat);
    },
    toggleSidebarMenu() {
      this.isMenuSidebar = true;
      this.isMenuContentMain = false;
      this.isHovered=false;
      this.isIConSVG=true;
    },
    hideIsConvSvg() {
      this.isMenuSidebar = false;
      this.isMenuContentMain = true;
      this.color1='#373737';
      this.isHovered=true;
      this.isIConSVG=false;
    },
    avtiveVersionGpt(version) {
      // console.log(version);
      this.version_gpt = version;
    },
    getConversations(object_data) {
      if(!object_data) {
        object_data = {};
        object_data.shop_id = this.shop_id;
      }
      if(!object_data.shop_id) object_data.shop_id = this.shop_id;
      object_data.page = this.page_conv;

      gptService.getConversations(object_data, this.token)
        .then((res) => {
          if (!res.data.error) {
            let list_conversations = res.data.data;
            this.conversations.push(...list_conversations);
            this.page_conv++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListPrompt(object_data) {
      object_data.user_id = this.aUser.id;
      object_data.package_id = this.package.package_id;
      object_data.type_action = this.type_action;
      object_data.shop_id_owner=this.website.shop_id;
      gptService.getListPrompt(object_data, this.token)
        .then((res) => {
          if (!res.data.error) {
            this.listPrompt = res.data.data;
            this.is_shop = object_data.is_shop;
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    getPageListPrompt(object_data) {
      object_data.user_id = this.aUser.id;
      object_data.package_id = this.package.package_id;
      object_data.shop_id_owner=this.website.shop_id;

      gptService.getListPrompt(object_data, this.token)
        .then((res) => {
          if (!res.data.error) {
            let prompt_data = res.data.data;
            prompt_data.forEach(item => {
                if (!this.listPrompt.some(existingItem => existingItem.id === item.id)) {
                    this.listPrompt.push(item);
                }
            });
            this.is_shop = object_data.is_shop;
            this.page_prompt++;
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    getListFavourite(object_data) {
      gptService.getListFavourite(object_data, this.token)
        .then((res) => {
          if (!res.data.error) {
            this.listPrompt = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListTag(object) {
      gptService.getListTag(this.token,object)
        .then((res) => {
          if (!res.data.error) {
            this.listTags = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPackage() {
      gptService.getPackage(this.shop_id, this.token)
        .then((res) => {
          if (!res.data.error) {
            this.package = res.data.data || {};
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listFavourite(object_data){
      if(!object_data.is_favourite){
        this.type_action = 1;
        object_data.is_shop = object_data.isMyAssistantOnClicked;
        this.getListPrompt(object_data, this.token)
      }
      else{
          this.type_action = 2;
          object_data.type_action = 2;
          gptService.getListPrompt(object_data, this.token).then((res) => {
            if (!res.data.error) {
              this.is_favourite = 0;
              this.listPrompt = res.data.data;
            }
        });
      }
    },
    myAssiatant(isMyAssistantOnClicked){
      let object_data = {
        is_shop: isMyAssistantOnClicked,
        type_action: isMyAssistantOnClicked ? 3 : 1
      };
      this.type_action = 3;
      this.getListPrompt(object_data);
    },
    scrollToEnd(i = ""){
      let container_message;
      if (i != "") {
        container_message = this.$el.querySelector("#list-mess" + i);
      } else {
        container_message = this.$el.querySelector("#list-mess");
      }
      if (container_message) {
        container_message.scrollTop = container_message.scrollHeight;
      }
    },
    handleScrollMessage(event) {
      if (event.target.scrollTop <= 0 && !this.view_conv) {
        this.loadMessages(this.conv.id, this.page_mess +1);
      }
    },
    handleScrollConv(event) {
      const target = event.target;
      const scrollBottom = target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollBottom <= 0.6) {
          this.getConversations({page_conv: this.page_conv + 1});
      }
    },
    handleScrollPrompt(event) {
      const target = event.target;
      const scrollBottom = target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollBottom <= 0.6) {
          this.getPageListPrompt({page: this.page_prompt + 1});
      }
    },
    loadMorelMessage() {

      this.loadMessages(this.conv.id, this.page_mess);
    },
    appendPrompt(prompt){
      // if(prompt){
      this.innerAssitantPrompt(prompt);
      this.updatePromptView(prompt);
      // }
    },
    innerAssitantPrompt(prompt){
      if(prompt){
        this.$root.$refs.compname_component_content_main.formGPTclose();
        this.aPrompt = prompt;
        if(prompt.options && prompt.options.length > 0){
          this.prompt_options = prompt.options;

          for(let i=0; i<prompt.options.length; i++){
            if(prompt.options[i].datatypes == 'images' || prompt.options[i].datatypes == 'sizephotos'){
              this.is_album = true;
              if(prompt.options[i].datatypes == 'images'){
                this.photo_length = prompt.options[i].name_variable || 1;
              }
              else if(prompt.options[i].datatypes == 'sizephotos'){
                this.photo_size = prompt.options[i].name_variable || '256x256';
              }
            }
            else if(prompt.options[i].datatypes == 'radio'){
              this.is_radio = true;
            }
            else if(prompt.options[i].datatypes == 'checbox'){
              this.is_checkbox = true;
            }
            else if(prompt.options[i].datatypes == 'select'){
              this.is_select = true;
            }
          }
        }
        else {
          this.prompt_options = [];
          this.is_album = false;
        }
      }else{
        this.prompt_options = [];
        this.aPrompt = {};
        this.is_album = false;
      }
    },
    loadConversation(id) {
      // if(this.$route.query.id != 0){
      //   $('#list-mess').removeClass('flex-content-items-center')
      // }
    if (!id) return  $('#props_header').addClass('d-none') ;

    this.token = localStorage.getItem('token');
    this.setQueryId(id)

    gptService.getMessages(id, 1, this.token).then((res) => {
        if (!res.error) {
          // if(res.data.prompt){
          //     localStorage.setItem('PropsHeader',JSON.stringify(res.data.prompt))
          //   }
            this.isTyping = false;
            this.isSending = false;
            this.show_version_gpt = true;
            $('#wapper-chat').empty();
            if (id != 0) {
              $('#props_header').addClass('d-none');
              $('#welcome').hide();
              this.$root.$refs.compname_component.openIconShare()
            }else{
              if(this.PropsHeader){
                $('#welcome').hide();
                $('#props_header').removeClass('d-none');
                this.setWelcome(false)

              }else{
                $('#welcome').show();
                $('#props_header').addClass('d-none');
                this.setWelcome(true)

              }
            }     
            let messages=res.data.data
            // console.log('assistant',res.data.prompt)
            let parentMessages = {};

            messages.forEach((message) => {
                if (message.parent_id === '' || message.parent_id == 0 || message.parent_id === null) {
                    parentMessages[message.id] = message;
                    parentMessages[message.id].children = [];
                } else {
                    if (parentMessages[message.parent_id]) {
                        parentMessages[message.parent_id].children.push(message);
                    }
                }
            });

            Object.values(parentMessages).forEach((parentMessage) => {
                let template = parentMessage.type == 0 ? 'template-bot' : 'template-user';
                let templateHtml = document.querySelector('.' + template).innerHTML;
                let html = templateHtml.replace('ps.content', parentMessage.content.replaceAll('\n', '<br>'));

                if (!parentMessage.albums || parentMessage.albums.length == 0) {
                    html = html.replace('ps.albums', '');
                } else if( parentMessage.albums && parentMessage.albums[0].url ) {
                    let decodeJson = JSON.parse(parentMessage.albums);
                    let imagesHtml = '';
                    for (let i = 0; i < decodeJson.length; i++) {
                        let img = '<a href="' + decodeJson[i].url + '" target="_blank"><img src="' + decodeJson[i].url + '" style="width: 250px; margin: 5px 5px;"></a>';
                        imagesHtml += img;
                    }
                    html = html.replace('ps.albums', imagesHtml);
                }else{
                  let decodeJson = parentMessage.albums;
                    let imagesHtml = '';
                        let img = '<a href="' + decodeJson + '" target="_blank"><img src="' + decodeJson + '" style="width: 250px; margin: 5px 5px;"></a>';
                        imagesHtml += img;
                    html = html.replace('ps.albums', imagesHtml);
                }

                html += '<div class="actions" style="display: none;">Your Actions Here</div>';
                html = $(html).attr('data-content', parentMessage.content);
                html = $(html).attr('data-id', parentMessage.id);

                let childContainer = `<div class="child-container" data-parent-id="${parentMessage.id}"></div>`;
                $("#wapper-chat").append(html);
                $('#wapper-chat').append(childContainer);

                if (parentMessage.children && parentMessage.children.length > 0) {
                  //Sắp theo hiển thị mess con mới nhất
                    parentMessage.children.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    // Lấy tin nhắn con mới nhất với type = 0
                    let latestChildMessage = parentMessage.children.find(child => child.type === 0);
                    if (latestChildMessage) {
                        displayChildMessages(parentMessage, latestChildMessage);
                    }

                    let currentPage = 1;
                    let itemsPerPage = 1;
                    let totalPages = Math.ceil(parentMessage.children.length / itemsPerPage);

                    parentMessage.currentPage = currentPage;
                    parentMessage.totalPages = totalPages;
                    parentMessage.itemsPerPage = itemsPerPage;

                    if (totalPages > 1) {
                        let navigationHtml = `<div class="navigation-buttons" style="cursor:pointer; margin-top:5px;" data-parent-id="${parentMessage.id}">

                                                  <button class="nav-button prev" data-action="prev">
                                                    <img src="https://fchat.ai/assets/svg/pre.svg" class="" alt="pre" />
                                                    </button>
                                                  <span class="page-info">${currentPage}/${totalPages}</span>
                                                  <button class="nav-button next" data-action="next">
                                                    <img src="https://fchat.ai/assets/svg/next.svg" class="active-next"  alt="next" />
                                                    <img src="https://fchat.ai/assets/svg/next-disable.svg" style="display:none" class="disable-next" alt="disable-next" />
                                                  </button>

                                                  <div class="d-flex gap-2">                      
                                                    <img src="https://fchat.ai/assets/svg/volum.svg" class="" alt="volumn" />
                                                    <img src="https://fchat.ai/assets/svg/copy-success.svg" style="display:none" class="copy-success"  alt="Copy" />
                                                    <img src="https://fchat.ai/assets/svg/copy.svg" class="copy-mess-bot" alt="Copy" />
                                                    <img src="https://fchat.ai/assets/svg/pen.svg" class="pen-rewrite${parentMessage.id} rewrite" style="display:none" alt="Rewrite" />
                                                  </div>
                                              </div>`;
                        displayChildMessages(parentMessage, currentPage, itemsPerPage);
                        $(`.child-container[data-parent-id="${parentMessage.id}"]`).append(navigationHtml);
                    } else {
                        let navigationHtml = `<div class="navigation-buttons d-flex gap-2" style="cursor:pointer; margin-top:5px" data-parent-id="${parentMessage.id}">
                                                  <img src="https://fchat.ai/assets/svg/volum.svg" class=""  alt="volumn" />
                                                  <img src="https://fchat.ai/assets/svg/copy-success.svg" style="display:none" class="copy-success"  alt="Copy" />
                                                  <img src="https://fchat.ai/assets/svg/copy.svg" class="copy-mess-bot"  alt="Copy" />
                                                  <img src="https://fchat.ai/assets/svg/pen.svg" class=" pen-rewrite${parentMessage.id} rewrite" style="display:none" alt="Rewrite" />
                                              </div>`;
                        displayChildMessages(parentMessage, currentPage, itemsPerPage);
                        $(`.child-container[data-parent-id="${parentMessage.id}"]`).append(navigationHtml);
                    }
                }
            });

            //hiển thị icon viết lại với mess con cuối cùng
            const lastBotMessage = messages.filter(message => message.type === 0).pop();
            if (lastBotMessage) {
              $(`[data-parent-id="${lastBotMessage.parent_id}"]`).find(`.pen-rewrite${lastBotMessage.parent_id}`).css('display', 'flex');
            }

            $("#list-mess").animate({ scrollTop: $('#list-mess').prop("scrollHeight") }, 1000);
            //Click vào class rewrite thì lấy ra id,mess cha và con 
            $("#wapper-chat").off("click", ".rewrite, .copy-mess-bot").on("click", ".rewrite, .copy-mess-bot", function(event) {
              let parentId = $(this).closest(".navigation-buttons").data("parent-id");
                let parentMessage = parentMessages[parentId];

                let currentPage = parentMessage.currentPage;
                let itemsPerPage = parentMessage.itemsPerPage;
                let startIndex = (currentPage - 1) * itemsPerPage;
                let endIndex = startIndex + itemsPerPage;

                let childMessages = parentMessage.children.slice(startIndex, endIndex);

                let userChatData = {
                    content: parentMessage.content,
                    id: parentMessage.id
                };
                localStorage.setItem('user-chat', JSON.stringify(userChatData));

                childMessages.forEach((childMessage) => {
                  let botChatData = {
                    content: childMessage.content,
                    id: childMessage.id
                };
                localStorage.setItem('bot-chat', JSON.stringify(botChatData))

                //Copy nội dung mess và thay đổi icon
                if ($(this).hasClass('copy-mess-bot')) {
                  let copyIcon = $(this);
                  let successIcon = copyIcon.siblings('.copy-success');
                  navigator.clipboard.writeText(childMessage.content).then(function() {
                    successIcon.css('display', 'block');
                    copyIcon.css('display', 'none');
                    Vue.notify({group: 'notification_box', type: 'warning', title: 'Thành công', text: 'Đã sao chép nội dung tin nhắn thành công!'})
                    setTimeout(() => {
                      successIcon.css('display', 'none');
                      copyIcon.css('display', 'block');
                    }, 3000);
                }, function(err) {                    
                    Vue.notify({group: 'notification_box', type: 'error', title: 'Thất bại', text: 'Sao chép nội dung thất bại!'})
                });
                }
                }); 
            });

            $('.rewrite').click(function(){
              $('.isShowModal').show();
              $('.isShowModal').addClass('display-flex');
            });
            $('#cancel-rewrite').off('click').on('click', function() {
              $('.isShowModal').hide();
                $('.isShowModal').removeClass('display-flex');

            });

              $('.btn-cancel').off('click').on('click', function() {
                $('.isShowModal').hide();
                $('.isShowModal').removeClass('display-flex');
              });

              $('.btn-send').off('click').on('click', function() {
                console.log($('#selectedRewrite').val() )
                if($('#selectedRewrite').val() === 'default' || $('#selectedReTone').val() === 'default'){
                Vue.notify({ group: 'notification_box', type: 'error', title: 'Thất bại', text: 'Vui lòng nhập đầy đủ thông tin!' });
              } else {
                  $('.isShowModal').addClass('d-none');
                  $('.isShowModal').removeClass('display-flex');
                }
              });

              $("#wapper-chat").on("click", ".nav-button", (event) => {
    let parentId = $(event.currentTarget).parent().data("parent-id");
    let parentMessage = parentMessages[parentId];
    if (parentMessage) {
        let action = $(event.currentTarget).data("action");
        if (action === 'prev') {
            if (parentMessage.currentPage > 1) {
                parentMessage.currentPage--;
            }
        } else if (action === 'next') {
            if (parentMessage.currentPage < parentMessage.totalPages) {
                parentMessage.currentPage++;
            }
        }
        $("#list-mess").animate({ scrollTop: $('#list-mess').prop("scrollHeight") }, 1000);

        let currentPage = parentMessage.currentPage; // Ensure currentPage is defined here
        let itemsPerPage = parentMessage.itemsPerPage;
        displayChildMessages(parentMessage, currentPage, itemsPerPage);

        // Update navigation button icons
        let navButtons = $(event.currentTarget).parent();
        if (parentMessage.currentPage === parentMessage.totalPages) {
            navButtons.find('.active-next').hide();
            navButtons.find('.disable-next').show();
        } else {
            navButtons.find('.active-next').show();
            navButtons.find('.disable-next').hide();
        }
    } else {
        // console.error("parentMessage is undefined for parentId:", parentId);
    }
});

              function displayChildMessages(parentMessage, currentPage, itemsPerPage) {
                let childContainer = $(`.child-container[data-parent-id="${parentMessage.id}"]`);
                let navigationButtons = childContainer.find('.navigation-buttons');
                navigationButtons.detach();

                childContainer.find('.message-child').remove();

                let startIndex = (currentPage - 1) * itemsPerPage;
                let endIndex = startIndex + itemsPerPage;
                for (let i = startIndex; i < Math.min(endIndex, parentMessage.children.length); i++) {
                    let childMessage = parentMessage.children[i];
                    let childTemplate = childMessage.type == 0 ? 'template-bot' : 'template-user';
                    let childTemplateHtml = document.querySelector('.' + childTemplate).innerHTML;
                    let htmlChild = childTemplateHtml.replace('ps.content', childMessage.content.replaceAll('\n', '<br>'));

                    // Xử lý ảnh nếu cần thiết
                    if (!childMessage.albums || childMessage.albums.length == 0) {
                        htmlChild = htmlChild.replace('ps.albums', '');
                    } else {
                        let decodeJsonChild = JSON.parse(childMessage.albums);
                        let imagesHtmlChild = '';
                        for (let j = 0; j < decodeJsonChild.length; j++) {
                            let img = `<a href="${decodeJsonChild[j].url}" target="_blank"><img src="${decodeJsonChild[j].url}" style="width: 250px; margin: 5px 5px;"></a>`;
                            imagesHtmlChild += img;
                        }
                        htmlChild = htmlChild.replace('ps.albums', imagesHtmlChild);
                    }

                    // Thêm container cho hành động
                    htmlChild += '<div class="actions" style="display: none;">Your Actions Here</div>';

                    // Thiết lập các thuộc tính và lớp
                    htmlChild = $(htmlChild).attr('data-content', childMessage.content);
                    htmlChild = $(htmlChild).attr('data-id', childMessage.id);
                    htmlChild = $(htmlChild).attr('data-parent-id', parentMessage.id);
                    htmlChild = $(htmlChild).addClass('message-child');
                    childContainer.append(htmlChild);
                }

                childContainer.append(navigationButtons);
                childContainer.find('.page-info').text(currentPage + '/' + parentMessage.totalPages);
            }

        }
    });
},

    viewConversation(index, conv) {
      this.conv=conv
      if(!conv.id){
        // console.log(conv.id)
        this.setWelcome(true)
        this.$root.$refs.compname_component.increaseCustomKey();
      }
      $("#wapper-chat").show()
      const targetRoute = { path: '/', query: { id: conv.id } };
      const currentRoute = this.$route;   
      this.$root.$refs.compname_component.closeIconShare()

      if (
        currentRoute.path !== targetRoute.path ||
        currentRoute.query.id !== String(targetRoute.query.id)
      ) {
        this.$router.push(targetRoute).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    }
,

 loadMessages(conv_id, page) {
    if (this.show_load_more = true) page = page + 1;
    gptService.getMessages(conv_id, page, this.token).then((res) => {
        if (!res.error) {
            if (res.data && res.data.data && res.data.data.length > 0) {
                if (page > 1) {
                    this.show_load_more = true;
                    res.data.data.reverse().forEach(function (value, index) {
                        let template = value.type == 0 ? 'template-bot' : 'template-user';
                        let html = $('.' + template).html();

                        let reply = value.content.replaceAll('\n', '<br>');
                        html = html.replaceAll('ps.content', reply);
                        if (!value.albums || value.albums.length == 0) {
                            html = html.replaceAll('ps.albums', '');
                        } else {
                          if(value.albums){
                            let decodeJson = JSON.parse(value.albums);
                            let imagesHtml = '';
                            for (let i = 0; i < decodeJson.length; i++) {
                                let img = '<a href="' + decodeJson[i].url + '" target="_blank"><img src="' + decodeJson[i].url + '" style="width: 250px; margin: 5px 5px;"></a>';
                                imagesHtml += img;
                            }
                            html = html.replaceAll('ps.albums', imagesHtml);
                          }else{
                            console.log("valueeeeeeeeeeeeeeeee",value)
                            let decodeJson = JSON.parse(value.albums);
                            let imagesHtml = '';
                            for (let i = 0; i < decodeJson.length; i++) {
                                let img = '<a href="' + decodeJson[i].url + '" target="_blank"><img src="' + decodeJson[i].url + '" style="width: 250px; margin: 5px 5px;"></a>';
                                imagesHtml += img;
                            }
                            html = html.replaceAll('ps.albums', imagesHtml);
                          }
                            
                        }
                        $("#wapper-chat").append(html);
                        $('#welcome').hide();
                        $('#props_header').hide();
                    });
                    this.page_mess++;
                } else {
                    res.data.data.forEach(function (value, index) {
                        let template = value.type == 0 ? 'template-bot' : 'template-user';
                        let html = $('.' + template).html();
                        html = html.replaceAll('ps.content', value.content);
                        $("#wapper-chat").prepend(html);
                        $('#welcome').hide();                          
                        $('#props_header').hide();                          
                    });
                    this.page_mess++;
                }
            } else {
                Vue.notify({group: 'notification_box', type: 'warning', title: i18ns.t('notification_box.notification_box'), text: i18ns.t('loaded_data.loaded_data')})
            }
        }
    });
}
,
    sendMessage(object_data) {
        $('#list-mess').removeClass('flex-content-items-center');

        $('#welcome').hide();
        if (object_data.message !== "") {
        $('#props_header').addClass('d-none');
        $('#prompt-no-auth').addClass('d-none');

        this.isSending = true;
        this.isTyping = true;
        let conv_id = this.$route.query.id || 0;

        let html_user =  $('.template-user').html()
        html_user = html_user.replaceAll('ps.content',object_data.input)
        if(object_data.parent_id==0){
          $("#wapper-chat").append(html_user)
        }
        $('#welcome').hide();
        $("#question").val('');
        let html_bot =  $('.template-bot-new').html()
        html_bot = html_bot.replaceAll('ps.count-mess',this.count_mess)
        $("#wapper-chat").append(html_bot);
        $('#welcome').hide();
        // Làm trống data trước khi gửi mới
        this.replyTyperWriter = '';
        this.indexTypeWriter = 0;
        this.currentTypeWriter = 0;

        let getRequest = parseInt(this.$cookies.get('request'), 10) || 0;
        // console.log("==================",this.count_mess)
        // Gửi request
        if(this.shop_id){
          this.requestMessage(object_data, conv_id, this.count_mess);
        }else if(!this.shop_id && getRequest < 5){
          this.$cookies.set('request', getRequest +=1 , '1d');
          // Nếu chưa đăng nhập thì chỉ được sử dụng 5 request trên ngày
          this.token=this.TOKEN_SHOP_SYSTEM;
          this.requestMessage(object_data, conv_id, this.count_mess);
        }else{
            this.typeWriter(this.speedWriter,'Bạn đã sử dụng hết lượt chat miễn phí/ ngày! Vui lòng đăng nhập để tiếp tục sử dụng chat.',"mess_count_"+this.count_mess,this.currentTypeWriter);
        }

        this.count_mess++;
        this.scrollToEnd();
        setTimeout(this.scrollToEnd, 200);
      }
    },
    getModelByVersion (version) {
      let model;
      switch (version) {
        case "gpt-4-turbo":
          model = "4";
          break;
        case "4":
          model = "4";
          break;
        case "gpt-4o":
          model = "4o";
          break;
        case "4o":
          model = "4o";
          break;
        case "3.5":
          model = "3.5";
          break;
        default:
          model = "3.5";
          break;
      }
      return model;
    },
    requestMessage(object_data, conv_id, count_mess) {
      this.show_popup_version = false;
      let index_mess= count_mess;
      this.indexTypeWriter = "mess_count_"+index_mess;
      if(object_data.version){
        this.version_gpt=this.getModelByVersion(object_data.version)
      }
      gptService.sendMessage({
            is_album: this.is_album,
            quantity: object_data.quantity,
            size: object_data.size,
            input: object_data.input,
            keyword: object_data.result || object_data.message,
            lang_code: object_data.lang_code,
            format: object_data.format,
            style: object_data.style,
            conversation_id: conv_id,
            shop_id: this.shop_id,
            parent_id: object_data.parent_id,
            botChatId: object_data.botChatId,
            temporaryChat:object_data.temporaryChat,
            thread_id: object_data.thread_id,
            assistant_id: object_data.assistant_id,
            prompt_id: object_data.prompt_id,
            is_image_openai:object_data.is_image_openai

          },
          this.version_gpt,
          this.token
      ).then(async(response) => {
          let data = response.data;
          // console.log(object_data.temporaryChat)
          if(object_data.temporaryChat==false){
            this.$router.push({
              path: '/',
              query: { id: data.data.message_reply.conversation_id }
            }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }

          if (!data.error) {
            if(conv_id==0){
                this.conv.id = data.data.message_reply.conversation_id;                
                this.conversations.unshift({
                  id: data.data.message_reply.conversation_id,
                  is_new: true,
                  name: object_data.input,
                  avatar: this.avatar_bot,
                  last_message: object_data.input,
                  last_message_time: new Date(),
                  unread: 0,
                  version: this.version_gpt
              });
              let conver={
                  id: data.data.message_reply.conversation_id,
                  is_new: true,
                  name: object_data.input,
                  avatar: this.avatar_bot,
                  last_message: object_data.input,
                  last_message_time: new Date(),
                  unread: 0,
                  version: this.version_gpt
              }
              if(!object_data.temporaryChat && this.shop_id){
                this.$root.$refs.compname_component.unshiftConversation(conver)
              }
            }
            let url = '';
            let content = data.data.message_reply.content;
            let albums = data.data.message_reply.albums;
            // if(data.data.message_reply.url){
            //   url = data.data.message_reply.url
            //   albums = []
            //   albums[0] = {}
            //   albums[0].url = data.data.message_reply.url
            // }
            content = content.replaceAll('\n','<br>')
            this.replyTyperWriter = content;
            // Nếu là ảnh
            if(data.data.message_reply.url){
              let image_inner;
                 image_inner = '<img src="'+data.data.message_reply.url+'" style="width: 250px; margin: 5px 5px;">'
                 document.getElementById(this.indexTypeWriter || 1).innerHTML += image_inner;
                 const element = document.getElementById('typewriter '+this.indexTypeWriter+'');
                 if(element){
                   element.parentNode.removeChild(element);
                 }
                 this.isSending = false;
                 setTimeout(this.scrollToEnd, 1000);
              
            }
            else
             if(albums && albums.length > 0) {
              let image_inner;
              for(let i=0; i<albums.length; i++){
                 image_inner = '<a href="'+albums[i].url+'" target="_blank"><img src="'+albums[i].url+'" style="width: 250px; margin: 5px 5px;"></a>'
                 document.getElementById(this.indexTypeWriter).innerHTML += image_inner;
                 const element = document.getElementById('typewriter '+this.indexTypeWriter+'');
                 console.log(image_inner)
                 if(element){
                   element.parentNode.removeChild(element);
                 }
                 this.isSending = false;
                 setTimeout(this.scrollToEnd, 1000);
              }
            }
            // Nếu là văn bản
            else{
             await this.typeWriter(this.speedWriter,this.replyTyperWriter,this.indexTypeWriter,this.currentTypeWriter)
             await $(".mess_typing_"+index_mess).remove()
              this.scrollToEnd();
              setTimeout(this.scrollToEnd, 1000);
              return;
            }

          }
          else{
            if(data.limit_request){
              this.limit_request = true;
              this.typeWriter(this.speedWriter,i18ns.t('update_to_use_request.update_to_use_request'),this.indexTypeWriter,this.currentTypeWriter);
              return;
            }
            else if(data.is_package){
              $("#wapper-chat").hide();
              this.isSending = false;
              this.is_package = true;
              this.typeWriter(this.speedWriter,i18ns.t('update_to_use_package.update_to_use_package'),this.indexTypeWriter,this.currentTypeWriter);
              return;
            }
            console.log(error);
            this.typeWriter(this.speedWriter,i18ns.t('notification_box.request_error'),this.indexTypeWriter,this.currentTypeWriter)
            Vue.notify({group: 'notification_box', type: 'error', title: i18ns.t('notification_box.notification_box'), text: i18ns.t('notification_box.request_error')});    
            return;
          }
          this.isSending = false;
          this.isTyping = false;

        })
        .catch((error) => {
            console.log('send GPT: '+error);
            this.typeWriter(this.speedWriter, i18ns.t('notification_box.request_error'), this.indexTypeWriter,this.currentTypeWriter)
            Vue.notify({group: 'notification_box', type: 'error', title: i18ns.t('notification_box.notification_box'), text: i18ns.t('notification_box.request_error')});
        });
    },

    replaceVariables(str, data) {
        return str.replace(/\{(\w+)\}/g, function (match, variable) {
            return data[variable] || '';
        });
    },
    typeWriter(speed,text,id,i) {
      if (i < text.length && this.isTyping) {
          if(text.charAt(i) == '<' && text.charAt(i+1)=='b'){
              document.getElementById(id).innerHTML += '<br>';
              i = i + 4
              this.scrollToEnd();
          }else{
              document.getElementById(id).innerHTML += text.charAt(i);
              i++;
              this.scrollToEnd();
          }
          this.currentTypeWriter = i;
          setTimeout(this.typeWriter, speed,speed,text,id,i);

      }else {
        this.isSending = false;
      }              
    },
    continueTyping() {
        this.isTyping = true;
        this.typeWriter(this.speedWriter, this.replyTyperWriter, this.indexTypeWriter, this.currentTypeWriter);
    },
    stopTyping() {
      this.isTyping = false;
    },
    updateFavourite(obj){
        if(obj.is_favourite == 1){obj.is_favourite = 0;} else obj.is_favourite = 1;

        let data_obj= {
          "prompt_id": obj.id,"user_id": this.aUser.id, 
          "type_action": obj.is_favourite,
          "type": 2
        }

        if(obj.is_favourite == 0){
          gptService.deleteFavourite(data_obj, this.token).then(r=>{
            if(!r.data.error){
              this.listPrompt.forEach(function (value,index){
                if(value.id == obj.id){
                  value.favourites = value.favourites - 1;
                  value.is_favourite = 0;
                }
              })
            }
          })
        }
        if(obj.is_favourite == 1){
          gptService.updateFavourite(data_obj, this.token).then(r=>{
            if(!r.data.error){
              this.listPrompt.forEach(function (value,index){
                if(value.id == obj.id){
                  value.favourites = value.favourites + 1;
                  value.is_favourite = 1;
                }
              })
            }
          })
        }
    },
    updatePromptView(obj){

      if(obj){
        let data= {"uses": obj.uses,"id": obj.id}
        let token=localStorage.getItem("token")
        gptService.updatePromptView(data, token).then((res) => {
            if(res.data){
              this.listPrompt.forEach(function (value,index){
                if(value.id == obj.id){
                  let count_view = obj.uses + 1;
                  value.uses = count_view;
                }
              })
            }
        });
      }

    },
    updatePromptLikes(obj){
        let data_obj = { likes: obj.likes, "prompt_id": obj.id, type: 1 }

        if(obj.is_like == 0){
          data_obj.type_action = 1;
          gptService.updatePromptLikes(data_obj, this.token).then(r=>{
            if(!r.data.error){
              this.listPrompt.forEach(function (value,index){
                if(value.id == obj.id){
                  value.likes = value.likes + 1;
                  value.is_like = 1;
                }
              })
            }
          })
        }
        if(obj.is_like == 1){
          data_obj.type_action = 0;
          gptService.updateFavourite(data_obj, this.token).then(r=>{
            if(!r.data.error){
              this.listPrompt.forEach(function (value,index){
                if(value.id == obj.id){
                  value.likes = value.likes - 1;
                  value.is_like = 0;
                }
              })
            }
          })
        }
    },

  },
};
</script>

<style>
.nav-button {
    border: none;
    background: none;
}

.navigation-buttons {
    display: flex;
    align-items: center;
    /* gap: 5px; */
    padding-left: 60px;
}

.page-info {
    font-size: 14px;
}

.navigation-buttons .nav-button {
    /* margin: 0 5px; */
}

.navigation-buttons i {
    cursor: pointer;

}
</style>
