<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
<select id="version-gpt" class="form-select form_choose_style border border-gray-300 rounded shadow-md border-none px-3 py-1 " v-model="selectedVersion" @change="handleChange">
    <option value="3.5">
        GPT - 3.5
    </option>
    <option value="4o-mini">
      GPT-4o mini
    </option>
    <option value="4o">
        GPT - 4o
    </option>
    <option value="4">
        GPT - 4
    </option>
    <option value="gemini">
        GEMINI
    </option>
</select>
</template>

<script>
import Vue from 'vue';

export default {
  name: "SelectChooseVersion",
  components: {

  },
  props: ['package','version_gpt'],
  data() {
    return {
      selectedVersion: '3.5', 

    };
  },
  mounted() {

  }
  ,
  methods:{
    handleChange() {
      // if ((this.selectedVersion === '4' || this.selectedVersion === '4o') && this.package.package_id !== 7 && this.package.package_id !== 1) {
      //   Vue.notify({group: 'notification_box', type: 'error', title: 'Thất bại', text: 'Vui lòng nâng cấp tài khoản trả phí để sử dụng dịch vụ!'})
      //   this.selectedVersion = '3.5'; // Reset về giá trị mặc định hoặc giá trị phù hợp khác
      // }
      // console.log(this.selectedVersion)
      this.avtiveVersionGpt(this.selectedVersion)
    },

    avtiveVersionGpt(version) {
      this.$root.$refs.compname_component_gpt.avtiveVersionGpt(version)
    },
    openUpgradePackageModal() {
      $('#modal-upgrade-package').modal('show');
      // show thông báo và không cho người dùng chọn
    },

  }
};
</script>

<style scoped src="../../assets/css/conversation.css"></style>
