<script>
import jQuery from "jquery";
let $ = jQuery;
console.log($);
</script>

<template>
   <div class="card">
            <!-- Header -->
            <div class="card-header">
              <div class="header-warp">

                <div class="row text-center ">
                  <div class="col-md-12 text-center ">
                      <div class="item-filter">
                          <input type="text" :placeholder="$t('assistant.assistant_search')" class="form-control mb-10" id="search_assis" v-model="searchValue">
                          <select name="tag" v-model="refilter_tag" @change="filterByTag($event)">
                              <option value="">{{ $t('all.all') }}</option>
                              <option v-for="(tag_chat, index_tag_chat) in listTags.tags_system" :key="index_tag_chat" :value="tag_chat.id">{{tag_chat.name}}</option>
                          </select>
                      </div>
                  </div>
              </div>
              <button type="button" @click="toggleSidebarRight" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>

              </div>
            </div>
            
            <!-- Danh sách chat ở phần giữa -->
            <div id="conversations-item" class="conversations-item">
              <!-- Hiển thị danh sách prompt -->
              <div id="pick_consv" class="pick_consv" @scroll="handleScrollPrompt">
                <div class="row">
                  <div class="">
                      <div v-for="prompt in this.visiblePrompts" :key="prompt.id" id="list_assis">
                         <div v-if="prompt" class="content-assis">
                            <div class="title-assis">
                              <div v-if="prompt.avatar_url"><img :src="prompt.avatar_url" alt=""></div>
                              <div v-else><img :src="'https://salekit.io/favicon.ico'" alt=""></div>
                              <div class="text-assis">
                                  <div class="name_text">
                                      <a href="#" @click="appendPrompt(prompt)" >{{ prompt.name }}</a>
                                  </div>
                              </div>
                              </div>
                              <div class="des-assis" :title="prompt.description">
                                  <div class="desc_text"> 
                                    {{ prompt.description }}
                                  </div>
                              </div>
                              <div class="show-social">
  
                              <div class="show-preview">
                                  <a href="#"> 
                                      <i class="fa fa-eye" aria-hidden="true" title="view"></i>
                                      {{ prompt.uses }}
                                  </a>
                              </div>
                              <div class="show-likes">
                                  <a href="#" @click="updatePromptLikes(prompt)">
                                      <i v-if="prompt.is_like == 0" class="fa fa-thumbs-up" aria-hidden="true" title="likes"></i>
                                      <i v-else class="fa fa-thumbs-up" aria-hidden="true" title="likes" style="color:rgb(221, 79, 221)"></i>
                                      {{prompt.likes }}
                                  </a>
                              </div>
                              <div class="show-favorite">
                                <a href="#" @click="updateFavourite(prompt)">
                                  <i v-if="prompt.is_favourite==1" class="fa fa-heart" aria-hidden="true" title="favourite" style="color:rgb(9, 50, 255)"></i>
                                  <i v-else class="fa fa-heart" aria-hidden="true" title="favourite"></i>
                                  {{ prompt.favourites }}
                                </a>
                              </div>
                              <div v-if="prompt.help_url" class="show-question">
                                  <a target="blank" :href="'https://help.fchat.ai/'+prompt.help_url"><i class="fa fa-question-circle" aria-hidden="true" title="question"></i></a>
                              </div>
                          </div>
                         </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Footer -->
            <div class="card-footer">
              <span class="see-full-screen" data-toggle="modal" data-target="#fullScreenModal">
                <i class="fas fa-long-arrow-alt-left"></i> {{ $t('view_full_screen.view_full_screen') }}
               </span>
            </div>
          </div>
</template>

<script>
import Conversations from "../../assets/css/sidebarright.css";
export default {
  components: {
    Conversations
  },
  props: {
    is_likes: Boolean,
    isSidebarRight: Boolean,
    token: String,
    listPrompt: Array,
    listTags: [Object, Array],
  },
  data() {
    return {
        isHovering: false,
        prompt: {},
        searchValue: "",
        choosePrompt: {},
        refilter_tag: '',
    };
  },
  computed:{
    visiblePrompts() {
        return this.listPrompt.slice(0, this.visiblePrompt)
    },
  },
  watch: {
    searchValue: function(newSearchValue, oldSearchValue) {
        this.updateVisiblePrompts();
    },
  },
  methods: {
    async updateVisiblePrompts() {
      const searchTerm = this.searchValue.toLowerCase().trim();
      let object_data = {
        keyword: searchTerm,
        is_shop: this.isMyAssistantOnClicked,
      };
      await this.$root.$refs.compname_component.getListPrompt(object_data);
    },
    handleMouseOver() {
      this.isHovering = true;
    },
    handleMouseOut() {
      this.isHovering = false;
    },
    handleScrollPrompt(event){
      this.$root.$refs.compname_component.handleScrollPrompt(event);
    },
    toggleSidebarRight() {
        this.$root.$refs.compname_component.toggleSidebarRight();
    },
    appendPrompt(object){
      this.innerAssitantPrompt(object);
    },
    innerAssitantPrompt(prompt){
      this.$root.$refs.compname_component.innerAssitantPrompt(prompt);
      this.$root.$refs.compname_component.updatePromptView(prompt);
    },
    filterByTag: function(tagevent) { 
      let object_data = {
        tag: tagevent.target.value
      }
      this.$root.$refs.compname_component.getListPrompt(object_data);
    },
    updateFavourite(newPrompt){
      this.$root.$refs.compname_component.updateFavourite(newPrompt);
    },
    updatePromptLikes(newPrompt){
      this.$root.$refs.compname_component.updatePromptLikes(newPrompt);
    },
  },
};
</script>

<style scoped src="../../assets/css/sidebarright.css">
</style>