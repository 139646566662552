<template>
  <div class="">

    <div>
      <div class="gpts_content_tile fs-20 fw-600 text-black">
        {{ $t('topic.topic') }}
      </div>
      <div class="gpts-content_des mt-1 text-neutral">
        {{ $t('topic_des.topic_des') }}
      </div>
      <div class="row mx-md-n5 ">
        <div v-for="(topic, index) in visibleTags" :key="index"
          class="col-lg-6 col-md-6 col-sm-12 col-12 px-1 px-md-1 mt-3 cursor-pointer">
          <div class="custom-item-topic bg-neutral-hover ">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0 p-8-12">
                <img v-if="topic.icon" :src="topic.icon" width="32" height="32" class="rounded-circle" :alt="topic.icon">
                <img v-else src="https://fchat.ai/assets/images/EMO-fchat-03.png" width="32" class="rounded-circle" height="32" alt="icon">
              </div>
              <div @click.prevent="viewDetailTags(topic)"
                class="flex-grow-1 ms-3 fs-14 fw-600 text-black limit-text-1-line">
                {{ topic.name }}
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-loadmore col-12" @click="visibleTag += step" v-if="visibleTag < listTags.length">
          {{ $t('load_more.load_more') }}
        </button>
        <button class="btn btn-loadmore col-12" @click="collapseTags" v-if="visibleTag >= listTags.length && listTags.length > step">
          {{ $t('collapse.collapse') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Topic',
  components: {},
  props: {
    listTags: Array
  },
  data() {
    return {
      token: '',
      aUser: {},
      aPackage: {},
      aShop: {},
      visibleTag: 6,
      step: 6,
      page: 2,
      topicStep: {},
      stepCount: 0,
      getDetail: false
      
    }
  },
  created() {

  },
  
  beforeMount() { },

  beforeDestroy() { },
  mounted() {
    this.aPackage = JSON.parse(localStorage.getItem('package'));
    this.aShop = JSON.parse(localStorage.getItem('aShop'));
    this.token = localStorage.getItem('token');
    this.aUser = JSON.parse(localStorage.getItem('aUser'));

  },

  computed: {
    visibleTags() {
      return this.listTags.slice(0, this.visibleTag)
    },
  },
  watch: {

  },
  methods: {

    viewDetailTags(tag) {
      // this.$store.dispatch('setListGPTS', false);
      localStorage.setItem('category', JSON.stringify(tag))
      this.$router.push({ name: 'topic_step', params: { id: tag.id } });
    },
    collapseTags() {
      this.visibleTag = this.step;
    }

  }

}
</script>

<style scoped src="@/assets/css/gpts.css"></style>
